import { messageTypes } from "../../utils/constants";

const USER_SET = 'USER_SET';
const USER_CLEAR = 'USER_CLEAR';
const APP_LOADING = 'APP_LOADING';
const APP_LOADED = 'APP_LOADED';
const APP_LOAD_STARTED = 'APP_LOAD_STARTED';
const APP_LOAD_COMPLETE = 'APP_LOAD_COMPLETE';
const APP_BACKGROUND_LOADING = 'APP_BACKGROUND_LOADING';
const APP_BACKGROUND_LOADED = 'APP_BACKGROUND_LOADED';
const APP_BACKGROUND_STARTED = 'APP_BACKGROUND_STARTED';
const APP_BACKGROUND_COMPLETE = 'APP_BACKGROUND_COMPLETE';
const APP_REPORT_LOADING = 'APP_REPORT_LOADING';
const APP_REPORT_LOADED = 'APP_REPORT_LOADED';
const APP_REPORT_STARTED = 'APP_REPORT_STARTED';
const APP_REPORT_COMPLETE = 'APP_REPORT_COMPLETE';
const FIRST_LOAD_REQUEST = 'FIRST_LOAD_REQUEST';
const FIRST_LOAD_SUCCESS = 'FIRST_LOAD_SUCCESS';
const RESET_FIRST_LOAD = 'RESET_FIRST_LOAD';
const APP_MESSAGE_SET = 'APP_MESSAGE_SET';
const APP_MESSAGE_CLEAR = 'APP_MESSAGE_CLEAR';
const NAV_MENU_EXPANDED_SET = 'NAV_MENU_EXPANDED_SET';
const PAGE_TITLE_SET = 'PAGE_TITLE_SET';
const PAGE_TITLE_CLEAR = 'PAGE_TITLE_CLEAR';
const WORKING_ACCOUNT_SET = 'WORKING_ACCOUNT_SET';
const WORKING_ACCOUNT_CLEAR = 'WORKING_ACCOUNT_CLEAR';

/**
 * ### Common Actions for Application State Management
 *
 * This file defines the core actions and their types used across the application to manage
 * global state such as user authentication, loading indicators, navigation menus, and 
 * working account context.
 *
 * ### Key Features:
 * 
 * 1. **User Authentication**:
 *    - Actions: `USER_SET`, `USER_CLEAR`
 *    - Manages the current user's authentication state, set during Firebase Authentication (`fb.auth`).
 *    - Used to initialize the user's session or clear it on logout.
 *
 * 2. **Application Loading State**:
 *    - Actions: `APP_LOADING`, `APP_LOADED`, `APP_LOAD_STARTED`, `APP_LOAD_COMPLETE`
 *    - Tracks the loading status of the application:
 *      - `APP_LOADING` and `APP_LOADED` toggle global loading state.
 *      - `APP_LOAD_STARTED` and `APP_LOAD_COMPLETE` manage task-based loading (e.g., account data).
 *    - Ensures smooth transitions between states and tracks whether the app is fully loaded.
 *
 * 3. **Global Messaging**:
 *    - Actions: `APP_MESSAGE_SET`, `APP_MESSAGE_CLEAR`
 *    - Enables the display of user notifications (e.g., success, warning, error messages).
 *    - Includes helper actions like `appMessageSuccess` and `appMessageError` for convenience.
 *
 * 4. **Navigation Menu**:
 *    - Actions: `NAV_MENU_EXPANDED_SET`
 *    - Tracks whether the navigation menu is expanded or collapsed.
 *
 * 5. **Page Titles**:
 *    - Actions: `PAGE_TITLE_SET`, `PAGE_TITLE_CLEAR`
 *    - Dynamically updates the page title based on the current view.
 *
 * /**
 * 6. **Working Account Context**:
 *    - Actions: `WORKING_ACCOUNT_SET`, `WORKING_ACCOUNT_CLEAR`
 *    - **Purpose**:
 *      - The `workingAccount` is used to manage the context of a specific `DEPARTMENT` or `AREA`
 *        within a `LOCATION`.
 *      - It applies **only when the `currentAccount` is a `LOCATION`**, providing further granularity
 *        for operations focused on a specific part of the `LOCATION`.
 *    - **Key Difference from `currentAccount`**:
 *      - `currentAccount` represents the broader organizational scope, such as `OWNER` or `LOCATION`.
 *      - `workingAccount` is a finer-grained scope within a `LOCATION`, targeting a `DEPARTMENT` or `AREA`.
 *    - **Use Case**:
 *      - If the `currentAccount` is set to a `LOCATION`, the `workingAccount` can further specify
 *        a department like "Kitchen" or "Bar" within that location.
 *      - Switching the `workingAccount` allows the user to interact with data specific to that department
 *        without changing the broader context of the `currentAccount`.
 *
 * ### Goal:
 * Provide a centralized and efficient way to manage global state, ensuring modular and
 * maintainable code while optimizing user experience across the application.
 *///


export const actionTypes = {
  USER_SET,
  USER_CLEAR,

  APP_LOADING,
  APP_LOADED,
  APP_LOAD_STARTED,
  APP_LOAD_COMPLETE,

  APP_BACKGROUND_LOADING,
  APP_BACKGROUND_LOADED,
  APP_BACKGROUND_STARTED,
  APP_BACKGROUND_COMPLETE,

  APP_REPORT_LOADING,
  APP_REPORT_LOADED,
  APP_REPORT_STARTED,
  APP_REPORT_COMPLETE,

  FIRST_LOAD_REQUEST,
  FIRST_LOAD_SUCCESS,
  RESET_FIRST_LOAD,
  
  APP_MESSAGE_SET,
  APP_MESSAGE_CLEAR,

  NAV_MENU_EXPANDED_SET,
  PAGE_TITLE_SET,
  PAGE_TITLE_CLEAR,

  WORKING_ACCOUNT_SET,
  WORKING_ACCOUNT_CLEAR,
};

// fb.auth -> user reducer
const userSet = (userInfo) => {
  return {
    type: USER_SET,
    userInfo
  };
};

const userClear = () => {
  return {
    type: USER_CLEAR
  };
};

//APP_LOADING and APP_LOADED
//These manage the global loading state (true or false) for the entire application.
const applicationLoading = () => {
  return {
    type: APP_LOADING
  };
};

const applicationLoaded = () => {
  return {
    type: APP_LOADED
  };
};

//APP_LOAD_STARTED and APP_LOAD_COMPLETE
//These manage the loading of individual tasks by tracking them in an array (loadingTasks) to determine when all tasks are complete.
const applicationLoadStart = (task) => {
  return {
    type: APP_LOAD_STARTED,
    task
  };
};

const applicationLoadComplete = (task) => {
  return {
    type: APP_LOAD_COMPLETE,
    task
  };
};

//ASYNC_PROFILE_LOADING_START and ASYNC_PROFILE_LOADING_COMPLETE
//These specifically track profile-related background tasks (asyncProfileLoading) but currently operate independently from the above global logic.

// Toggle True/False for Background Loading
const applicationBackgroundLoading = () => {
  return {
    type: APP_BACKGROUND_LOADING
  };
};

const applicationBackgroundLoaded = () => {
  return {
    type: APP_BACKGROUND_LOADED
  };
};

// Task Tracking for Background Tasks in Saga
const applicationBackgroundStart = (task) => ({
  type: APP_BACKGROUND_STARTED,
  task,
});

const applicationBackgroundComplete = (task) => ({
  type: APP_BACKGROUND_COMPLETE,
  task,
});

const applicationReportLoading = () => ({
  type: APP_REPORT_LOADING,
});

const applicationReportLoaded = () => ({
  type: APP_REPORT_LOADED,
});

const applicationReporStart = (task) => ({
  type: APP_REPORT_STARTED,
  task,
});

const applicationReportComplete = (task) => ({
  type: APP_REPORT_COMPLETE,
  task,
});

const firstLoadRequest = () => ({
  type: FIRST_LOAD_REQUEST,
});

const firstLoadSuccess = () => ({
  type: FIRST_LOAD_SUCCESS,
});

const resetFirstLoad = () => ({
  type: RESET_FIRST_LOAD,
});

const appMessageSet = (messageText, messageType = 'info') => {
  return {
    type: APP_MESSAGE_SET,
    messageText,
    messageType
  };
};

const appMessageClear = () => {
  return {
    type: APP_MESSAGE_CLEAR
  };
};

const appMessageSuccess = (messageText) => {
  return appMessageSet(messageText, messageTypes.SUCCESS);
};

const appMessageError = (messageText) => {
  return appMessageSet(messageText, messageTypes.ERROR);
};

const navMenuExpandedSet = (expanded) => {
  return {
    type: NAV_MENU_EXPANDED_SET,
    expanded
  };
};

const navMenuExpand = () => {
  return {
    type: NAV_MENU_EXPANDED_SET,
    expanded: true
  };
};

const navMenuCollapse = () => {
  return {
    type: NAV_MENU_EXPANDED_SET,
    expanded: false
  };
};

const pageTitleSet = (title) => {
  return {
    type: PAGE_TITLE_SET,
    title
  };
};

const pageTitleClear = () => {
  return {
    type: PAGE_TITLE_CLEAR
  };
};

const workingAccountSet = (accountId) => {
  return {
    type: WORKING_ACCOUNT_SET,
    accountId,
  };
};

const workingAccountClear = () => {
  return {
    type: WORKING_ACCOUNT_CLEAR,
  };
};

export const actions = {
  userSet,
  userClear,
  applicationLoading,
  applicationLoaded,
  applicationLoadStart,
  applicationLoadComplete,
  applicationBackgroundLoading,
  applicationBackgroundLoaded,
  applicationBackgroundStart,
  applicationBackgroundComplete,
  applicationReportLoading,
  applicationReportLoaded,
  applicationReporStart,
  applicationReportComplete,

  firstLoadRequest,
  firstLoadSuccess,
  resetFirstLoad,

  appMessageClear,
  appMessageSet,
  appMessageSuccess,
  appMessageError,

  navMenuExpandedSet,
  navMenuExpand,
  navMenuCollapse,

  pageTitleClear,
  pageTitleSet,

  workingAccountClear,
  workingAccountSet,
};
