//React
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { majorScale, Pane, Text, Table, TextInputField, useTheme } from 'evergreen-ui';

//Components
import BestSellers from './BestSellers';
import SalesByCategory from './SalesByCategory';
import Block from '../../../components/ui/Block/Block';
import Button from '../../../components/ui/Button/Button';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons'
import CustomDialog from '../../../components/Dialog/Dialog';

//Files
import { getSalesForPeriod, getOrdersForPeriod, getWastageDate, calcVariance, calcPercentage, periodTransferCosts, 
  getProcurementTotal, getWeekDaySales } from '../../../utils/functions';
import { currency } from '../../../utils/format';
import { orderStatuses } from '../../../utils/constants';
import { current, selectReportIncidentals } from '../../../utils/selectors';
import { actions } from '../../../store/actions';
import { calculcateWeekTheoreticalStock } from '../../../utils/stock';


const SummaryPage = ({ weekStart, weekEnd, prevWeekStart, prevWeekEnd }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { accountId } = useParams();
  //console.log(weekStart, 'WEEKSTARTTT')
  //console.log(weekEnd, 'WEEKSTENNNNDD')
  const theoreticalStock = calculcateWeekTheoreticalStock(weekStart, weekEnd, accountId);
  const reportIncidentals = useSelector((state) => selectReportIncidentals({ state, accountId, weekStart }));
  const weekWastageCost = useSelector((state) => _.sumBy(
    _.filter(current(state, 'wastages', accountId), (wastage) => {
      const wastageDate = getWastageDate(wastage);
      if (!wastageDate) {
        return false;
      }
      return (moment(wastageDate).isBetween(weekStart, weekEnd, 'day', '[]'));
    }),
    (wastage) => ((isNaN(wastage.cost) ? 0 : wastage.cost))
  ));
  const weekdaySales = getWeekDaySales(accountId, weekStart, weekEnd);
  const weekSales = getSalesForPeriod(accountId, weekStart, weekEnd);
  //console.log(weekSales)
  const prevWeekSales = getSalesForPeriod(accountId, prevWeekStart, prevWeekEnd);
  const weekOrders = getOrdersForPeriod(accountId, weekStart, weekEnd);
  //console.log(weekOrders, "weekOrders")
  const prevReportIncidentals = useSelector((state) => selectReportIncidentals({ state, accountId, prevWeekStart }));
  const prevWeekOrders = getOrdersForPeriod(accountId, prevWeekStart, prevWeekEnd);
  const prevWeekTransfers = periodTransferCosts(accountId, prevWeekStart, prevWeekEnd);
  const prevProcurementTotal = getProcurementTotal(prevWeekOrders, prevWeekTransfers, prevReportIncidentals);
  const [editingValue, setEditingValue] = useState(false);
  const [newValue, setNewValue] = useState(false);
  const weekSalesTotal = _.sumBy(weekSales, 'totalgross');
  const weekSalesNet = _.sumBy(weekSales, 'totalnet');
  const weekSalesCost = _.sumBy(weekSales, 'totalcost');
  const expectedGP = calcPercentage(weekSalesNet - weekSalesCost, weekSalesNet, 1);
  const prevWeekSalesNet = _.sumBy(prevWeekSales, 'totalnet');
  const prevWeekSalesCost = _.sumBy(prevWeekSales, 'totalcost');
  const prevWeekGP = calcPercentage(prevWeekSalesNet - prevWeekSalesCost, prevWeekSalesNet, 1);
  const openPOCount = (_.filter(weekOrders, (order) => (order.status === orderStatuses.SENT))).length;
  const approvedPOCount = (_.filter(weekOrders, (order) => (order.status === orderStatuses.RECEIVED))).length;
  const weekTransfers = periodTransferCosts(accountId, weekStart, weekEnd);
  const procurementTotal = getProcurementTotal(weekOrders, weekTransfers, reportIncidentals)

  const setWeeklyIncidental = ({ incidental, newValue }) => {
    dispatch(actions.reportsIncidentals.setWeeklyIncidentals(accountId, {
      weekOf: moment(weekStart).format('YYYY-MM-DD'),
      [incidental]: newValue,
    }));
  };

  const setYearlyIncidental = ({ incidental, newValue }) => {
    dispatch(actions.reportsIncidentals.setYearlyIncidentals(accountId, {
      year: moment(weekStart).year(),
      [incidental]: newValue,
    }));
  };

  const closeDialog = () => {
    setEditingValue(false);
    setNewValue(false);
  };
  
  return (
    <React.Fragment>
      <Block marginBottom={majorScale(2)} paddingBottom={majorScale(2)}>
        <Pane
            display="flex"
            alignItems="center"
            padding={majorScale(2)}
        >
          <CustomHeading level="4" >Net sales</CustomHeading>
          <Text
              flex="1 0 auto"
              marginLeft={majorScale(4)}
              marginRight={majorScale(4)}
              color={theme.colors.tertiary100}
              fontSize={24}
          >£ {currency(weekSalesNet)}</Text>
          <CustomHeading level="4" >Gross sales</CustomHeading>
          <Text
              flex="1 0 auto"
              marginLeft={majorScale(4)}
              marginRight={majorScale(4)}
              color={theme.colors.tertiary100}
              fontSize={24}
          >£ {currency(weekSalesTotal)}</Text>
          <Button
              appearance="primary"
              iconBefore="download"
              is={Link}
              to={`/${accountId}/reports/sales-import`}
          >Import Sales</Button>
        </Pane>
        <Table>
          <Table.Head>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.TextHeaderCell>Actual</Table.TextHeaderCell>
            <Table.TextHeaderCell>Budget</Table.TextHeaderCell>
            <Table.TextHeaderCell>Var %</Table.TextHeaderCell>
            <Table.TextHeaderCell>Last Week</Table.TextHeaderCell>
            <Table.TextHeaderCell>Var %</Table.TextHeaderCell>
            <Table.TextHeaderCell>Last Year</Table.TextHeaderCell>
            <Table.TextHeaderCell>Var %</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            <Table.Row borderBottom="none">
              <Table.TextCell>Net Sales</Table.TextCell>
              <Table.TextCell textProps={{ color: theme.colors.tertiary100 }}>£ {currency(weekSalesNet)}</Table.TextCell>
              <Table.Cell>
                <Text size={300} whiteSpace="nowrap">£ {currency(reportIncidentals.salesBudget || 0)}</Text>
                <IconWrapper
                    name="edit"
                    appearance='clickable'
                    marginLeft={majorScale(1)}
                    onClick={() => setEditingValue({
                      label: 'Sales Budget',
                      incidental: 'salesBudget',
                      value: reportIncidentals.salesBudget,
                      setValue: setWeeklyIncidental,
                    })}
                />
              </Table.Cell>
              <Table.TextCell>{calcVariance(weekSalesNet, reportIncidentals.salesBudget || 0)}%</Table.TextCell>
              <Table.TextCell>£ {currency(prevWeekSalesNet)}</Table.TextCell>
              <Table.TextCell>{calcVariance(weekSalesNet, prevWeekSalesNet)}%</Table.TextCell>
              <Table.Cell>
                <Text size={300} whiteSpace="nowrap">£ {currency(reportIncidentals.salesPrevYear || 0)}</Text>
                <IconWrapper
                    name="edit"
                    appearance="clickable"
                    marginLeft={majorScale(1)}
                    onClick={() => setEditingValue({
                      label: 'Last Year Sales',
                      incidental: 'salesPrevYear',
                      value: reportIncidentals.salesPrevYear,
                      setValue: setYearlyIncidental,
                    })}
                />
              </Table.Cell>
              <Table.TextCell>{calcVariance(weekSalesNet, reportIncidentals.salesPrevYear || 0)}%</Table.TextCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Block>
      
      <Block marginBottom={majorScale(2)} paddingBottom={majorScale(2)}>
        <Pane >
          <Table>
            <Table.Head>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.TextHeaderCell>Monday</Table.TextHeaderCell>
              <Table.TextHeaderCell>Tuesday</Table.TextHeaderCell>
              <Table.TextHeaderCell>Wednesday</Table.TextHeaderCell>
              <Table.TextHeaderCell>Thursday</Table.TextHeaderCell>
              <Table.TextHeaderCell>Friday</Table.TextHeaderCell>
              <Table.TextHeaderCell>Saturday</Table.TextHeaderCell>
              <Table.TextHeaderCell>Sunday</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              <Table.Row borderBottom="none">
                <Table.TextCell>Gross Sales</Table.TextCell>
                {_.map(weekdaySales, (day, index) => {
                  const {items} = day
                  const daySalesTotal = _.sumBy(items, 'totalgross');
                  return (
                    <Table.TextCell key={`gross_${index}`}>£ {currency(daySalesTotal)}</Table.TextCell>
                  )
                })}
              </Table.Row>
              <Table.Row borderBottom="none">
                <Table.TextCell>Net Sales</Table.TextCell>
                {_.map(weekdaySales, (day, index) => {
                  const {items} = day;
                  const daySalesNet = _.sumBy(items, 'totalnet');
                  return (
                    <Table.TextCell key={`net_${index}`}>£ {currency(daySalesNet)}</Table.TextCell>
                  )
                })}
              </Table.Row>
            </Table.Body>
          </Table>
        </Pane>
      </Block>
      
      <Pane display="flex" marginBottom={majorScale(2)}>
        <Block
            flex="1 1 0"
            marginRight={majorScale(2)}
            paddingY={majorScale(2)}
        >
          <BestSellers salesItems={(weekSales) ? weekSales : []} accountId={accountId} />
        </Block>
        <Block
            flex="1 1 0"
            paddingY={majorScale(2)}
        >
          <SalesByCategory salesItems={(weekSales) ? weekSales : []} accountId={accountId} />
        </Block>
      </Pane>

      <Block marginBottom={majorScale(2)} paddingBottom={majorScale(2)}>
        <Pane
            display="flex"
            alignItems="center"
            padding={majorScale(2)}
        >
          <CustomHeading level="3" size={600}>Procurement Balance</CustomHeading>
          <Text
              flex="1 0 auto"
              marginLeft={majorScale(4)}
              color={theme.colors.tertiary100}
              fontSize={24}
          >£ {currency(procurementTotal)}</Text>
        </Pane>
        <Table>
          <Table.Head>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.TextHeaderCell>Actual</Table.TextHeaderCell>
            <Table.TextHeaderCell>Budget</Table.TextHeaderCell>
            <Table.TextHeaderCell>Var %</Table.TextHeaderCell>
            <Table.TextHeaderCell>Last Week</Table.TextHeaderCell>
            <Table.TextHeaderCell>Var %</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            <Table.Row borderBottom="none">
              <Table.TextCell>Procurement Balance</Table.TextCell>
              <Table.TextCell textProps={{ color: theme.colors.tertiary100 }}>£ {currency(procurementTotal)}</Table.TextCell>
              <Table.Cell>
                <Text size={300} whiteSpace="nowrap">£ {currency(reportIncidentals.procurementBudget || 0)}</Text>
                <IconWrapper
                    name="edit"
                    appearance="clickable"
                    marginLeft={majorScale(1)}
                    onClick={() => setEditingValue({
                      label: 'Procurement Budget',
                      incidental: 'procurementBudget',
                      value: reportIncidentals.procurementBudget,
                      setValue: setWeeklyIncidental,
                    })}
                />
              </Table.Cell>
              <Table.TextCell>{calcVariance(procurementTotal, reportIncidentals.procurementBudget || 0)}%</Table.TextCell>
              <Table.TextCell>£ {currency(prevProcurementTotal)}</Table.TextCell>
              <Table.TextCell>{calcVariance(procurementTotal, prevProcurementTotal)}%</Table.TextCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Block>

      <Pane display="flex" marginBottom={majorScale(2)}>
        <Block
            flex="1 1 0"
            marginRight={majorScale(2)}
            padding={majorScale(2)}
            display="flex"
            justifyContent="space-between"
        >
          <CustomHeading level="4">Total POs</CustomHeading>
          <Text size={600} color={theme.colors.grey2}>{weekOrders.length}</Text>
        </Block>
        <Block
            flex="1 1 0"
            marginRight={majorScale(2)}
            padding={majorScale(2)}
            display="flex"
            justifyContent="space-between"
        >
          <CustomHeading level="4">Open POs</CustomHeading>
          <Text size={600} color={theme.colors.grey2}>{openPOCount || 0}</Text>
        </Block>
        <Block
            flex="1 1 0"
            padding={majorScale(2)}
            display="flex"
            justifyContent="space-between"
        >
          <CustomHeading level="4">Approved POs</CustomHeading>
          <Text size={600} color={theme.colors.grey2}>{approvedPOCount || 0}</Text>
        </Block>
      </Pane>

      <Pane display="flex" marginBottom={majorScale(2)}>
        <Block
          flex="1 1 0"
          marginRight={majorScale(2)}
          padding={majorScale(2)}
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
        >
          <CustomHeading level="4" marginBottom={majorScale(2)}>Theoretical Stock Value</CustomHeading>
          <Text
              size={600}
              color={theme.colors.tertiary100}
              marginLeft={majorScale(2)}
              marginBottom={majorScale(2)}
          >£{currency(theoreticalStock.expectedClosingStocktakeValue)}</Text>
          <CustomHeading level="4" marginBottom={majorScale(2)}>Last week</CustomHeading>
          <Text
              size={600}
              color={theme.colors.grey2}
              marginLeft={majorScale(2)}
              marginBottom={majorScale(2)}
          >£{currency(theoreticalStock.expectedOpeningStocktakeValue)}</Text>
        </Block>
        <Block
          flex="1 1 0"
          marginRight={majorScale(2)}
          padding={majorScale(2)}
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <CustomHeading level="4" marginBottom={majorScale(2)}>Theoretical GP%</CustomHeading>
          <Text
            size={600}
            color={theme.colors.tertiary100}
            marginLeft={majorScale(2)}
            marginBottom={majorScale(2)}
          >{expectedGP} %</Text>
          <CustomHeading level="4" marginBottom={majorScale(2)}>Last week</CustomHeading>
          <Text
              size={600}
              color={theme.colors.grey2}
              marginLeft={majorScale(2)}
              marginBottom={majorScale(2)}
          >{prevWeekGP} %</Text>
        </Block>

        <Block
          flex="1 1 0"
          padding={majorScale(2)}
          
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
        >
          <CustomHeading level="4"marginBottom={majorScale(2)}>Wastage</CustomHeading>
          <Text
            size={600}
            color={theme.colors.tertiary100}
            marginLeft={majorScale(2)}
            marginBottom={majorScale(2)}
          >£ {currency(weekWastageCost)}</Text>
          <CustomHeading level="4" marginBottom={majorScale(2)}>Allowances</CustomHeading>
          <Text
            size={600}
            color={theme.colors.grey2}
            marginLeft={majorScale(2)}
          >£ {currency(reportIncidentals.stockAllowances || 0)}</Text>
        </Block>
      </Pane>

      <CustomDialog
          isOpen={!_.isEmpty(editingValue)}
          title={editingValue.label || 'Update value'}
          confirmLabel="Update"
          onClose={closeDialog}
          onConfirm={() => {
            editingValue.setValue({
              incidental: editingValue.incidental, newValue
            });
          }}
      >
        <Pane>
          {!_.isEmpty(editingValue) && (
            <TextInputField
              required
              label={editingValue.label || 'New value'}
              value={newValue || editingValue.value || 0}
              onChange={(e) => setNewValue(e.target.value)}
            />
          )}
        </Pane>
      </CustomDialog>
    </React.Fragment>
  );
};

SummaryPage.propTypes = {
  accountId: PropTypes.string.isRequired,
  weekStart: PropTypes.instanceOf(Date).isRequired
};

export default SummaryPage;
