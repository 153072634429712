//React
import React from 'react';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Pane, Table, Text, useTheme } from 'evergreen-ui';

//Files
import { colors } from '../../../utils/constants';
import { currency } from '../../../utils/format';
import { calcStocktakeIngredientCost } from '../../../utils/functions';


const valuesInit = { 
  unitStockDisplay: 0, 
  thisStockDisplay: 0, 
  varianceWorth: 0, 
  livestockDisplay: 0, 
  unitStockValue: 0 // Initialize unitStockValue here
};


// const amountType = (type) => type.replace('Display', 'Amount');

const calculateIngWorth = (type, ing) => {
  if (type === 'varianceWorth') return ing[type];
  if (type === 'unitStockValue') return ing.unitStockValue || 0; // Return unitStockValue if available

  return calcStocktakeIngredientCost({
    ...ing,
    amount: parseFloat(ing[type]),
    recordUOM: ing.startRecordUOM,
    recipeunit: ing.ingredient ? ing.ingredient.recipeunit : ing.recipeunit
  });
};

const createValues = (ings) => {
  const result = _.reduce(ings, (values, ing) => {
    const updatedValues = valuesAdd(values, ing);
    //console.log("Updated Values after adding ingredient:", updatedValues); // Log to check unitStockValue
    return updatedValues;
  }, { ...valuesInit });
  
  //console.log("Final Aggregated Values:", result); // Final check
  return result;
};

const valuesAdd = (values, ing) => {
  for (const type in values) {
    const worth = calculateIngWorth(type, ing);
    //console.log(`Adding ${type}:`, worth, "to total:", values[type]);
    values[type] += worth;
  }
  return values;
}

const valueRender = (header, values) => {
  if (header.label === 'Name') return 'Values';
  
  if (header.field && values[header.field]) return currency(values[header.field]);

  if (header.field && values[header.field] === 0) return '0.00';
}

const textValueColor = (header, theme) => {
  if (header.label === 'Name') return theme.colors.black;
  return theme.colors.tertiary100;
}

const textJusitfyValues = (header, justify) => {
  if (!justify) return 'flex-start';

  if (header.label === 'Name') return 'flex-start';
  
  return 'flex-end';
}



const ValuesTables = ({values, headers, justify}) => {
  const theme = useTheme();
  return (
  <Table background='#F5F6F7'>
    <Table.Body>
      <Table.Row>
        {headers.map(header =>
          <Table.Cell key={header.label} justifyContent={textJusitfyValues(header, justify)} padding={majorScale(2)}>
            <Text color={textValueColor(header, theme)}>{valueRender(header, values)}</Text>
          </Table.Cell>
        )}
      </Table.Row>
    </Table.Body>
  </Table>
  )
}

const ValuesPane = ({ings, mobileTableHeaders, tableHeaders, isMobile, justify}) => {
  const values = createValues(ings);
  return (
    <Pane>
      <ValuesTables
        values={values}
        headers={(isMobile) ? mobileTableHeaders : tableHeaders}
        justify={justify}
      />
    </Pane>
  )
}

ValuesPane.propTypes = {
  ings: PropTypes.array.isRequired
}

export default ValuesPane;
