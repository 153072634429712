//React
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Pane, Text, Table, Checkbox, SelectMenu, Paragraph, Strong, useTheme } from 'evergreen-ui';

//Components
import DataTable from '../../../components/DataTable/DataTable';
import FilterBar from '../../../components/FilterBar/FilterBar';
import Block from '../../../components/ui/Block/Block';
import Button from '../../../components/ui/Button/Button';
import IconWrapper from '../../../components/Icons/Icons';
import CustomHeading from '../../../components/Headings/Headings';
import CustomDialog from '../../../components/Dialog/Dialog';
import Accordion from '../../../components/ui/Accordion/Accordion';

//Files
import { accountTypes } from '../../../utils/constants';
import { current, activeAccount } from '../../../utils/selectors';
import { filterItems, generateSearchFieldsFn, getIngredientById } from '../../../utils/functions';
import { actions } from '../../../store/actions';
import { requestMEPSupplierOptions } from '../../../store/firebase/supplierOptions';


const tableHeaders = [
  { label: 'Name', field: 'name', type: 'text', width: 4 },
  { label: 'Item code', field: 'supplier_code', type: 'text', width: 2 },
  { label: 'UOM', field: 'uom', type: 'text', width: 2 },
  { label: 'Price', field: 'unitprice', type: 'numeric', format: 'currency', width: 2 },
  { label: 'VAT', field: 'vat', type: 'calc', calc: (item) => { const vat = item.vat || 0; return `${vat}`; }, suffix: '%', width: 1 },
  { label: 'Linked to', field: 'ingredientId', type: 'calc', calc: (item) => {
    if (item.ingredientId) {
      const ing = getIngredientById(item.ingredientId);
      return (ing) ? ing.name : '';
    }
    return '';
  }, width: 2 },
  { label: 'Edit', field: 'edit', type: 'action', icon: 'edit', buttonProps: { appearance: 'clickable' }, width: 1 },
  { label: 'Deactivate', field: 'Deactivate', type: 'action', icon: 'lock', buttonProps: { appearance: 'warning' }, width: 1 },
  { label: 'Delete', field: 'delete', type: 'action', icon: 'trash', buttonProps: { appearance: 'danger' }, width: 1 },
];

const tableArchivedHeaders = [
  { label: 'Name', field: 'name', type: 'text', width: 4 },
  { label: 'Item code', field: 'supplier_code', type: 'text', width: 2 },
  { label: 'UOM', field: 'uom', type: 'text', width: 2 },
  { label: 'Price', field: 'unitprice', type: 'numeric', format: 'currency', width: 2 },
  { label: 'VAT', field: 'vat', type: 'calc', calc: (item) => { const vat = item.vat || 0; return `${vat}`; }, suffix: '%', width: 4 },
  { label: 'Activate', field: 'activate', type: 'action', icon: 'unlock', buttonProps: { appearance: 'warning' }, width: 1 },
];

const buildMepSupplierHeaders = (categories) => {
  return [
    { label: 'Name', field: 'name', type: 'text', width: 4 },
    { label: 'Category', field: 'categoryId', type: 'calc', calc: (item) => item.categoryId ? categories[item.categoryId]?.name : null, width: 3 },
    { label: 'Item code', field: 'supplier_code', type: 'text', width: 2 },
    { label: 'UOM', field: 'uom', type: 'text', width: 1 },
    { label: 'Est. Price', field: 'unitprice', type: 'numeric', format: 'currency', width: 2 },
    { label: 'VAT', field: 'vat', type: 'calc', calc: (item) => { const vat = item.vat || 0; return `${vat}`; }, suffix: '%', width: 1  },
    { label: 'Edit', field: 'edit', type: 'action', icon: 'edit', buttonProps: { appearance: 'clickable' }, width: 1},
    //{ label: 'Archive', field: 'archive', type: 'action', icon: 'lock', buttonProps: { appearance: 'warning' }, width: 1 },
    { label: 'Delete', field: 'delete', type: 'action', icon: 'trash', buttonProps: { appearance: 'danger' }, width: 1 },
  ];
}

//TODO Check 3 Rendering due to 3 states
const DatabaseEdit = ({supplierId}) => {
  const accountId = useSelector(activeAccount);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const supplierOptions = useSelector((state) => {
    return (
      _.sortBy(
        _.filter(
          current(state, 'supplierOptions'),
          { 'supplierId': supplierId }
        ),
        'name'
      )
    );
  }).filter(s => !s.un_verified);

  const categoryOptions = useSelector((state) => {
    return (
      _.sortBy(
        current(state, 'supplierCategories'),
        'name'
      )
    );
  });

  const supplierCategories = useSelector(state => _.keyBy(state.supplierCategories[state.currentAccount], 'id'))
  const account = useSelector(state => state.accounts[state.currentAccount]);
  const supplier = useSelector((state) => state.suppliers[state?.currentAccount]?.find(s => s.id === supplierId));
  
  const isSlave = supplier?.type?.includes('SLAVE');
  const isMepSupplier = account?.type?.includes(accountTypes.SUPPLIER);
  
  //All options available for that supplier
  const [options, setOptions] = useState(supplierOptions); 
  //All options available to the user
  const [addOptions, setAddOptions] = useState(supplierOptions.map(o => o.parent && !o.deleted));
  const [loading, setLoading] = useState(false);

  const [catFilters, setCatFilters] = useState([]);
  const [catOptions, setCatOptions] = useState(categoryOptions);

  const [archiveDialogShown, setArchiveDialogShown] = useState(false);
  const [optionToArchive, setOptionToArchive] = useState(null);

  const [unarchiveDialogShown, setUnarchiveDialogShown] = useState(false);
  const [optionToUnarchive, setOptionToUnarchive] = useState(null);

  const [removeDialogShown, setRemoveDialogShown] = useState(false);
  const [optionToRemove, setOptionToRemove] = useState(null);

  const [filters, setFilters] = useState({});
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const toggleAddOptions = (optionId, isChecked) => {
    setAddOptions(prevOptions => {
      if (isChecked) {
        // Prevent adding duplicate options
        if (!prevOptions.includes(optionId)) {
          return [...prevOptions, optionId];
        }
      } else {
        return prevOptions.filter(id => id !== optionId);
      }
      return prevOptions;
    });
  };
  
  useEffect(() => {
    if (isSlave) {
      const buildSupplierOptions = async () => {
        setLoading(true); // Start loading state
  
        try {
          const fetchedOptions = await requestMEPSupplierOptions(supplier); // Fetch options and categories
  
          if (fetchedOptions?.options.length > 0) {
            setAddOptions((prevOptions) => {
              const filteredOptions = prevOptions.filter(o => fetchedOptions.options.findIndex(fO => fO.id === o) > -1);
              return filteredOptions;
            });
          }
  
          setOptions(fetchedOptions.options); // Update options state
          setCatOptions(fetchedOptions.categoryOptions); // Update category options state
        } catch (error) {
          console.error('Error fetching supplier options:', error); // Log any errors
        } finally {
          setLoading(false); // End loading state
          //console.log('Loading finished'); // Log loading finished
        }
      };
  
      buildSupplierOptions();
    }
  }, [setOptions, supplier, isSlave, setCatOptions, setAddOptions]);

  useEffect(() => {
    if (!isSlave && options.length !== supplierOptions.length) {
      setOptions([...supplierOptions]);
    }
  }, [supplierOptions, options, isSlave])

  const categoryMap = useMemo(() => _.keyBy(categoryOptions, 'id'), [categoryOptions]);

  const activeOptions = options.filter(option => !option.deleted && !option.archived);

  const sortedActiveOptions = isSlave ?
      _.sortBy(activeOptions, (opt) => supplierOptions.findIndex(o => o.parent === opt.id) === -1) :
      activeOptions;

  const archivedOptions = options.filter(option => option.archived);

  const confirmArchiveOption = () => {
    const optionId = optionToArchive.id;
    dispatch(actions.supplierOptions.archiveSupplierOption(optionId));  
    if (optionToArchive.ingredientId) {
      dispatch(actions.supplierOptions.removeSupplierOptionFromCollection(accountId, optionToArchive?.ingredientId, optionId, false));  
    }
    setArchiveDialogShown(false);
  };

  const confirmRemoveOption = () => {
    const optionId = optionToRemove.id;
    console.log(optionToRemove, optionId)
    dispatch(actions.supplierOptions.removeSupplierOption(optionId)); 
    if (optionToRemove.ingredientId) {
      dispatch(actions.supplierOptions.removeSupplierOptionFromCollection(accountId, optionToRemove?.ingredientId, optionId, false));  
    }
    setRemoveDialogShown(false);
  };

  const searchOnChange = (newSearchValue) => {
    if (!newSearchValue) {
      setFilters((prevFilters) => {
        const { search, ...newFilters } = prevFilters;
        return newFilters;
      });
    }
    else {
      setFilters((prevFilters) => {
        return {
          ...prevFilters,
          search: generateSearchFieldsFn(['name'], newSearchValue),
        };
      });
    }
  };

  const importSelected = async () => {
    setLoading(true);
  
    try {
      const updateOptions = [];
      const newOptions = [];
  
      // Prepare the options for import, checking if they already exist in the user's database
      addOptions.forEach(optionId => {
        const option = options.find(o => o.id === optionId);
        const existingOption = supplierOptions.find(o => o.parent === optionId);
  
        if (existingOption) {
          // If the option exists, update its status
          updateOptions.push({
            ...existingOption,
            deleted: false, // Change status from deleted to not deleted
          });
        } else {
          const {
            buyer_id,
            invoice_upload_id,
            un_verified,
            option_matches,
            ...filteredOption
          } = option; // Destructure to remove unwanted fields

          newOptions.push({
            ...filteredOption,
            supplierId: supplier.id,
            type: 'SLAVE',
          });
        }
      });
  
      // Dispatch an action to update existing supplier options and create ingredients
      for (const updateOption of updateOptions) {
        // Create a new ingredient for the supplier option
        const newIngredient = {
          name: updateOption.name,
          recipeunit: updateOption.base_uom,
          categoryId: updateOption.categoryId || null,
          supplierOptions: [
            {
              id: updateOption.id,
              name: updateOption.name,
              defaultOption: true, // Default option since it's the first
            },
          ],
          allergens: [],
          archived: false,
          deleted: false,
        };

        // Create the ingredient and get its ID
        let newIngredientId = null;
        await new Promise((resolve) => {
          dispatch(actions.ingredients.addIngredient(newIngredient, (ingredient) => {
            newIngredientId = ingredient.id;
            resolve();
          }));
        });

        // Update the supplier option with the newly created ingredient ID
        const updatedOptionWithIngredient = {
          ...updateOption,
          ingredientId: newIngredientId,
          deleted: false, // Ensure the option is reactivated
        };

        // Dispatch the updateSupplierOption action with the updated supplier option
        await dispatch(actions.supplierOptions.updateSupplierOption(updatedOptionWithIngredient));
      }
  
      // Filter out any invalid new options
      const validNewOptions = newOptions.filter(o => o.id);
  
      // Dispatch an action to import the new supplier options
      if (validNewOptions.length > 0) {
        await dispatch(actions.supplierOptions.importSupplierOptions(validNewOptions));
      }
    } catch (error) {
      console.error('Error importing selected options:', error);
    } finally {
      setLoading(false);
    }
  };  

  const categoryFilterFn = (catFilters) => (item) => {
    if (!_.isEmpty(catFilters)) {
      const itemCategories = [];
      let catId = item.categoryId;
      itemCategories.push(catId);
      while (categoryMap[catId] && categoryMap[catId].parent) {
        catId = categoryMap[catId].parent;
        itemCategories.push(catId);
      }

      for (let x = 0, ln = catFilters.length; x < ln; x++) {
        // Match any category selected
        if (_.includes(itemCategories, catFilters[x])) {
          return true;
        }
      }
      // No categories matched
      return false;
    }
    return true;
  };

  const updateFilters = (filterName, value) => {
    setFilters((prevFilters) => {
      if (!value) {
        let newFilters = Object.assign({}, prevFilters);
        delete newFilters[filterName];
        return newFilters;
      }
      return { ...prevFilters, [filterName]: value };
    });
  };

  const addCategoryToFilter = (catId) => {
    let newList = [];
    setCatFilters((prev) => {
      newList = [...prev, catId];
      return newList;
    });
    updateFilters('category', categoryFilterFn(newList));
  };

  const removeCategoryFromFilter = (catId) => {
    let newList = [];
    setCatFilters((prev) => {
      newList = _.without(prev, catId);
      return newList;
    });
    updateFilters('category', categoryFilterFn(newList));
  };

  const confirmUnarchiveOption = () => {
    navigate(`/supplier-ingredient/${supplier.id}/${optionToUnarchive.id}`);
  };
  
  const height = 338;

  return (
    <>
      <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
        <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
          <CustomHeading level="3" flex="1 0 auto">Supplier options</CustomHeading>
          {!isSlave && (
            <Button
                marginLeft={majorScale(2)}
                is={Link}
                to={`/supplier-ingredient/${supplierId}/add`}
                appearance="primary"
            >Add supplier option</Button>
          )}
        </Pane>
        <Pane display='flex' alignItems='center'>
          {(isSlave || isMepSupplier) && (
            <SelectMenu
                hasFilter={false}
                hasTitle={false}
                isMultiSelect={true}
                selected={catFilters}
                options={_.map(catOptions, (option) => ({ label: _.repeat('--', option.level) + option.name, value: option.id }))}
                onSelect={(item) => addCategoryToFilter(item.value)}
                onDeselect={(item) => removeCategoryFromFilter(item.value)}
                marginRight={majorScale(2)}
            >
              <Button
                  marginRight={majorScale(2)}
                  iconBefore="filter"
                  appearance='flat'
              >{(!_.isEmpty(catFilters)) ? _.join(_.map(catFilters, (catId) => categoryMap[catId].name), ', ') : 'Categories'}</Button>
            </SelectMenu>
          )}
          <FilterBar
              filters={filters}
              searchPlaceholder="Search for a supplier option"
              searchOnChange={searchOnChange}
              flex="1 0 auto"
          />
        </Pane>
      </Block>

      {!isSlave && (
        <>
          <Block flex={`1 0 auto`} display="flex" height="480px" flexDirection="column" marginBottom={majorScale(2)}>
            <Pane flex="1" overflowY="auto" display="flex" flexDirection="column">
              <DataTable
                  items={sortedActiveOptions}
                  filters={filters}
                  headers={isMepSupplier ? buildMepSupplierHeaders(supplierCategories) : tableHeaders}
                  onDelete={(option) => { setOptionToRemove(option); setRemoveDialogShown(true); }}
                  onDeactivate={(option) => { setOptionToArchive(option); setArchiveDialogShown(true); }}
                  onEdit={(option) => navigate(`/supplier-ingredient/${supplierId}/${option.id}`)}
                  flex="1 0 auto"
                  listHeight={460}
              />
            </Pane>
          </Block>
          
          <Accordion
            isOpen={isAccordionOpen}
            toggleOpen={() => setIsAccordionOpen(!isAccordionOpen)}
            label="Deactivated supplier options"
            marginBottom={majorScale(2)}
          >
            <Pane flex="1" overflowY="auto" display="flex" maxHeight="480px" flexDirection="column">
              <DataTable
                  items={archivedOptions}
                  filters={filters}
                  headers={tableArchivedHeaders}
                  onActivate={(option) => { setOptionToUnarchive(option); setUnarchiveDialogShown(true); }}
                  flex="1 0 auto"
                  listHeight={460}
              />
            </Pane>
          </Accordion>
        </>
      )}

      {isSlave && (
        <>
          <Block flex="1 0 auto" display="flex" maxHeight="480px" flexDirection="column" marginBottom={majorScale(2)}>
            <Pane flex="1" overflowY="auto" display="flex" flexDirection="column">
              <CustomHeading level='4' padding={majorScale(2)}>Your supplier options</CustomHeading>
                
                {loading && (
                  <Pane height='460px' padding={majorScale(2)}>
                    <Text>Loading...</Text>
                  </Pane>
                )}

                {!loading && (
                  <Pane>
                    <Table display="flex" flexDirection="column" flex="1 0 auto" maxHeight='460px'>
                      <Table.Head>
                        <Table.TextHeaderCell minWidth="200px">Name</Table.TextHeaderCell>
                        <Table.TextHeaderCell>Category</Table.TextHeaderCell>
                        <Table.TextHeaderCell>Item code</Table.TextHeaderCell>
                        <Table.TextHeaderCell>UOM</Table.TextHeaderCell>
                        <Table.TextHeaderCell>Est. price</Table.TextHeaderCell>
                        <Table.TextHeaderCell>VAT</Table.TextHeaderCell>
                        <Table.TextHeaderCell>Edit</Table.TextHeaderCell>
                        <Table.TextHeaderCell>Deactivate</Table.TextHeaderCell>
                        <Table.TextHeaderCell>Delete</Table.TextHeaderCell>                
                      </Table.Head>
                      
                      <Table.Body height={height} maxHeight={height} overflowY='scroll'>
                        {filterItems(sortedActiveOptions, filters).map(option => {
                          const userOption = supplierOptions.find(o => o.parent === option.id);
                          //console.log(userOption)
                          const isAdded = !!userOption;

                          if (isAdded && !userOption?.archived && !userOption?.deleted) {
                            return (
                              <Table.Row key={option.id}>
                                <Table.TextCell
                                  display="flex"
                                  height="auto"
                                  minWidth="200px"
                                  textProps={{ textOverflow: 'unset', overflow: 'unset', whiteSpace: 'initial' }}
                                >
                                  {option.name}
                                </Table.TextCell>
                                <Table.TextCell>{option.category?.name}</Table.TextCell>
                                <Table.TextCell>{option.supplier_code}</Table.TextCell>
                                <Table.TextCell>{option.uom}</Table.TextCell>
                                <Table.TextCell>{userOption?.unitprice || option?.unitprice}</Table.TextCell>
                                <Table.TextCell>{option.vat ? 'Y' : 'N'}</Table.TextCell>
                                <Table.Cell>
                                  <IconWrapper 
                                    name='edit' 
                                    appearance="clickable"
                                    onClick={() => navigate(`/supplier-ingredient/${supplierId}/${option.type?.includes('MASTER') ? supplierOptions.find(s => s.parent === option.id)?.id : option.id}`)} 
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  <IconWrapper
                                    onClick={() => { setOptionToArchive(userOption); setArchiveDialogShown(true); }}
                                    name='lock'
                                    appearance="clickable"
                                    color="warning"
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  <IconWrapper
                                    onClick={() => { setOptionToRemove(userOption); setRemoveDialogShown(true); }}
                                    name='trash'
                                    appearance="clickable"
                                    color="danger"
                                  />
                                </Table.Cell>
                              </Table.Row>
                              )
                            }
                            return null
                          })}
                      </Table.Body>
                    </Table>
                  </Pane>
                )}
              </Pane>
            </Block>

            <Block flex={`1 0 auto`} display="flex" maxHeight="480px" flexDirection="column" marginBottom={majorScale(2)}>
              <Pane flex="1" overflowY="auto" display="flex" flexDirection="column">
                <Pane display='flex' justifyContent='space-between' alignItems='center' >
                  <CustomHeading level='4' padding={majorScale(2)}>Supplier options</CustomHeading>
                  {isSlave && (

                    <Button
                        margin={majorScale(2)}
                        appearance='primary'
                        onClick={importSelected}
                    >Save</Button>
                  )}
                </Pane>
                  {loading && (
                    <Pane padding={majorScale(2)} maxHeight="460px">
                      <Text>Loading...</Text>
                    </Pane>
                  )}

                  {!loading && (
                    <Pane>
                      <Table display="flex" flexDirection="column" flex="1 0 auto" maxHeight='460px'>
                        <Table.Head>
                          <Table.TextHeaderCell minWidth="200px">Name</Table.TextHeaderCell>
                          <Table.TextHeaderCell>Category</Table.TextHeaderCell>
                          <Table.TextHeaderCell>Item code</Table.TextHeaderCell>
                          <Table.TextHeaderCell>UOM</Table.TextHeaderCell>
                          <Table.TextHeaderCell>Est. price</Table.TextHeaderCell>
                          <Table.TextHeaderCell>VAT</Table.TextHeaderCell>
                          <Table.TextHeaderCell>Add</Table.TextHeaderCell>
                        </Table.Head>

                        <Table.Body height={height} maxHeight={height} overflowY='scroll'>
                          {filterItems(sortedActiveOptions, filters).map(option => {
                            const userOption = supplierOptions.find(o => o.parent === option.id);
                            const isAdded = !!userOption;
                            let isChecked = addOptions.indexOf(option.id) > -1;

                            if (!isAdded || userOption?.deleted) {
                            return (
                              <Table.Row key={option.id}>
                                <Table.TextCell display='flex' height='auto' minWidth='200px' textProps={{textOverflow: 'unset', overflow: 'unset', whiteSpace: 'initial'}}>{option.name}</Table.TextCell>
                                <Table.TextCell>{option.category?.name}</Table.TextCell>
                                <Table.TextCell>{option.supplier_code}</Table.TextCell>
                                <Table.TextCell>{option.uom}</Table.TextCell>
                                <Table.TextCell>{userOption?.unitprice || option?.unitprice}</Table.TextCell>
                                <Table.TextCell>{option.vat ? 'Y' : 'N'}</Table.TextCell>
                                <Table.Cell>
                                  <Checkbox
                                      checked={isChecked}
                                      onChange={(e) => toggleAddOptions(option.id, e.target.checked)}
                                      flexBasis="15%"
                                  />
                                </Table.Cell>
                              </Table.Row>
                            )
                          }
                          return null
                          })}
                        </Table.Body>
                      </Table>
                    </Pane>
                  )}
              </Pane>
            </Block>

            <Block flex={`1 0 auto`} display="flex" maxHeight='480px' flexDirection="column" marginBottom={majorScale(2)}>
              <Pane flex="1" overflowY="auto" display="flex" flexDirection="column">
                <Accordion
                  isOpen={isAccordionOpen}
                  toggleOpen={() => setIsAccordionOpen(!isAccordionOpen)}
                  label="Deativated supplier options"
                  marginBottom={0}
                >
                  {loading && (
                    <Pane padding={majorScale(2)} height='460px'>
                      <Text>Loading...</Text>
                    </Pane>
                  )}
                  {!loading && (
                    <Pane>
                      <Table display="flex" flexDirection="column" flex="1 0 auto" maxHeight='460px'>
                        <Table.Head>
                          <Table.TextHeaderCell minWidth="200px">Name</Table.TextHeaderCell>
                          <Table.TextHeaderCell>Category</Table.TextHeaderCell>
                          <Table.TextHeaderCell>Item code</Table.TextHeaderCell>
                          <Table.TextHeaderCell>UOM</Table.TextHeaderCell>
                          <Table.TextHeaderCell>Est. price</Table.TextHeaderCell>
                          <Table.TextHeaderCell>VAT</Table.TextHeaderCell>
                          <Table.TextHeaderCell>Activate</Table.TextHeaderCell>
                        </Table.Head>

                        <Table.Body height={height} maxHeight={height} overflowY='scroll'>
                          {filterItems(sortedActiveOptions, filters).map(option => {
                            const userOption = supplierOptions.find(o => o.parent === option.id);
                            const isAdded = !!userOption;

                            if (isAdded && (userOption?.archived)) {
                            return (
                              <Table.Row key={option.id}>
                                <Table.TextCell display='flex' height='auto' minWidth='200px' textProps={{textOverflow: 'unset', overflow: 'unset', whiteSpace: 'initial'}}>{option.name}</Table.TextCell>
                                <Table.TextCell>{option.category?.name}</Table.TextCell>
                                <Table.TextCell>{option.supplier_code}</Table.TextCell>
                                <Table.TextCell>{option.uom}</Table.TextCell>
                                <Table.TextCell>{userOption?.unitprice || option?.unitprice}</Table.TextCell>
                                <Table.TextCell>{option.vat ? 'Y' : 'N'}</Table.TextCell>
                                <Table.Cell>
                                  <IconWrapper
                                    onClick={() => { setOptionToUnarchive(userOption); setUnarchiveDialogShown(true); }}
                                    name='unlock'
                                    appearance="clickable"
                                    intent="warning"
                                    color="warning"
                                  />
                                </Table.Cell>
                              </Table.Row>
                            )
                          }
                          return null
                          })}
                        </Table.Body>
                      </Table>
                    </Pane>
                  )}
                </Accordion>
              </Pane>
            </Block>
          </>
        )}

      <CustomDialog
        isOpen={archiveDialogShown}
        title="Deactivating supplier option"
        confirmLabel="Deactivate"
        onClose={() => setArchiveDialogShown(false)}
        onConfirm={confirmArchiveOption}
        onCloseComplete={() => setArchiveDialogShown(false)}
      >
        <Pane marginBottom={majorScale(3)}>
          <Paragraph marginBottom={majorScale(2)}>
            Deactivating <Strong>{optionToArchive?.name}</Strong> will make it unavailable for this account. 
          </Paragraph>
          <Paragraph marginBottom={majorScale(2)}>
            The link between {optionToArchive?.name} and the associated ingredient will be removed.
          </Paragraph>
          <Paragraph marginBottom={majorScale(2)}>
            You can reactivate {optionToArchive?.name} at any time. 
          </Paragraph>
        </Pane>
      </CustomDialog>

      <CustomDialog
          isOpen={removeDialogShown}
          title="Deleting supplier option"
          confirmLabel="Delete"
          onConfirm={confirmRemoveOption}
          onClose={() => setRemoveDialogShown(false)}
          onCloseComplete={() => setRemoveDialogShown(false)}
      >
        <Pane marginBottom={majorScale(3)}>
          <Paragraph marginBottom={majorScale(2)}>
            Deleting <Strong>{optionToRemove?.name}</Strong> will make it unavailable for this account. 
          </Paragraph>
          <Paragraph marginBottom={majorScale(2)}>
            This supplier option will be permanently deleted from this account and will not be accessible again.
          </Paragraph>
        </Pane>
      </CustomDialog>

      <CustomDialog
          isOpen={unarchiveDialogShown}
          title="Reactivating supplier option"
          confirmLabel="Next"
          onConfirm={confirmUnarchiveOption}
          onClose={() => setUnarchiveDialogShown(false)}
          onCloseComplete={() => setUnarchiveDialogShown(false)}
      >
        <Pane marginBottom={majorScale(3)}>
          <Paragraph marginBottom={majorScale(2)}>
            In order to reactivate <Strong>{optionToUnarchive?.name}</Strong>, it must be linked to an ingredient. If no ingredient is linked, one will need to be created.
          </Paragraph>
          <Paragraph marginBottom={majorScale(2)}>
            By clicking "Save" on the next page, a new ingredient with the same information as the supplier option will be automatically created.
          </Paragraph>
        </Pane>
      </CustomDialog>
    </>
  );
};

DatabaseEdit.propTypes = {
  supplierId: PropTypes.string.isRequired,
};

export default DatabaseEdit;
