import { accountsRef } from './accounts';
import { getCollectionRef } from './common';
import store from '../redux';
import { actions } from '../actions';

export const addSupplier = (supplier, accountId) => {
  const suppliersRef = getCollectionRef('suppliers', accountId);
  return suppliersRef.add(supplier);
};

export const removeSupplier = (supplierId, accountId) => {
  const suppliersRef = getCollectionRef('suppliers', accountId);
  return suppliersRef.doc(supplierId).delete();
};

export const toggleArchiveSupplier = (supplierId, supplier, accountId) => {
  const suppliersRef = getCollectionRef('suppliers', accountId);
  return suppliersRef.doc(supplierId).update({ ...supplier, archive: supplier.archive ? false : true  });
};

export const updateSupplier = (supplierId, supplier, accountId) => {
  const suppliersRef = getCollectionRef('suppliers', accountId);
  return suppliersRef.doc(supplierId).update(supplier);
};

export const getSupplierAccounts = () => {
  const accounts = accountsRef.where('type', '==', 'SUPPLIER').get();
  return accounts;
}

export const getSupplierMasterDetails = async (supplier, accountId) => {
  if (!accountId) {
    return null;
  }

  const { parent } = supplier;
  const account = accountsRef.doc(parent);
  const supplierCollect = await account.collection('suppliers').get();
  const accountData = await account.get();
  const status = await accountData.data()?.accounts?.includes(accountId) ? 'ACCEPTED' : 'REQUESTED';
  const supplierDetails = supplierCollect.docs.length > 0 ? supplierCollect.docs[0].data() : null;
  if (supplierDetails) {
    return {...supplierDetails, supplierId: supplierCollect.docs[0].id, ...supplier, status};
  }
}

export const processSuppliers = async (suppliers, account) => {
  if (!account) {
    return null;
  }

  const slaveSuppliers = suppliers.filter(s => s.parent && s.type && s.type === 'SLAVE');
  if (slaveSuppliers.length > 0) {
    for (const supplier of slaveSuppliers) {
      const details = await getSupplierMasterDetails(supplier, account);
      if (details) {
        const index = slaveSuppliers.findIndex(s => s.id === details.id);
        if (index > -1) {
          slaveSuppliers[index] = details;
        }
      }
    }
    return slaveSuppliers;
  }
}