//React + Libraries
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

//Libraries
import { Pane, useTheme } from 'evergreen-ui';

//Pages
import './App.css';
import AccountSelect from './pages/AccountSelect/AccountSelect';

//Components
import NavMenu from './components/NavMenu/NavMenu';
import NavMenuMobile from './components/NavMenuMobile/NavMenuMobile';
import Header from './components/Header/Header';
import LoadingIndicator from './components/IsLoading/IsLoading';

//Files
import { actions } from './store/actions'
import { getAccountsData } from './utils/reports'

//TODO - review the loading collection process isLoading vs asyncLoading
const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const state = useSelector((state) => state);
  const isLoading = useSelector((state) => state.loading);
  const backgroundLoading = useSelector((state) => state.backgroundLoading);
  const firstLoad = useSelector((state) => state.firstLoad.firstLoad);
  const user = useSelector((state) => state.user);
  const currentAccountId = useSelector((state) => state.currentAccount);
  const account = useSelector((state) => state.accounts[currentAccountId]);
  const isDesktop = useMediaQuery({ minWidth: 769 });
  const hasActiveAccount = currentAccountId && currentAccountId !== 'pending';
  //console.log(firstLoad, currentAccountId, hasActiveAccount, isLoading, backgroundLoading)

  useEffect(() => {
    if (!backgroundLoading && account && account.type === 'OWNER') {
      const aggregatedAccountsData = getAccountsData(state, currentAccountId);
      dispatch(actions.reports.accountsDataSet(aggregatedAccountsData));
    }
  }, [dispatch, account, backgroundLoading, currentAccountId]);

  useEffect(() => {
    if (!user) {
      navigate("/signin", { replace: true });
    }
  }, [user, navigate]);

  return (
    <>
      {/* Step 1: Show Loading while `hasActiveAccount` is pending */}
      {hasActiveAccount && user && isLoading ? (
        <Pane display="flex" alignItems='center' justifyContent='center' height="100vh" width="100%">
          <LoadingIndicator message={"We are setting up your account..."} />
        </Pane>
      ) : hasActiveAccount && user ? (
        <Pane display="flex" flexDirection="row" height="100vh" width="100vw">
          {/* Step 2: Show NavMenu and Header once `isLoading` is false */}
          {!isLoading && (
            <>
              <NavMenu />
              <Pane
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                flex="1 1 auto"
                overflow="hidden"
                maxHeight="100vh"
                maxWidth={isDesktop && hasActiveAccount ? 'calc(100% - 230px)' : '100vw'}
              >
                <Header />
                {/* Step 3: Only show Main Content when background loading is complete */}
                {backgroundLoading ? (
                  <LoadingIndicator message={"We are loading your data..."} />
                ) : (
                  <Pane is="main" display="flex" flexDirection="column" overflowY="auto" background={theme.colors.white}>
                    {hasActiveAccount && (
                      <NavMenuMobile style={{ position: 'absolute', bottom: 0, width: '100%' }} />
                    )}
                    <Outlet />
                  </Pane>
                )}
              </Pane>
            </>
          )}
        </Pane>
      ) : !hasActiveAccount && user ? (
        <AccountSelect />
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default App;
