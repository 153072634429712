import _ from 'lodash';
import store from '../redux';
import fb from './';
import { actions } from '../actions';

/**
 * Adds a Firestore listener for a specific account and recursively sets up listeners for its children.
 * 
 * ### Key Behavior:
 * 1. **Real-time Updates**:
 *    - A listener is added to the specified `accountId` and recursively to all child accounts.
 *    - Ensures all accounts in the hierarchy receive real-time updates.
 * 
 * 2. **Recursive Listener Addition**:
 *    - For each child account in the `children` array, this function calls itself to add a listener.
 *    - Recursion stops when an account has no `children` or its `children` array is empty.
 * 
 * 3. **Duplicate Prevention**:
 *    - Before adding a listener for a child account, the function checks if a listener already exists using `accountListeners`.
 * 
 * 4. **Full Hierarchy Load**:
 *    - All accounts (parents and children) are loaded up front.
 *    - When all accounts are loaded, the promise resolves.
 * 
 * ### Why This Approach Works:
 * Ensures consistent loading order.  
 * Handles both success and failure cleanly.  
 * Prevents race conditions and partial state loads.  
 * Reduces Firestore reads and listener overhead by only adding unique listeners.  
 * Handles complex parent-child hierarchies with real-time updates.  
 * 
 * ### Current Limitations:
 * - All accessible accounts are loaded at once — no lazy loading yet.
 * - Large account hierarchies may increase initial load time.
 * 
 * @param {string} accountId - The ID of the account to add a listener for.
 */

let accountUsersListener;
const accountListeners = {};
let allAccountIds = new Set();

const accountUsersRef = fb.firestore.collection('accountUsers')
export const accountsRef = fb.firestore.collection('accounts')

const unlistenAccounts = () => {
  for (let id in accountListeners) {
    accountListeners[id]();
    delete accountListeners[id];
  }
};

const addAccountListener = async (accountId, callback, loadedAccounts, allIds) => {
  store.dispatch(actions.applicationLoadStart(accountId));
  allIds.add(accountId);

  const accountListener = accountsRef.doc(accountId).onSnapshot(async (doc) => {
    const account = { ...doc.data(), id: doc.id };

    if (account.children && account.children.length > 0) {
      // Track all child IDs
      account.children.forEach(childId => allIds.add(childId));
      
      await Promise.all(account.children.map(async (childAccountId) => {
        if (!accountListeners[childAccountId]) {
          
          // Pass the same loadedAccounts and allIds references
          await addAccountListener(childAccountId, callback, loadedAccounts, allIds);
        }
      }));
    }

    if (!account.deleted) {
      // Store this account in the loadedAccounts
      loadedAccounts[accountId] = account;
      // Check if we have loaded all accounts (parents and children)
      if (callback && Object.keys(loadedAccounts).length === allIds.size) {
        callback(loadedAccounts);
      }
    }

    store.dispatch(actions.applicationLoadComplete(accountId));
  });

  accountListeners[accountId] = accountListener;
};

const setAccountNull = () => {
  store.dispatch(actions.applicationLoaded());
}

/**
 * Loads all account documents associated with the provided user email.
 * Sets up listeners for each account and updates the Redux state with account data.
 * @param {string} userEmail - The email of the user whose accounts are being loaded.
 */
export const loadAccounts = (userEmail, currentAccountId) => {
   // First check if user is authenticated
  if (!userEmail) {
    console.log("Attempting to load accounts when user is not authenticated");
    return Promise.resolve(); // Return early if not authenticated
  }

  // Return a promise that resolves when initial account loading is complete
  return new Promise((resolve, reject) => {
    if (accountUsersListener) { 
      accountUsersListener();
    }
    unlistenAccounts();
    store.dispatch(actions.accounts.accountsClear());
    
    // Reset tracking
    allAccountIds = new Set();
    //console.log(`loading: accounts using accountUsers`);
    
    let dataReceived = false;
    const loadedAccounts = {};
    
    accountUsersListener = accountUsersRef
      .where('email', '==', userEmail)
      .onSnapshot(async (querySnapshot) => {
        //console.log(`Received account user data for email: ${userEmail}`);
        dataReceived = true;
        
        if (querySnapshot.empty) {
          // If the user has no accounts, mark application as loaded
          console.log("User has no accounts");
          store.dispatch(actions.applicationLoaded());
          resolve();
          return;
        }

        // Set up a callback that will be called when all accounts are loaded
        const onAllAccountsLoaded = (accounts) => {
          //console.log(`All accounts loaded`);
          store.dispatch(actions.accounts.accountsLoadSuccess(accounts, currentAccountId));
          resolve(); // Resolve the promise when all accounts are loaded
        };

        try {
          // Set up account listeners
          const setupPromises = [];
          
          querySnapshot.forEach((audoc) => {
            const accountUser = audoc.data();
            if (!accountListeners[accountUser.account]) {
              
              // Add a promise for this account listener setup
              setupPromises.push(
                addAccountListener(
                  accountUser.account, 
                  onAllAccountsLoaded, 
                  loadedAccounts, 
                  allAccountIds
                )
              );
            }
          });
          
          // Wait for all initial account listeners to be set up
          await Promise.all(setupPromises);
          
          // Handle the case where there might be no accounts after setup
          if (_.isEmpty(accountListeners) && dataReceived) {
            console.log("No account listeners set up after receiving data");
            setAccountNull();
            resolve();
          }
        } catch (error) {
          console.error("Error setting up account listeners:", error);
          reject(error);
        }
      }, (error) => {
        // Check if this is a permissions error (likely due to sign-out)
        if (error.code === 'permission-denied' || error.message.includes('Missing or insufficient permissions')) {
          //console.log("Permission denied due to authentication change - ignoring error");
          // Clean up this listener
          if (accountUsersListener) {
            accountUsersListener();
            accountUsersListener = null;
          }
          // Still resolve the promise to prevent hanging
          store.dispatch(actions.applicationLoaded());
          resolve();
        } else {
          // Handle other errors normally
          console.error("Error loading account users:", error);
          store.dispatch(actions.applicationLoaded());
          reject(error);
        }
      });
    
    // Add a timeout to prevent hanging if something goes wrong
    setTimeout(() => {
      if (!dataReceived) {
        console.warn("No data received from Firestore within timeout");
        setAccountNull();
        resolve();
      }
    }, 10000); // 10 second timeout
  });
};

// Removes all event listeners and clears cached data for account documents.
export const clearAccount = (accountId) => {
  if (accountListeners[accountId]) {
    accountListeners[accountId]();
  }
};

// This function removes all active listeners for accounts and clears any cached data.
export const clearAccounts = () => {
  unlistenAccounts()
}

// Adds a new account document to Firestore and returns the reference.
export const addAccount = (account) => {
  return accountsRef.add(account)
}

// Removes an account document from Firestore.
export const removeAccount = (accountId) => {
  return accountsRef.doc(accountId).delete()
}

// Updates a specific account document in Firestore with new data.
export const updateAccount = (accountId, account) => {
  return accountsRef.doc(accountId).update(account)
}
