//React
import React, { useMemo } from 'react';
import { useSelector} from 'react-redux';
import { useLocation, Link } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale } from 'evergreen-ui';

//Components
import Sales from './SalesComponents/Sales';
import LocationSales from './SalesComponents/LocationSales';
import ProductSales from './SalesComponents/ProductSales';
import Tab from '../../components/ui/Tab/Tab';
import TabNavigation from '../../components/ui/TabNavigation/TabNavigation';
import PeriodNavigation from './components/PerdiodNavigation';
import ReportFilters from './components/ReportFilters';

//Files
import { buildWeekDates } from '../..//utils/reports';


const tabs = {
  overview: 'Overview',
  locations: 'Locations',
  // departments: 'By department',
  products: 'Products sales'
};

const SalesTabs = ({ accountId, accounts, weekProps, filterProps }) => {
  const location = useLocation();
  const currentTab = location.pathname.split('/')[3] || 'overview';

  const accountsData = accounts
  const sales = useSelector(state => state.reports.salesData);

  const weekDatesProps = useMemo(() => {
    return weekProps.weekInfo;
  }, [weekProps.weekInfo]);
 const weekdates = buildWeekDates(weekDatesProps.start);

  const salesData = useMemo(() => ({ accountId, accountsData, sales, weekdates, filterProps }),
    [accountId, accountsData, sales, weekdates, filterProps]);
  //console.log(salesData)
  
  //Only for Filters, can be refactored
  const newAccounts = {...accountsData, filterProps }

  const tabComponents = {
    overview: <Sales {...salesData} type={currentTab} />,
    locations: <LocationSales {...salesData} type={currentTab} limit={false} />,
    "products": <ProductSales {...salesData} type={currentTab} limit={false} />,
  };

  return (
    <>
      <TabNavigation paddingBottom={majorScale(2)}>
        {_.map(tabs, (tabName, tabKey) => (
          <Tab
              key={tabKey}
              is={Link}
              to={`/analytics/sales/${tabKey}`}
              isSelected={currentTab === tabKey}
          >{tabName}</Tab>
        ))}
      </TabNavigation>
      <PeriodNavigation 
          weekInfo={weekProps.weekInfo}
          seeNextWeek={weekProps.seeNextWeek}
          seePrevWeek={weekProps.seePrevWeek}
      />
      <ReportFilters accounts={newAccounts} />
      {tabComponents[currentTab]}
    </>
  );
};

SalesTabs.propTypes = {
  accounts: PropTypes.object.isRequired,
  weekProps: PropTypes.object.isRequired
};

export default SalesTabs;
