//React
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { majorScale, Pane, SelectMenu, Table, Badge } from 'evergreen-ui';
import moment from 'moment';

//Components
import FilterBar from '../../../components/FilterBar/FilterBar';
import Block from '../../../components/ui/Block/Block';
import Button from '../../../components/ui/Button/Button';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';

//Files
import { recipeIngredientAllergens } from '../../../utils/functions';
import { generatePDF } from '../../../utils/exportContent';
import { current } from '../../../utils/selectors';

const AllergenView = ({...otherProps}) => {
  const { accountId } = useParams();
  const navigate = useNavigate();
  const contentRef = useRef();
  const allergens = useSelector((state) => state.allergens);
  const menus = useSelector((state) => current(state, 'menus', accountId));

  const [recipeFilter, setRecipeFilter] = useState('');
  const [selectedMenu, setSelectedMenu] = useState(menus[0]);

  const recipeMap = useSelector((state) => {
    if (!_.isEmpty(recipeFilter)) {
      return _.keyBy(_.filter(current(state, 'recipes', accountId), (recipe) => (_.includes(_.lowerCase(recipe.name), _.lowerCase(recipeFilter)))), 'id');
    }
    return _.keyBy(current(state, 'recipes', accountId), 'id');
  });  

  useEffect(() => {
    if (!selectedMenu && !_.isEmpty(menus)) {
      setSelectedMenu(menus[0]);
    }
  }, [menus, selectedMenu]);


  return (
    <React.Fragment>
      <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
        <Pane display="flex" alignItems="center" justifyContent="space-between">
          <CustomHeading level="3" marginBottom={majorScale(2)}>Allergens Charts</CustomHeading>
          <Button
            name="Download"
            onClick={() => generatePDF(contentRef.current, 'Allergens Charts', `Allergens_Charts_${selectedMenu.name}.pdf`, selectedMenu.name)}
            appearance='primary'
          >
            Download as PDF
        </Button>
        </Pane>
        <Pane display="flex">
          <SelectMenu
              hasTitle={false}
              hasFilter={false}
              title="Menu"
              options={_.map(menus, (menu) => ({ label: menu.name, value: menu.id }))}
              selected={selectedMenu ? selectedMenu.id : ''}
              onSelect={(item) => {
                setSelectedMenu(_.find(menus, { 'id': item.value }));
              }}
              closeOnSelect={true}
          >
            <Button
                width= "200px" 
                iconBefore="filter"
                appearance="flat"
                {...otherProps}
            >
              {(selectedMenu) ? selectedMenu.name : 'Select a menu'}
            </Button>
          </SelectMenu>
          <FilterBar
              searchPlaceholder="Search menu items"
              searchOnChange={(searchText) => setRecipeFilter(searchText)}
              flex="1 1 0"
              marginLeft={majorScale(2)}
          />
        </Pane>
      </Block>
  
      <Pane ref={contentRef}>
        {!_.isEmpty(selectedMenu) &&
          _.map(selectedMenu.sections, (menuSection, index) => (
            <Block
              marginBottom={majorScale(2)}
              key={_.kebabCase(index + ' ' + menuSection.name)}
            >
              <CustomHeading level="4" padding={majorScale(2)}>{menuSection.name}</CustomHeading>
              <Table>
                {/* Table Header */}
                <Table.Head display="flex" height={100}>
                  <Table.TextHeaderCell flex="1" minWidth="200px">
                    Name
                  </Table.TextHeaderCell>
                  {_.map(allergens, (allergen) => (
                    <Table.HeaderCell
                      key={allergen.id}
                      width={40}
                      maxWidth={48}
                      alignItems="center"
                      justifyContent="center"
                      display="flex"
                      paddingX={majorScale(1)}
                    >
                      <Badge
                        transform="rotateZ(-60deg)"
                        transformOrigin="bottom bottom"
                        isSolid
                      >
                        {allergen.name}
                      </Badge>
                    </Table.HeaderCell>
                  ))}
                  <Table.TextHeaderCell
                    width={60}
                    maxWidth={60}
                    textAlign="center"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    View
                  </Table.TextHeaderCell>
                </Table.Head>

                {/* Table Body */}
                <Table.Body>
                  {_.map(menuSection.recipes, (recipeId) => {
                    if (!recipeMap[recipeId]) {
                      return null;
                    }
                    const recipe = recipeMap[recipeId];
                    const allergenList = recipeIngredientAllergens(recipe);

                    return (
                      <Table.Row
                        key={`recipe-${recipeId}`}
                        display="flex" // Ensure the row itself uses flexbox
                      >
                        {/* Recipe Name */}
                        <Table.TextCell
                          flex="1"
                          minWidth="200px"
                          padding={majorScale(1)}
                          display="flex"
                          alignItems="center"
                        >
                          {recipe.name}
                        </Table.TextCell>

                        {/* Allergen Cells */}
                        {_.map(allergens, (allergen) => (
                          <Table.Cell
                            key={`recipe-${recipeId}-allergen-${allergen.id}`}
                            borderLeft="default"
                            width={40}
                            maxWidth={48}
                            padding={majorScale(1)}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            {_.includes(allergenList, allergen.name) && (
                              <IconWrapper
                                name="checkFat"
                                appearance="danger"
                                size={18}
                              />
                            )}
                          </Table.Cell>
                        ))}

                        {/* View Button */}
                        <Table.TextCell
                          width={60}
                          maxWidth={60}
                          textAlign="center"
                          borderLeft="default"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <IconWrapper
                            appearance="clickable"
                            name="eye"
                            onClick={() =>
                              navigate(
                                `/${accountId}/front-of-house/recipes/${recipeId}`
                              )
                            }
                          />
                        </Table.TextCell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </Block>
          ))}
      </Pane>
    </React.Fragment>
  );
};

export default AllergenView;
