//React
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

//Libraries
import { majorScale, Pane, Button, Avatar , useTheme} from 'evergreen-ui';

//Components
import ProfileMenu from './components/ProfileMenu';
import IconWrapper from 'components/Icons/Icons';
import CustomHeading from 'components/Headings/Headings';

//Files
import { userHasUnreadMessages } from '../../utils/functions';
import { checkIfGuestUser } from '../../utils/accounts';

const Header = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery({ maxWidth: 460 });
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const pageTitle = useSelector((state) => state.pageTitle);
  const profile = useSelector((state) => state.profile);
  const profileName = (profile) ? profile.name : '';
  const isGuest = checkIfGuestUser();

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const messagesAllowed = useSelector(state => state.accounts[state.currentAccount]?.type?.includes('LOCATION'));
  const hasMessages = useSelector(state =>  messagesAllowed ?  userHasUnreadMessages(state) : false);

  return (
    <Pane
      is="header"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      height={(isMobile) ? 40 : 60} 
      background={theme.colors.white}
      marginBottom={majorScale(1)}
    >
      <Pane flex="1 1 auto" paddingLeft={majorScale(2)}>
        <CustomHeading level="1">{pageTitle}
        </CustomHeading>
      </Pane>

      {!!profile && !isGuest && (
        <Pane display="flex" alignItems="center">
          <a
            href='https://www.misenplace.ai/support'
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconWrapper
              name="help"
              size={(isMobile) ? 14 : 18}
              color={theme.colors.grey2}
              marginX={majorScale(1)}
            />
          </a>

          {messagesAllowed && (
            <IconWrapper
                is={Link}
                to="/messages"
                name="chat"
                size={(isMobile) ? 14 : 18}
                color={hasMessages ? theme.colors.tertiary100 : theme.colors.grey2}
                marginX={majorScale(1)}
            />
          )}
        </Pane>
      )}

      {!!profile && (
          <Pane>
          <Button appearance="minimal" onClick={() => setSidebarOpen(true)} marginRight={majorScale(1)}>
            <Avatar size={(isMobile) ? 20 : 32} name={profileName} />
          </Button>
        </Pane>
      )}

      <ProfileMenu isShown={sidebarOpen} onClose={closeSidebar}  isGuest={isGuest}/>
    </Pane>
  );
};

export default Header;
