import { accountsRef } from './accounts';
import fb from './';
import { getCollectionRef } from './common';
import _ from 'lodash';
import { action } from '../actions'

export const addSupplierOption = async (supplierOption, accountId, userName) => {
  try {
    const supplierOptionsRef = getCollectionRef('supplierOptions', accountId);

    // Define the default values that are not required by default
    const defaultValues = {
      supplierAccount: null, // Parent account ID, which is provided when adding a parent option
      supplierIdAccount: null,
      parent: null, // Use supplied parent or set to null
      type: 'INTERNAL', // Default type
      categoryId: null,
      allergens: supplierOption.allergens || [],
    };

    // Merge the supplied supplierOption with default values
    const newSupplierOption = { ...defaultValues, ...supplierOption };

    const docRef = await supplierOptionsRef.add({
      ...newSupplierOption,
      createdBy: userName,
      createdAt: fb.serverTime(),
      updatedBy: null,
      updatedAt: null,
      archived: false,
      deleted: false
    });

    // Get the document ID and update the document to include the ID
    const docId = docRef.id;
    await docRef.update({ id: docId });

    // Merge the new ID into the supplier option
    const supplierOptionWithId = { ...newSupplierOption, id: docId };

    return supplierOptionWithId;
  } catch (error) {
    console.error("Error adding Supplier Option:", error);
    throw error;
  }
};

export const updateSupplierOption = (ingredientId, ingredient, accountId, userName) => {
  const ingredientsRef = getCollectionRef('supplierOptions', accountId);
  return ingredientsRef.doc(ingredientId).update({
    ...ingredient,
    updatedBy: userName,
    updatedAt: fb.serverTime(),
  });
};

export const archiveSupplierOption = (ingredientId, accountId, userName) => {
  const ingredientsRef = getCollectionRef('supplierOptions', accountId);
  return ingredientsRef.doc(ingredientId).update({
    ingredientId: null,
    archived: true,
    updatedBy: userName,
    updatedAt: fb.serverTime(),
  });
};

export const removeSupplierOption = (ingredientId, accountId, userName) => {
  const ingredientsRef = getCollectionRef('supplierOptions', accountId);
  return ingredientsRef.doc(ingredientId).update({
    ingredientId: null,
    deleted: true,
    updatedBy: userName,
    updatedAt: fb.serverTime(),
  });
};

//ShouldArchive to true if you need to archive the ingredient and remove the ingredientId, else false
export const removeSupplierOptionFromCollection = async (accountId, ingredientId, optionId, userName, shouldArchive = false) => {
  //console.log('removeSupplierOptionFromCollections called with:', { accountId, ingredientId, optionId });

  const ingredientRef = getCollectionRef('ingredients', accountId).doc(ingredientId);
  const supplierOptionRef = getCollectionRef('supplierOptions', accountId).doc(optionId);

  // Get the current supplier options from the ingredient
  const ingredientDoc = await ingredientRef.get();
  const ingredientData = ingredientDoc.data();
  
  if (ingredientData && ingredientData.supplierOptions) {
    const updatedSupplierOptions = ingredientData.supplierOptions.filter(opt => opt.id !== optionId);

    // Update the ingredient to remove the supplier option
    //console.log('Updating ingredient to remove supplier option:', updatedSupplierOptions);
    await ingredientRef.update({
      supplierOptions: updatedSupplierOptions
    });
  } else {
    console.log(`No supplierOptions field found in ingredient: ${ingredientId}`);
  }

  // Conditionally archive and unlink the supplier option if shouldArchive is true
  if (shouldArchive) {
    //console.log('Archiving and unlinking supplier option');
    await supplierOptionRef.update({
      ingredientId: null,
      archived: true,
      updatedBy: userName,
      updatedAt: fb.serverTime(),
    });
  } else {
    // Only update the timestamp and user if not archiving
    //console.log('Updating supplier option without archiving');
    await supplierOptionRef.update({
      updatedBy: userName,
      updatedAt: fb.serverTime(),
    });
  }
};

export const uploadSupplierOptions = (accountId, bucket, file) => {
  const uploadRef = accountsRef.doc(accountId).collection('uploads');
  const upload = {
    uploadtype: 'supplierOptions',
    uploadname: file.name,
    status: 'processing',
    created: fb.serverTime()
  };
  return uploadRef.add(upload).then(function (docRef) {
    const uploadId = docRef.id;
    const filename = bucket + '/uploads/supplierOptions/' + uploadId + '.csv';
    docRef.update({filename: filename});
    return fb.storage.ref().child(filename).put(file).then((snapshot) => {
      return 'Supplier Options file uploaded';
    }).catch(err => {
      console.log(err);
      return 'Error uploading file';
    })
  })
};

export const requestMEPSupplierOptions = async (supplier) => {
  if (!supplier.parent || !supplier.type?.includes('SLAVE')) return;

  const account = accountsRef.doc(supplier.parent);
  const supplierOptions = await account.collection('supplierOptions').get();
  const categories = await account.collection('supplierCategories').get();
  const categoryOptions = categories.docs?.map(c => ({...c.data(), id: c.id }));
  const options = supplierOptions.docs?.map(o => {
    const data = o.data();
    //console.log(data, 'REAUESTSUPPLIER')
    const categoryData = categoryOptions ? categoryOptions.find(c => c.id === data.categoryId): null;
    return {
      ...data,
      id: o.id,
      type: 'MASTER',
      category: categoryData,
      supplierAccount: supplier.parent,
      supplierAccountId: data.supplierId
    }
  }).filter(o => o.supplierId === supplier.supplierId && !o.un_verified);
  return {
    options: _.sortBy(options, 'name'),
    categoryOptions
  }
}

/**
 * Function to import supplier options for a given account.
 *
 * This function performs the following tasks:
 * 1. Finds existing supplier options by their parent ID.
 * 2. Creates new supplier options if they don't already exist.
 * 3. Removes supplier options that are marked for deletion.
 * 4. Finds or creates categories associated with the supplier options.
 * 5. Creates new ingredients associated with the supplier options.
 * 6. Uses Firestore batch operations to perform multiple writes in a single request.
 *
 * @param {string} accountId - The ID of the account.
 * @param {Array} options - An array of supplier options to be imported.
 */
export const importSupplierOptions = async (accountId, options, userName) => {
  const account = accountsRef.doc(accountId);

  const findOption = async (option) => {
    const {id} = option;
    const supplierOption = await account.collection('supplierOptions').where('parent', '==', id).get();
    if (supplierOption.docs?.length > 0) {
      const sO = supplierOption.docs[0];
      return {...sO.data(), id: sO.id }
    }
  }

  const createIngredient = async (option, newOptionRef) => {
    const ingredient = {
      categoryId: null,
      name: option.name,
      recipeunit: option.base_uom,
      supplierOptions: [
        {
          id: newOptionRef.id,
          name: option.name,
          defaultOption: true,
        },
      ],
      allergens: [],
      createdAt: fb.serverTime(),
      createdBy: userName,
      updatedAt: null,
      updatedBy: null,
      archived: false,
      deleted: false,
    }
    // Add the ingredient to Firestore and get its ID
    const ingNew = await account.collection('ingredients').add(ingredient);

    // Now, update the ingredient with its own ID as the itemcode
    await ingNew.update({
        itemcode: ingNew.id
    });

    //console.log('Created new ingredient with ID as itemcode:', ingNew.id);
    return ingNew.id;
  };

  const createOption = async (option) => {
    const {id, supplierId, supplierAccount, supplierAccountId, categoryId, category, ...rest} = option;
    
    const newOptionRef = account.collection('supplierOptions').doc();

    // Prepare the supplier option data
    const newSupplierOption = {
      ...rest,
      id: newOptionRef.id,
      parent: id,
      supplierId: supplierId,
      supplierIdAccount: supplierAccountId,
      supplierAccount: supplierAccount,
      type: 'SLAVE',
      categoryId: null, // Ensure categoryId is null
      createdAt: fb.serverTime(),
      createdBy: userName,
      updatedAt: null,
      updatedBy: null,
      deleted: false,
      archived: false,
    };
    // Add the new supplier option to the batch
    batch.set(newOptionRef, newSupplierOption);
    //console.log('New supplier option created:', newOptionRef.id);
  
    // Create a new ingredient with the first supplier option
    const ingredientId = await createIngredient(option, newOptionRef);
    //console.log('New ingredient created with id:', ingredientId);

    // Update the new supplier option with the ingredientId
    batch.update(newOptionRef, {
      ingredientId,
    });

    return newOptionRef;
  };

  const batch = account.firestore.batch();

  const findOptionPromises = options.map(option => findOption(option));
  const optionResults = await Promise.all(findOptionPromises);

  for (let i = 0; i < options.length; i++) {
    const option = options[i];
    const hasOption = optionResults[i];

    if (hasOption && option._delete) {
      const optionRef = account.collection('supplierOptions').doc(hasOption.id);
      batch.delete(optionRef);
    } else if (!hasOption) {
      await createOption(option, batch);
    }
  }

  await batch.commit();
  console.log('Batch operation committed successfully.');
};

export const getSupplierOptionMasterDetails = async (option) => {
  const { supplierAccount, parent } = option;
  const account = accountsRef.doc(supplierAccount);
  const supplierOption = await account.collection('supplierOptions').doc(parent).get();
  //console.log(supplierOption, 'SUPPLIEROPTION')
  //console.log(`Looking for master option with ID: ${parent} in supplier account: ${supplierAccount}`, supplierOption.data());
  if (supplierOption.exists) {
    //console.log(`Master details found for slave option ID: ${option.id}, Parent ID: ${parent}:`, supplierOption.data());
    return [supplierOption.data(), true];
  } else {
    //console.log(`No master details found for slave option ID: ${option.id}, Parent ID: ${parent}`);
    return [null, false];
  }
}

async function fetchSupplierParentId(accountId, supplierId) {
  try {
    const supplierDocRef = accountsRef.doc(accountId).collection('suppliers').doc(supplierId);
    const supplierDoc = await supplierDocRef.get();
    if (supplierDoc.exists) {
        const supplierAccount = supplierDoc.data().parent;
        //console.log(`Found supplier account: ${supplierAccount} for supplier ID: ${supplierId}`);
        return supplierAccount  // Assuming 'parent' is the field name
    }
  } catch (error) {
      //console.error('Error fetching supplier parent ID:', error);
      return null;
  }
}

export const processSupplierOptions = async (options, accountId) => {
  let parentIds = {};
  let duplicates = []; 

  const processedOptions = await Promise.all(options.map(async (option) => {
    if (option.type === 'SLAVE') {
      // Count each parent ID
      if (parentIds[option.parent]) {
        parentIds[option.parent]++;
      } else {
        parentIds[option.parent] = 1;
      }
      // Ensure every SLAVE option has a supplierAccount
      let shouldUpdate = false;
      if (!option.supplierAccount && option.supplierId) {
        const fetchedAccount = await fetchSupplierParentId(accountId, option.supplierId);
        if (fetchedAccount) {
          option.supplierAccount = fetchedAccount;
          shouldUpdate = true;
        } else {
          return { ...option, error: 'Missing supplier account', archived: true };
        }
      }
      
      const [masterDetails, found] = await getSupplierOptionMasterDetails(option);
      if (found) {
        const { name, uom, supplier_code, vat } = masterDetails;
        const updatedOption = { ...option, name, uom, supplier_code, vat, supplierAccount: option.supplierAccount };
        // Update Firestore document if supplierAccount was updated
        if (shouldUpdate) {
          const ingredientsRef = getCollectionRef('supplierOptions', accountId);
          await ingredientsRef.doc(option.id).update(updatedOption);
        }
        return updatedOption;
      } else {
        return { ...option, error: 'No matching master detail found', noMaster: true, archived: true };
      }
    }
    return option;
  }));
  // Identify duplicates
  for (const [key, value] of Object.entries(parentIds)) {
    if (value > 1) {
      duplicates.push(key);
    }
  }
  // Log duplicates
  if (duplicates.length > 0) {
    console.log('Duplicated Parent IDs:', duplicates);
  }
  return processedOptions;
};
