//React
import React from 'react';

//Libraries
import { majorScale, minorScale, Pane } from 'evergreen-ui';
import _ from 'lodash';

//Components
import Header from '../components/Header';
import ViewMore from '../components/ViewMore';
import DataTable from '../../../components/DataTable/DataTable';
import Block from '../../../components/ui/Block/Block'
import { CustomComposedChart } from '../../../components/Recharts/Recharts';

//Files
import { buildAggregatedSalesData } from '../../../utils/reports';


const LocationSales = ({accountsData, sales, limit = true }) => {
  //console.log(sales, 'SALESSS')
  const salesDept = sales.salesDeptData
  const locationSales = accountsData.locations.reduce((sales, location) => {
    const areas = accountsData.areas.filter(area => location.children.includes(area.id));

    const data = areas.reduce((data, area) => {
      // Ensure salesDept[area.id] exists before accessing it
      if (salesDept[area.id]) {
        data[area.id] = salesDept[area.id];
      } else {
        // If salesDept[area.id] is undefined, log a warning for debugging
        console.warn(`salesDept[${area.id}] is undefined!`);
      }
      return data;
    }, {});

    const locationSales = buildAggregatedSalesData(data);

    const processed = {
      name: location.name,
      gross: locationSales.gross,
      net: locationSales.net,
      id: location.id
    }
    return sales.concat(processed);
  }, []);

  let final = _.sortBy(locationSales, (s) => 1/s.net);

  // Helper function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dayName = days[date.getDay()];
    const dayNumber = date.getDate().toString().padStart(2, '0');
    return `${dayName} ${dayNumber}`;
  };

  // Prepare data for the chart
  const chartData = sales.dailySales ? 
  Object.entries(sales.dailySales).map(([date, data]) => ({
    date: formatDate(date),
    cost: data.daySalesCost || 0, // Convert to thousands for better scaling, default to 0 if null
    net: data.daySalesNet || 0, // Convert to thousands for better scaling, default to 0 if null
    gp: parseFloat(data.expectedDayGP) || 0 // Default to 0 if null or invalid
  })).sort((a, b) => new Date(a.date) - new Date(b.date))
  : [];

  const tableHeaders = [
    { label: 'Location', field: 'name', type: 'text', width: 5 },
    { label: 'Gross', field: 'gross', type: 'numeric', prefix: '£', width: 3 },
    { label: 'Net', field: 'net', type: 'numeric', prefix: '£', width: 3 }
  ]

  return (
    <Block width='100%' marginBottom={majorScale(2)}>
        <Header title='Location sales with daily breakdown' />
        <Pane padding={majorScale(4)}>
          <CustomComposedChart
            data={chartData}
            height={400}
            width="100%"
            layout="horizontal"
            xKey="date"
            barKeys={['net', 'cost']}
            lineKey="gp"
            xType="category"
            yType="number"
            valueTypes={{
              net: 'currency',
              cost: 'currency',
              gp: 'percentage'
            }}
            yDomain={[0, 'auto']}
            y2Domain={[0, 100]}
            yAxisLabel="Sales (£)"
            y2AxisLabel="GP (%)"
          />
        </Pane>
        <Pane maxHeight="480px" overflow="auto">
          <DataTable
              headers={tableHeaders}
              items={final}
              listHeight={320}
              marginBottom={majorScale(2)}
          />
          {limit && (
            <ViewMore to='locations' />
          )}
      </Pane>
    </Block>
  )
}

export default LocationSales;

