//React
import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { majorScale, minorScale, Pane, FormField, Textarea, useTheme } from 'evergreen-ui';

//Components
import Page from '../../components/Page/Page';
import DataTable from '../../components/DataTable/DataTable';
import FilterBar from '../../components/FilterBar/FilterBar';
import DatePickerInput from '../../components/DatePickerInput/DatePickerInput';
import Block from '../../components/ui/Block/Block';
import Button from '../../components/ui/Button/Button';
import CustomHeading from '../../components/Headings/Headings';
import IconWrapper from '../../components/Icons/Icons';

//Files
import { actions } from '../../store/actions';
import { generateSearchFieldsFn, isValidNumeric, supplierOptionUOM } from '../../utils/functions';
import { filterActiveSupplierOptions } from '../../utils/ingredients';
import { orderStatuses } from '../../utils/constants';
import { dateFormat } from '../../utils/format';
import { current } from '../../utils/selectors';
import useConditionalRedirect from '../../hooks/useConditionalRedirect';

// Dynamically calculate tomorrow
const getTomorrow = () => moment.utc().add(1, 'days').startOf('day').toDate();


const OrderingEdit = ({ draftDate, isPO }) => {
  const { accountId, supplierId, type } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { requiresWorkingAccount } = useConditionalRedirect();
  const isMobile = useMediaQuery({ maxWidth: 460 });
  const nextOrderNumber = useSelector((state) => {
    return _.reduce(
      current(state, 'orders', accountId),
      (result, order) => Math.max(result, parseInt(order.orderNumber) || 0), 0
    ) + 1;
  });
  const supplier = useSelector((state) => _.find(current(state, 'suppliers', accountId), { 'id': supplierId }));
  const categoryMap = useSelector((state) => _.keyBy(current(state, 'ingredientCategories', accountId), 'id'));
  const quickOrder = useSelector((state) => state.quickOrder);
  const defaultItems = (quickOrder) ? [{ id: quickOrder.optionId, quantity: quickOrder.quantity, ingredientId: quickOrder.ingredientId }] : [];
  const [isSaving, setIsSaving] = useState(false);
  const [filters, setFilters] = useState({});
  const tomorrow = getTomorrow();
  const initialDate = draftDate ? moment.utc(draftDate, 'YYYY-MM-DD').toDate() : tomorrow;
  const [orderDate, setOrderDate] = useState(initialDate);
  const [orderInfo, setOrderInfo] = useState({
      date: orderDate,
      supplierId,
      items: defaultItems,
      orderNumber: type && type === 'credit' ? null : nextOrderNumber
  });
  //console.log(orderInfo, "orderInfoooo")

  let existingDraft = useSelector((state) => {
    return _.find(current(state, 'orders', accountId), (order) => {
      return (order.supplierId === supplierId && order.date === dateFormat(orderDate) && order.status === orderStatuses.DRAFT);
    });
  });
  if (isPO) existingDraft = null;

  const supplierOptions = useSelector((state) => {
    if (!supplier) {
      return [];
    }
  
    // Step 1: Filter active supplier options for the specific supplier
    const allSupplierOptions = current(state, 'supplierOptions', accountId);
    const filteredOptions = filterActiveSupplierOptions(
      _.filter(allSupplierOptions, { 'supplierId': supplierId })
    );
  
    // Step 2: Combine option info with the order items and sort by name
    let orderItems = _.keyBy(orderInfo.items || [], 'id');
    let optionList = _.sortBy(
      _.map(filteredOptions, (opt) => {
        if (orderItems[opt.id]) {
          return {
            ...opt,
            ...orderItems[opt.id]
          };
        }
        return { ...opt };
      }),
      'name'
    );
  
    return optionList;
  });  
  //console.log(supplierOptions, 'SUPPLIEROPTIONSSS')

  const ingredientMap = useSelector((state) => {
    const ingIdList = _.uniq(
      _.map(
        _.filter(
          current(state, 'supplierOptions', accountId),
          { 'supplierId': supplierId }
        ),
        (opt) => (opt.ingredientId)
      )
    );
    return _.keyBy(
      _.filter(
        current(state, 'ingredients', accountId),
        (ing) => _.includes(ingIdList, ing.id)
      ),
      'id'
    );
  });

  requiresWorkingAccount();

  useEffect(() => {
    // If existingDraft changes (orderDate or supplier has changed), combine the quantities for 
    //  both the current value and the existing draft
    if (existingDraft && !isSaving) {
      const draftItems = _.keyBy(existingDraft.items, 'id');
      setOrderInfo((prevInfo) => {
        const orderItems = _.keyBy(prevInfo.items, 'id');
        let combinedItems = [];
        for (let i in orderItems) {
          if (draftItems[i]) {
            combinedItems.push({ ...orderItems[i], quantity: parseFloat(orderItems[i].quantity) + parseFloat(draftItems[i].quantity) });
          }
          else {
            combinedItems.push(orderItems[i]);
          }
        }
        for (let i in draftItems) {
          if (!orderItems[i]) {
            combinedItems.push(draftItems[i]);
          }
        }
        let { specialInstructions } = prevInfo;
        if (specialInstructions !== existingDraft.specialInstructions) {
          specialInstructions = _.trim(_.join([existingDraft.specialInstructions, specialInstructions], '\n'));
        }

        return {
          ...prevInfo,
          ...(specialInstructions && { specialInstructions }),
          orderNumber: existingDraft.orderNumber || prevInfo.orderNumber,
          items: combinedItems
        };
      });
    }
  }, [existingDraft, isSaving]);

  useEffect(() => {
    // Once we've loaded the edit form, we can clear the quickOrder if there is any
    if (quickOrder) {
      dispatch(actions.orders.quickOrderClear());
    }
  }, [quickOrder, dispatch]);

  const tableheaders = [
    { label: 'Name', field: 'name', type: 'text', width: 5 },
    { label: 'Category', field: 'ingredientId', type: 'text', format: (ingId) => {
      if (ingId && ingredientMap[ingId]) {
        const catId = ingredientMap[ingId].categoryId;
        return ((categoryMap[catId]) ? categoryMap[catId].name : '');
      }
      return '';
    }, width: 3 },
    { label: 'UOM', field: 'uom', type: 'calc', calc: supplierOptionUOM, width: 2 },
    { label: 'Est. price', field: 'unitprice', type: 'numeric', format: 'currency', width: 2 },
    { label: 'Qty to ord.', field: 'quantity', type: 'input', onChange: (item, e) => onQuantityChange(item, e.target.value), width: 2 },
  ];
  const mobileHeaders = [
    { label: 'Name', field: 'name', width: 12, },
    { label: 'UOM', field: 'uom', type: 'calc', calc: supplierOptionUOM, width: 4, row: 2 },
    { label: 'Est. £', field: 'unitprice', type: 'numeric', format: 'currency',  width: 4, row: 2 },
    { label: 'Qty to o.', field: 'quantity', type: 'input', onChange: (item, e) => onQuantityChange(item, e.target.value), width: 4, row: 2 }
  ]
  const updateFilters = (filter, newValue) => {
    setFilters((prevFilters) => {
      if (!newValue) {
        const { [filter]: discard, ...newFilters } = prevFilters;
        return newFilters;
      }
      return { ...prevFilters, [filter]: newValue };
    });
  };

  const searchOnChange = (newSearchValue) => {
    if (!newSearchValue) {
      updateFilters('search', '');
    }
    else {
      updateFilters('search', generateSearchFieldsFn(['name'], newSearchValue));
    }
  };

  const onFieldChange = (fieldName, newValue) => {
    setOrderInfo({ ...orderInfo, [fieldName]: newValue });
  };

  const saveDraft = () => {
    setIsSaving(true);
    const order = {
      ...orderInfo,
      date: dateFormat(orderDate),
      status: orderStatuses.DRAFT
    }

    if (type && type === 'credit') {
      order.type = 'CREDIT';
    }

    // Adjust status based on type if it's an invoice or credit to directly go to Issue PO
    if (type === 'invoice' || type === 'credit') {
      order.status = orderStatuses.SENT;  // Assuming SENT represents Issue PO
    }

    if (isPO) {
      order.status = orderStatuses.SENT;
    }

    const callback = () => navigate(`/${accountId}/procurement/${(isPO || type === 'invoice' || type === 'credit') ? 'received' : 'open'}-orders`);
    if (existingDraft) {
      const updateOrder = { ...existingDraft, ...order } 
      // Update the current draft by combining existingDraft and the new orderInfo
      dispatch(actions.orders.updateOrder(accountId, updateOrder, callback))
    }
    else {
      // Create a new draft
      dispatch(actions.orders.addOrder(accountId, order, callback));
    }
  };

  const onQuantityChange = (option, qty) => {
    if (!isValidNumeric(qty)) {
      return;
    }
    setOrderInfo((prevInfo) => {
      const optIndex = _.findIndex(prevInfo.items, { 'id': option.id });
      let newOptList = [...(prevInfo.items || [])];
      if (optIndex >= 0) {
        if (!qty) {
          // Remove the item from the list
          newOptList = _.concat(newOptList.slice(0, optIndex), newOptList.slice(optIndex + 1));
        }
        else {
          newOptList[optIndex] = { id: option.id, quantity: qty, ingredientId: option.ingredientId };
        }
      }
      else {
        newOptList.push({ id: option.id, quantity: qty, ingredientId: option.ingredientId });
      }

      return { ...prevInfo, items: newOptList };
    });
  };

  return (
    <Page title={(supplier) ? `${supplier.name} - New Order` : 'New Order'} flex="1 0 auto">
      <Block
          flex="0 0 auto"
          background={theme.colors.offwhite}
          display="flex"
          alignItems="center"
          marginBottom={majorScale(2)}
          paddingX={majorScale(2)}
          paddingY={majorScale(1)}
          height={48}
      >
        <IconWrapper
            appearance="clickable"
            name="arrowLeft"
            is={Link}
            to={`/${accountId}/procurement`}
            marginRight={majorScale(2)}
        />
        <CustomHeading level="3" flex="1 0 auto">Draft PO</CustomHeading>
      </Block>

      <Block flex="1 0 auto" flexDirection="column" display="flex" marginBottom={majorScale(2)}>
        <Pane
            display="flex"
            flexDirection={(isMobile) ? 'column-reverse' : 'row'}
            alignItems={(isMobile) ? 'flex-end' : 'flex-start'}
            justifyContent="flex-start"
            marginBottom={majorScale(2)}
            padding={majorScale(2)}
        >
          <Pane
              flex="1 0 auto"
              alignSelf={(isMobile) ? 'stretch' : 'inherit'}
              marginRight={(isMobile) ? 0 : minorScale(4)}
          >
            <DatePickerInput
                required
                label="Delivery Date"
                placeholder="Please choose a date"
                value={orderDate}
                onChange={(newDate) => setOrderDate(newDate)}
                min={tomorrow}
                marginBottom={minorScale(4)}
                inputProps={{ width: (isMobile) ? '100%' : 'auto' }}
            />
            <FormField
                label="Special Instructions"
            >
              <Textarea
                  value={orderInfo.specialInstructions || ''}
                  onChange={(e) => onFieldChange('specialInstructions', e.target.value)}
              />
            </FormField>
          </Pane>
          <Button
              appearance="primary"
              disabled={isSaving}
              onClick={() => saveDraft()}
              marginBottom={majorScale(2)}
          >Save as draft</Button>
        </Pane>

        <Pane marginBottom={majorScale(2)} paddingX={majorScale(2)}>
          <FilterBar
              filters={filters}
              searchPlaceholder="Search Ingredients"
              searchOnChange={searchOnChange}
          />
        </Pane>
        <DataTable
            items={supplierOptions}
            headers={(isMobile) ? mobileHeaders : tableheaders}
            filters={filters}
            //tableProps={{ flex: `1 0 ${TABLE_MIN_HEIGHT}px` }}
            listHeight={400}
        />
      </Block>
    </Page>
  );
};

OrderingEdit.propTypes = {
  draftDate: PropTypes.string
};

OrderingEdit.defaultProps = {
  draftDate: dateFormat(getTomorrow())
};

export default OrderingEdit;
