//React
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale } from 'evergreen-ui';

//Components
import PeriodNavigation from './components/PerdiodNavigation';
import ReportFilters from './components/ReportFilters';
import LocationOrders from './CostsComponents/LocationOrders';
import SuppliersTab from './CostsComponents/SuppliersTab';
import IngredientsTabs from './CostsComponents/IngredientsTabs';
import Tab from '../../components/ui/Tab/Tab';
import TabNavigation from '../../components/ui/TabNavigation/TabNavigation';

//Files
import { buildWeekDates } from '../..//utils/reports';

const tabs = {
  overview: 'Overview',
  orders: 'Orders',
  suppliers: "Suppliers",
  ingredients: 'Ingredients',
};

const CostsTabs = ({  accountId, accounts, weekProps, filterProps }) => {
  const location = useLocation();
  const currentTab = location.pathname.split('/')[3] || 'overview';
  
  const accountsData = accounts
  const costs = useSelector(state => state.reports.costsData);

  const weekDatesProps = useMemo(() => {
    return weekProps.weekInfo;
  }, [weekProps.weekInfo]);
  const weekdates = buildWeekDates(weekDatesProps.start);

  const costsData = useMemo(() => ({ accountId, accountsData, costs, currentTab, weekdates, filterProps }),
   [accountId, accountsData, costs, weekdates, filterProps]);
  //console.log(costsData, 'CostTAbs')

  const newAccounts = {...accountsData, filterProps }

  const hideFields = {categories: true, departments: true}
  if (currentTab === 'overview') {
    hideFields.ingredientCategories = true;
    hideFields.suppliers = true;
  }
  if (currentTab === 'orders') {
    hideFields.ingredientCategories = true;
  }

  const tabComponents = {
    overview: <LocationOrders {...costsData} currentTab={currentTab}/>,
    orders: <LocationOrders {...costsData} currentTab={currentTab}/>,
    suppliers: <SuppliersTab {...costsData} currentTab={currentTab}/>,
    ingredients: <IngredientsTabs {...costsData} currentTab={currentTab}/>,
  };

  return (
    <>
      <TabNavigation paddingBottom={majorScale(2)}>
        {Object.entries(tabs).map(([tabKey, tabName]) => (
          <Tab
            key={tabKey}
            is={Link}
            to={`/analytics/costs/${tabKey}`}
            isSelected={currentTab === tabKey}
          >
            {tabName}
          </Tab>
        ))}
      </TabNavigation>
      <PeriodNavigation 
        weekInfo={weekProps.weekInfo}
        seeNextWeek={weekProps.seeNextWeek}
        seePrevWeek={weekProps.seePrevWeek}
      />
      <ReportFilters accounts={newAccounts} hideFields={hideFields} />
      {tabComponents[currentTab]}
    </>
  );
};

CostsTabs.propTypes = {
  accounts: PropTypes.object.isRequired,
  weekProps: PropTypes.object.isRequired
};

export default CostsTabs;
