//Libraries
import _ from 'lodash';
import moment from 'moment';

//Files
import store from 'store/redux';
import { orderStatuses } from './constants';
import {
  buildRecipeCategory,
  calcOrdersTotal,
  calcStocktakeCost,
  calcStocktakeIngredientCost,
  calcTransfersLiveBalance,
  castFloat,
  denormalizeIngredientQuantity,
  findSubRecipeAmount,
  getIngredientStockUOM,
  getOrdersForPeriod,
  getSalesForPeriod,
  getStocktakeDate,
  getStocktakeMoment,
  getTransfersForPeriod,
  getWastageDate,
  ingOrderAmount,
  ingredientUnitAmount,
  ingSaleAmount,
  ingWastageAmount,
  periodTransferCosts,
  recipeIngredientsUseAmount,
  ingRecipePrice,
  supplierOptionUsed,
} from './functions';
import { dateStoreFormat, formatDate } from './format'
import { current, selectPeriodReportIncidentals, selectReportIncidentals } from './selectors';
import { actions } from '../store/actions'

const dateFormat = "YYYY-MM-DD";

const buildPeriodData = (accountId, initialStocktake, previousStocktake = null) => {
  const periodStart = previousStocktake ?
    getStocktakeMoment(previousStocktake).startOf('day') :
    getStocktakeMoment(initialStocktake).startOf('day'); 
    //console.log(periodStart.format(), "PERIODSTARTTTT")

  const state = store.getState();

  const periodEnd = previousStocktake ? 
    getStocktakeMoment(initialStocktake).subtract(1, 'days').endOf('day') : 
    moment().utc().endOf('day');
    //console.log(periodEnd.format(), "PERIODENDDD")

  const periodOrders = getOrdersForPeriod(accountId, periodStart, periodEnd, orderStatuses.RECEIVED);
  //console.log(periodOrders, 'PERIODORDERS')
  const periodSales = getSalesForPeriod(accountId, periodStart, periodEnd);
  //console.log(periodSales, "PERIODSALES")

  // refactor
  const periodWastage = _.filter(current(state, 'wastages', accountId), (wastage) => {
      const wastageDate = getWastageDate(wastage);
      if (!wastageDate) {
        // Ignore any wastage records without a date
        return false;
      }
      return (moment.utc(wastageDate).isBetween(periodStart, periodEnd, 'day', '[]'));
    });

  const recipeCategories = current(state, 'recipeCategories', accountId);
  const recipeList = _.sortBy(current(state, 'recipes', accountId), 'name').map(r => buildRecipeCategory(r, recipeCategories));

  const periodTransfers = getTransfersForPeriod(accountId, periodStart, periodEnd);
  return {
    periodOrders,
    periodSales,
    periodWastage,
    periodTransfers,
    recipeList,
  }
}

// Function to build a comprehensive list of ingredients for a stock period, 
// including both direct ingredients and ingredients used within recipes.
const buildPeriodStockIngredients = (stock) => {
  if (!stock) {
    console.log('Stock is empty or undefined');
    return [];
  }

  // Initialize the ingredients list with direct ingredients from stock.
  let ingredients = stock.ingredients || [];
  //console.log(ingredients.length, 'Initial Ingredients Count:', ingredients);

  // Check if there are any recipes in stock to process
  if (stock.recipes && !_.isEmpty(stock.recipes)) {
    //console.log(stock.recipes.length, 'Recipes Count in Stock:', stock.recipes);

    // Loop through each recipe and extract ingredients
    const recipeIngs = stock.recipes.reduce((array, recipe) => {
      // Ensure the recipe and its ingredients exist, and that the helper function is defined
      if (!recipe || !recipe.ingredients || typeof recipeIngredientsUseAmount !== 'function') {
        //console.warn('Invalid recipe or missing ingredients:', recipe);
        return array;
      }
     
      // Get the list of ingredients used in the recipe, adjusted by the recipe's normal quantity
      const recipeIngredients = recipeIngredientsUseAmount(recipe, recipe.normalQty) || [];
      const totalWorth = _.sumBy(recipeIngredients, 'ingredient.worth'); // Sum of ingredient worth values
      //console.log(recipe.name, recipe, recipe.normalQty, recipeIngredients)
      // Check for discrepancy between recipe.worth and sum of ingredients
      /*if (recipe.worth !== totalWorth) {
        logRecipeDiscrepancy(recipe, recipeIngredients, totalWorth); // Call helper function
      }*/

      // Flatten the nested ingredient arrays and add to the accumulated array
      return array.concat(_.flatten(recipeIngredients));
    }, []);

    //console.log(recipeIngs.length, 'Total Ingredients Extracted from Recipes:', recipeIngs);

    // Combine the direct ingredients and the extracted recipe ingredients
    ingredients = ingredients.concat(recipeIngs);
  }

  const totalVarianceAcrossRecipes = stock.recipes.reduce((totalVariance, recipe) => {
    const recipeIngredients = recipeIngredientsUseAmount(recipe, recipe.normalQty) || [];
    const recipeVariance = _.sumBy(recipeIngredients, (ingredient) => {
      const actualWorth = ingredient.ingredient?.worth || 0;
      const expectedWorth = ingredient.ingredient?.expectedWorth || 0; // Replace with the correct field
      return actualWorth - expectedWorth;
    });
    return totalVariance + recipeVariance;
  }, 0);
  
  //console.log('Total Variance Across All Recipes:', totalVarianceAcrossRecipes);

  //console.log(ingredients.length, 'Final Ingredients Count After Merging Recipes:', ingredients);
  return ingredients;
};


/*const buildAllIngredients = (stock, ingredients) => {
  // Step 1: Use `stock.ingredients` as the default list
  const baseIngredients = stock.ingredients || [];
  console.log(baseIngredients.length, 'Base Ingredients Count');

  // Step 2: Enhance the list with additional ingredients from the state
  // Filter `ingredients` to include only those not already in `stock.ingredients`
  const additionalIngredients = ingredients.filter(ing =>
    !baseIngredients.some(stockIng => stockIng.id === ing.id)
  );
  console.log(additionalIngredients.length, 'Additional Ingredients Count');

  // Step 3: Combine base ingredients with additional ingredients
  const allIngredients = baseIngredients.concat(additionalIngredients);

  // Step 4: Deduplicate the list by `id` (if necessary)
  const uniqueIngredients = _.uniqBy(allIngredients, 'id');
  console.log(uniqueIngredients.length, 'Final Unique Ingredients Count');

  return allIngredients;
};*/

const buildAllIngredients = (stock, ingredients) => {
  //console.log(stock.ingredients.length, stock.recipes.length, ingredients.length, 'SUMM INGREDIENT');

  // Step 1: Build the initial `sIngredients` list
  const sIngredients = buildPeriodStockIngredients(stock);
  //console.log(sIngredients.length, sIngredients, 'sIngredients Before Filter');

  // Step 2: Filter `sIngredients` to include only those that exist in `ingredients`
  const filteredSIngredients = sIngredients.filter(sI => {
    const exists = ingredients.findIndex(i => i.id === sI.id) > -1;
    if (!exists) {
      console.log(sI, 'Filtered Out Ingredient'); // Log ingredients being filtered out
    }
    return exists;
  });
  //console.log(filteredSIngredients.length, 'sIngredients After Filter');

  // Step 3: Combine `ingredients` with the filtered `sIngredients`
  const allIngredients = ingredients.concat(filteredSIngredients);
  //console.log(allIngredients.length, 'Combined Ingredients Count');

  return allIngredients;
};

const getDefaultIngredient = (ingredient = {}) => {
  // Retrieve supplier option values, or fallback to defaults
  const recipeprice = ingRecipePrice(ingredient) || 0; // Corrected calculation
  const supOptionPrice = supplierOptionUsed(ingredient, 'unitprice') || 0;
  const supOptionNum = supplierOptionUsed(ingredient, 'numperportion') || 1;
  const supOptionGram = supplierOptionUsed(ingredient, 'gramperportion') || 1;

  return {
    id: ingredient.id || '', // Ensure every ingredient has an ID
    name: ingredient.name || '', // Ensure every ingredient has a name
    categoryId: ingredient.categoryId || null,
    recipeunit: ingredient.recipeunit ?? null,
    supplierOptions: ingredient.supplierOptions ?? [],
    supOptionPrice: ingredient.supOptionPrice ?? supOptionPrice,
    supOptionNum: ingredient.supOptionNum ?? supOptionNum,
    supOptionGram: ingredient.supOptionGram ?? supOptionGram,
    recipeprice: ingredient.recipeprice ?? recipeprice,
    //recordUOM: ingredient.recordUOM ?? ingredient.recipeunit ?? null,
    //amount: ingredient.amount ?? 0, // Default value for new ingredients
    //normalQty: ingredient.normalQty ?? 0,
    //worth: ingredient.worth ?? 0,
  };
};

const buildIngredients = (initialStocktake = {}, previousStocktake = {}, globalIngredients = []) => {
  // Step 1: Start with ingredients from the initial stocktake
  const stocktakeIngredients = initialStocktake.ingredients || [];
  //console.log(stocktakeIngredients.length, 'Step1');

  // Step 2: Check previous stocktake for any missing ingredients
  const previousIngredients = (previousStocktake?.ingredients || []).filter(prevIng =>
    !stocktakeIngredients.some(stockIng => stockIng.id === prevIng.id)
  );
  //console.log(previousIngredients.length, 'Step2');

  // Step 3: Check global ingredients for any remaining missing ingredients
  const globalMissingIngredients = globalIngredients.filter(globalIng =>
    !stocktakeIngredients.some(stockIng => stockIng.id === globalIng.id) &&
    !previousIngredients.some(prevIng => prevIng.id === globalIng.id)
  );
  //console.log(globalMissingIngredients.length, 'Step3');

  // Step 4: Merge ingredients from all sources
  const allIngredients = [
    ...stocktakeIngredients,
    ...previousIngredients,
    ...globalMissingIngredients.map(getDefaultIngredient), // Apply default schema only for global ingredients
  ];

  // Step 5: Deduplicate by `id` and filter out invalid ingredients
  const finalIngredientList = _.uniqBy(allIngredients, 'id');

  //console.log(allIngredients, allIngredients.length, finalIngredientList.length, 'Final Aggregated Ingredient List');
  return finalIngredientList;
};

const buildPeriodSales = ({periodSales, recipeList}) => {
  const livestockPeriodSales = _.flatten(periodSales.map((sale) => {
    const recipe = _.find(recipeList, { id: sale.recipeId });
    const recipeIngredients = recipe && recipe.ingredients
        ? recipeIngredientsUseAmount(recipe, sale.totalqty).map((ingredient) => ({
            ...ingredient,
            recipeId: sale.recipeId,
            recipeName: recipe.name,
            saleQuantity: sale.totalqty, // Quantity of the dish sold
            type: 'recipe', // Mark it as part of a recipe
          }))
        : [];
      return recipeIngredients;
    })
  ).sort((a, b) => a.id > b.id);
  //console.log('Livestock Period Sales:', livestockPeriodSales);
  return _.compact(livestockPeriodSales);
}

const wastageIng = (w, ing) => ({ ...ing, amount: w.amount, recordUOM: w.recordUOM })

const buildPeriodWastage = ({ periodWastage }) => {
  const livestockPeriodWastage = _.flatten(
    periodWastage.map((wastage) => {
      const recipeIngredients = wastage.recipe
        ? recipeIngredientsUseAmount(wastage.recipe, wastage.normalQty).map((recipeIngredient) => ({
            ...recipeIngredient,
            recipeId: wastage.recipe.id, // Add recipeId
            recipeName: wastage.recipe.name, // Add recipeName
            type: 'recipe', // Specify as recipe
            cost: wastage.cost || 0, // Include cost
            reason: wastage.reason || 'Unknown', // Include reason
            date: wastage.date ? wastage.date : 'Unknown', // Include date
            recordUOM2: wastage.recordUOM || 'Unknown', // Include recordUOM
          }))
        : [];

      const ingredients = wastage.ingredients
        ? wastage.ingredients.map((ing) => ({
            ...wastageIng(wastage, ing),
            type: 'ingredient', // Specify as ingredient
            cost: wastage.cost || 0, // Include cost
            reason: wastage.reason || 'Unknown', // Include reason
            date: wastage.date ? wastage.date : 'Unknown', // Include date
            recordUOM2: wastage.recordUOM || 'Unknown', // Include recordUOM
          }))
        : [];

      const ingredient = wastage.ingredient
        ? [
            {
              ...wastageIng(wastage, wastage.ingredient),
              type: 'ingredient', // Specify as ingredient
              cost: wastage.cost || 0, // Include cost
              reason: wastage.reason || 'Unknown', // Include reason
              date: wastage.date ? wastage.date : 'Unknown', // Include date
              recordUOM2: wastage.recordUOM || 'Unknown', // Include recordUOM
            },
          ]
        : [];

      return [...recipeIngredients, ...ingredients, ...ingredient];
    })
  ).sort((a, b) => a.id > b.id);

  return _.compact(livestockPeriodWastage);
};

const addTransferTypeAndDate = (ings, transferType, date) => {
  return ings.map(i => ({ ...i, transferType, date }));
};

const transferIng = (t, ing) => ({ ...ing, amount: t.normalQty, recordUOM: ing.recipeunit })

const buildPeriodTransfers = (periodTransfers) => {
  const livestockPeriodTransfers = _.flatten(periodTransfers.map((transfer) => {
    const transferType = transfer.transferType; 
    const date = transfer.date; // Access date directly
    
    // Process recipe ingredients if present
    const recipeIngredients = transfer.recipe
      ? recipeIngredientsUseAmount(transfer.recipe, transfer.normalQty).map(ingredient => ({
          ...ingredient,
        }))
      : [];

    // Process individual ingredients if present
    const ingredient = transfer.ingredient
      ? [{
          ...transferIng(transfer, transfer.ingredient),
        }]
      : [];
    // Combine processed recipes and ingredients, adding transfer type
    return [
      ...addTransferTypeAndDate(recipeIngredients, transferType, date),
      ...addTransferTypeAndDate(ingredient, transferType, date),
    ];
  }));
  return _.compact(livestockPeriodTransfers);
}

const ingredientStock = (ingredient, ingredients, startRecordUOM) => {
  const ingredientsStartStock = ingredients.filter(ing => ing.id === ingredient.id);
  //console.log(`Ingredient: ${ingredient.name || 'Unknown'} (${ingredient.id})`);
  //console.log('Matching Start Stock Ingredients:', ingredientsStartStock);
  const unitStockAmount = ingredientsStartStock.reduce((amount, ing) => {
    return amount + ingredientUnitAmount(ing)
  }, 0);
  const denormIng = ingredient.ingredient ? ingredient.ingredient : ingredient;
  const unitStockDisplay = !_.isEmpty(ingredientsStartStock) ? denormalizeIngredientQuantity({ ...denormIng, amount: unitStockAmount, recordUOM: startRecordUOM }).toFixed(2) : 0;
  //console.log(`Total Stock Amount for ${ingredient.name || 'Unknown'}:`, unitStockAmount);
  // Calculate unitStockValue (worth) by summing up the worth for all matching ingredients
  const unitStockValue = ingredientsStartStock.reduce((total, ing) => {
    // Check for worth directly on ing, otherwise try ing.ingredient.worth
    const worth = ing.worth || (ing.ingredient && ing.ingredient.worth) || 0;
    return total + worth;
  }, 0);
  //console.log(`Total Worth for ${ingredient.name || 'Unknown'}:`, unitStockValue);

  // Build the detailed view for each line item
  const detailedLines = ingredientsStartStock.map((line) => {
    // Check if the line is part of a recipe or an ingredient
    const isRecipe = !!line.ingredient; // If `ingredient` key exists, it's a recipe

    return {
      id: line.id,
      name: isRecipe ? line.ingredient.recipeName || 'Unknown Recipe' : line.name || 'Unknown Ingredient',
      amount: isRecipe
      ? (line.ingredient?.normalQty || line?.amount || 0) / (line?.ingredient.recipeunit === 'g' || line?.ingredient.recipeunit === 'ml' ? 1000 : 1)
      : (line?.normalQty || 0) / (line?.recipeunit === 'g' || line?.recipeunit === 'ml' ? 1000 : 1),
      worth: isRecipe
        ? line.ingredient.worth || 0 // Use the `worth` from the recipe ingredient
        : line.worth || 0, // Use `worth` for regular ingredients
      uom: startRecordUOM, // Use the recordUOM or default to startRecordUOM
      type: isRecipe ? 'recipe' : 'ingredient', // Include the type for clarity
    };
  });

  // Return aggregated data along with optional detailed lines
  return {
    unitStockAmount,
    unitStockDisplay: unitStockDisplay || '0.00', // Opening Stock Amount
    unitStockValue: unitStockValue, //|| '0.00', // Opening Stock Value
    detailedLines, // Line-level details (optional)
  };
}

const buildLivestockIngredientStock = (ingredient, periodStartStock) => {
  //console.log(periodStartStock, ingredient, 'BUILDDLLLEDATA')
  const startRecordUOM = getIngredientStockUOM(ingredient);
  const data = ingredientStock(ingredient, periodStartStock, startRecordUOM);
  //console.log(data, 'DATTTTAAA')
  return {...ingredient, ...data, startRecordUOM};
}

const buildLivestockIngredientOrders = (ingredient, livestockPeriodOrders) => {
  //console.log(ingredient)
  // Filter orders related to the ingredient
  const ingredientOrders = livestockPeriodOrders.filter(order => order.ingredientId === ingredient.id);

  // Initialize `amount` to calculate total quantity
  let amount = 0;

  // Calculate the total order amount based on type (CREDIT/DEBIT)
  if (!_.isEmpty(ingredientOrders)) {
    amount = ingredientOrders.reduce((num, item) => 
      item.type && item.type === 'CREDIT'
        ? num - (ingOrderAmount(item)) 
        : num + (ingOrderAmount(item)), 
      0
    );
  }

  // Calculate normalized display quantities
  const orderQuantity = parseFloat(amount);
  const denormIng = ingredient.ingredient ? ingredient.ingredient : ingredient;
  const orderQuantityDisplay = denormalizeIngredientQuantity({ 
    ...denormIng, 
    amount, 
    recordUOM: ingredient.startRecordUOM 
  }).toFixed(2);

  // Build `detailedOrders` array
  const detailedOrders = ingredientOrders.map(order => ({
    quantityReceived: order.numperportion * order.gramperportion * order.quantityreceived / (order.base_uom === 'g' || order.base_uom === 'ml' ? 1000 : 1),
    displayUOM: order.base_uom === 'g' ? 'kg' : order.base_uom === 'ml' ? 'l' : order.base_uom || 'unit',
    totalValue: order.finalprice * order.quantityreceived,
    supplierId: order.supplierId || 'Unknown',
    deliveryDate: formatDate(order.deliveryDate) || 'Unknown',
    type: !order.type ? 'DEBIT' : order.type,
    supplierUOM: `${order.numperportion}x${order.gramperportion}${order.base_uom}`,
    quantityOrdered: order.quantityreceived,
    finalPrice: order.finalprice || 0,
  }));

  // Return the updated ingredient object with `detailedOrders`
  return {
    ...ingredient,
    orderQuantity,
    orderQuantityDisplay,
    detailedOrders, // Add the new detailed orders array
  };
};

const buildLivestockIngredientSales = (ingredient, livestockPeriodSales) => {
  let amount = 0;
  const ingredientSales = livestockPeriodSales.filter(sale => sale.id === ingredient.id);
  //console.log(ingredientSales, 'INGREDISALESS')
  if (!_.isEmpty(ingredientSales)) {
    amount = ingredientSales.reduce((num, item) => num + ingSaleAmount(item), 0);
  }
  const saleQuantity = parseFloat(amount);
  const denormIng = ingredient.ingredient ? ingredient.ingredient : ingredient;
  const saleQuantityDisplay = denormalizeIngredientQuantity({ ...denormIng, amount, recordUOM: ingredient.startRecordUOM }).toFixed(2);

  // Construct detailed sales array
  const detailedSales = ingredientSales.map((sale) => ({
    id: sale.id,
    recipeName: sale.recipeName || 'Unknown Recipe',
    amount: sale.amount / (sale.ingredient.recipeunit === 'g' || sale.ingredient.recipeunit === 'ml' ? 1000 : 1),
    value: sale.cost || 0, // Add sale cost if available
    uom: sale.recipeunit || ingredient.startRecordUOM || 'Unknown',
    type: sale.type || 'recipe',
    qtySold: sale.saleQuantity,

  }));
  return {
    ...ingredient,
    saleQuantity,
    saleQuantityDisplay: saleQuantityDisplay || '0.00',
    detailedSales,
  };
}

const buildLivestockIngredientWastage = (ingredient, livestockPeriodWastage) => {
  const ingredientWastage = livestockPeriodWastage.filter(wastage => wastage.id === ingredient.id);
  //console.log(ingredientWastage, ingredient, 'IngredientWASTAGE')
  let amount = 0;
  if (!_.isEmpty(ingredientWastage)) {
    amount = ingredientWastage.reduce((num, item) => num + ingWastageAmount(item), 0);
  }
  const wastageQuantity = parseFloat(amount);

  const denormIng = ingredient.ingredient ? ingredient.ingredient : ingredient;
  const wastageQuantityDisplay = denormalizeIngredientQuantity({ ...denormIng, amount, recordUOM: ingredient.startRecordUOM }).toFixed(2);

  // Construct detailed wastage array
  const detailedWastage = ingredientWastage.map((wastage) => {
    const isRecipe = wastage?.type === 'recipe'; // If `ingredient` key exists, it's a recipe
    return {    
      id: wastage.id,
    reason: wastage.reason ? wastage.reason : 'Unknown',
    amount: isRecipe
    ? (wastage?.amount || 0) / (wastage?.ingredient.recipeunit === 'g' || wastage?.ingredient.recipeunit === 'ml' ? 1000 : 1)
    : (wastage?.amount || 0) / (wastage?.recordUOM === 'g' || wastage?.recordUOM === 'ml' ? 1000 : 1),
    value: wastage.cost || 0,
    type: wastage.type, 
    name: wastage.type === 'recipe' ? wastage.recipeName : wastage.name || 'Unknown',
    recordUOM: ingredient.startRecordUOM || 'Unknown',
    date: wastage.date ? formatDate(wastage.date) : 'Unknown', 
    }
  });

  return {
    ...ingredient,
    wastageQuantity,
    ingredientWastage,
    wastageQuantityDisplay: wastageQuantityDisplay || '0.00',
    detailedWastage,
  };
}

const buildLivestockIngredientTransfer = (ingredient, livestockPeriodTransfer) => {
  const ingredientTransfer = livestockPeriodTransfer.filter(t => t.id === ingredient.id);
  //console.log(ingredientTransfer, 'TRANFEFEF')
  let amount = 0;
  if (!_.isEmpty(ingredientTransfer)) {
    amount = calcTransfersLiveBalance(ingredientTransfer);
  }
  const transferQuantity = parseFloat(amount);
  const denormIng = ingredient.ingredient ? ingredient.ingredient : ingredient;
  const transferQuantityDisplay = denormalizeIngredientQuantity({ ...denormIng, amount, recordUOM: ingredient.startRecordUOM }).toFixed(2);

  // Construct detailed transfer array
  const detailedTransfer = ingredientTransfer.map(transfer => ({
    id: transfer.id,
    amount: (transfer.amount || 0) / 
      (transfer.recordUOM === 'g' || transfer.recordUOM === 'ml' ? 1000 : 1), 
    type: transfer.transferType, // Type of the transfer (e.g., ingredient or recipe)
    name: transfer.name || 'Unknown', // Name of the ingredient/recipe
    recordUOM: ingredient.startRecordUOM || 'Unknown', // Unit of measurement
    date: transfer.date ? formatDate(transfer.date) : 'Unknown',
  }));

  return {
    ...ingredient,
    transferQuantity,
    ingredientTransfer,
    transferQuantityDisplay: transferQuantityDisplay || '0.00',
    detailedTransfer, // Include detailed transfer array
  };
}

const calculateIngredientLivestock = (ingredient) => {
  //console.log(ingredient, ingredient.name)
  const livestock = 
    ingredient.unitStockAmount +
    ingredient.orderQuantity -
    ingredient.saleQuantity -
    ingredient.wastageQuantity +
    ingredient.transferQuantity;
  
  const denormIng = ingredient.ingredient ? ingredient.ingredient : ingredient;
  const livestockDisplay = denormalizeIngredientQuantity({
    ...denormIng,
    amount: livestock,
    recordUOM: ingredient.startRecordUOM
  }).toFixed(2);

  // Calculate livestockValue based on the worth per unit (assumes `ingredient.worth` is the per-unit worth)
  //const livestockValue2 = (livestock * (ingredient.recipeprice || 0));
  //console.log(livestock, livestockValue2, ingredient.recipeprice)

  const livestockValue = ingredient.recipeprice > 0 
    ? (livestock * (ingredient.recipeprice || 0))
    : calcStocktakeIngredientCost({
      ...ingredient,
      amount: parseFloat(livestockDisplay),
      recordUOM: ingredient.startRecordUOM,
      recipeunit: ingredient.ingredient ? ingredient.ingredient.recipeunit : ingredient.recipeunit
    });

  return {
    ...ingredient,
    livestock,
    livestockDisplay: livestockDisplay || '0.00', //Theoretical Stock Amount
    livestockValue: livestockValue || 0, //Theoretical Stock Value
  };
};

const calculateIngredientVariance = (ingredient, initialStock) => {
  //console.log(ingredient.name, initialStock, 'INITITALSTOCKKK')
  const data = ingredientStock(ingredient, initialStock, ingredient.startRecordUOM);
  //console.log(ingredient.name, data, 'OEOROEROE')
  const variance = data.unitStockAmount - ingredient.livestock;
  
  const denormIng = ingredient.ingredient ? ingredient.ingredient : ingredient;

  const varianceDisplay = denormalizeIngredientQuantity({
    ...denormIng,
    amount: variance,
    recordUOM: ingredient.startRecordUOM
  }).toFixed(2);

  /*const varianceWorth = ingredient.recipeprice > 0 
    ? */
  const varianceWorth = (parseFloat(variance) * (ingredient.recipeprice || 0))
    /*
    : calcStocktakeIngredientCost({
    ...ingredient,
    amount: parseFloat(varianceDisplay),
    recordUOM: ingredient.startRecordUOM,
    recipeunit: ingredient.ingredient ? ingredient.ingredient.recipeunit : ingredient.recipeunit
  });*/
  //console.log(variance, varianceWorth)

  // Calculate `detailedClosing`
  const ingredientsClosingStock = initialStock.filter((stockItem) => stockItem.id === ingredient.id);
  //console.log(ingredientsClosingStock, '')
  const detailedClosing = ingredientsClosingStock
  .map((line) => {
    const isRecipe = !!line.ingredient; // Determine if it's part of a recipe

    return {
      id: line.id,
      name: isRecipe ? line.ingredient.recipeName || 'Unknown Recipe' : line.name || 'Unknown Ingredient',
      amount: isRecipe
        ? (line.ingredient?.normalQty || line?.amount || 0) / (line?.ingredient.recipeunit === 'g' || line?.ingredient.recipeunit === 'ml' ? 1000 : 1)
        : (line?.normalQty || 0) / (line?.recipeunit === 'g' || line?.recipeunit === 'ml' ? 1000 : 1),
      value: isRecipe
        ? line.ingredient.worth || 0 // Use `worth` from the recipe ingredient
        : line.worth || 0, // Use `worth` for regular ingredients
      uom: ingredient.startRecordUOM,
      type: isRecipe ? 'recipe' : 'ingredient', // Specify the type
    };
  })
  // Filter out rows with `amount` or `value` equal to 0 or null
  .filter((line) => line.amount > 0 || line.value > 0);

  const thisStockAmount = data.unitStockAmount
  const thisStockValue = data.unitStockValue
  //console.log(thisStockAmount, thisStockValue)

  const varianceWorth2 = (parseFloat(thisStockValue).toFixed(2) - (parseFloat(ingredient.livestockValue).toFixed(2)))

  // Log discrepancies where varianceWorth is different from thisStockValue
  /*if (Math.abs((thisStockValue - ingredient.livestockValue) - varianceWorth2) > 0.01) {
    console.group(`Discrepancy Found for Ingredient: ${ingredient.name}`);
    console.log('Variance Worth:', varianceWorth2);
    console.log('Stock Value:', (thisStockValue - ingredient.livestockValue));
    console.log('Difference:', (thisStockValue - ingredient.livestockValue) - varianceWorth2);
    console.log('Ingredient Details:', ingredient);
    console.groupEnd();
  }*/

  return {
    ...ingredient,
    varianceWorth: parseFloat(varianceWorth2),
    variance,
    varianceDisplay,
    thisStockAmount: thisStockAmount,
    thisStockDisplay: data.unitStockDisplay || '0.00',
    thisStockValue: thisStockValue,
    detailedClosing,
  };
};

const finalOrderItems = ({ items, type, deliveryDate, supplierId }) =>
  items
    ? items.map(item => ({
        ...item,
        type: type ? type : 'DEBIT',
        deliveryDate,
        supplierId,
      }))
    : [];

const finalTransferItems = ({records, transferType, date}) => records ? records.map(item => ({ ...item, transferType, date })) : [];

export const calculateTotalWastageCost = (accountId, initialStocktake, previousStocktake = null) => {
  const data = buildPeriodData(accountId, initialStocktake, previousStocktake);
  //console.log(data)
  const totalWastageCost = _.sumBy(data.periodSales, (sale) => sale.totalwastagecost || 0);
  //console.log(totalWastageCost)
  return totalWastageCost;
};

export const buildLivestock = (accountId, initialStocktake, archive = false, previousStocktake = null, ingredientId = null) => {
  // Step 1: Build period data containing orders, sales, wastage, and transfers for the period
  const data = buildPeriodData(accountId, initialStocktake, previousStocktake);
  //console.log(data, "buildPErdioData")

  let livestock = [];

  // Step 2: Build starting stock for the period based on the stocktake data
  // If 'archive' mode is enabled and there is a previous stocktake, use the previous stocktake's ingredients
  // Otherwise, use the initial stocktake's ingredients
  // NOTE: The `periodStartStock` contains the initial stock values, including the 'worth' for each ingredient
  const periodStartStock = archive ? previousStocktake ? buildPeriodStockIngredients(previousStocktake) : [] : buildPeriodStockIngredients(initialStocktake);
  //console.log(periodStartStock, 'PERIODSTART')

  // Step 3: Retrieve the full list of ingredients for the account
  // If 'archive' mode is enabled, use all ingredients from the initial stocktake and current state
  const livestockIngredientsList = buildIngredients(initialStocktake, previousStocktake, current(store.getState(), 'ingredients', accountId));
  //console.log(ingredientsList2, 'IngredientList22222222')
  const ingredientsList = archive ? buildAllIngredients(initialStocktake, current(store.getState(), 'ingredients', accountId)) : current(store.getState(), 'ingredients', accountId);
  //console.log(ingredientsList, 'IngredientList')

  // Step 4: Build additional data for orders, sales, wastage, and transfers for the period
  //console.log(livestockIngredientsList , 'PERIODSTART')
  const livestockPeriodOrders = _.compact(_.flatten(data.periodOrders.map(finalOrderItems)));
  //console.log(livestockPeriodOrders, 'ORDERSS')
  const livestockPeriodSales = buildPeriodSales(data);
  //console.log(livestockPeriodSales, 'SALESSS')
  const livestockPeriodWastage = buildPeriodWastage(data);
  //console.log(livestockPeriodWastage, "PerdioWastage")
  const livestockPeriodTransfers = buildPeriodTransfers(_.flatten(data.periodTransfers.map(finalTransferItems)));
  //console.log(livestockPeriodTransfers, "PerdioWastage")

  // Calculate the total wastage cost from periodSales
  const totalWastageCost = _.sumBy(data.periodSales, (sale) => sale.totalwastagecost || 0);
  //console.log("Total Wastage Cost:", totalWastageCost);

  // Step 5: Map through each ingredient and calculate livestock data step by step
  livestock = livestockIngredientsList.map(ingredient =>
    buildLivestockIngredientStock(ingredient, periodStartStock)
  ).map(ingredient =>
    buildLivestockIngredientOrders(ingredient, livestockPeriodOrders)
  ).map(ingredient => 
    buildLivestockIngredientSales(ingredient, livestockPeriodSales)
  ).map(ingredient =>
    buildLivestockIngredientWastage(ingredient, livestockPeriodWastage)
  ).map(ingredient =>
    buildLivestockIngredientTransfer(ingredient, livestockPeriodTransfers)
  ).map(ingredient =>
    calculateIngredientLivestock(ingredient, (archive ? periodStartStock : null))  
  );
  if (archive) {
    livestock = livestock.map(ingredient => calculateIngredientVariance(ingredient, ingredientsList))
  }
  return livestock;
}

export const buildRecipeLive = (accountId, initialStocktake, previousStocktake = null) => {
  const data = buildPeriodData(accountId, initialStocktake, previousStocktake);
  const initRecipes = initialStocktake && initialStocktake.recipes ? initialStocktake.recipes : [];
  //console.log(`Processing ${initRecipes.length} initial recipes.`);

  let livestock = [];
  livestock = initRecipes.map((recipe, index) => {
    //console.log(`Processing recipe ${index + 1}/${initRecipes.length}:`, recipe.name);
    const startRecordUOM = getIngredientStockUOM({ recipeunit: recipe.yieldDescription });
    const salesAmountNew = data.periodSales.reduce((total, sale) => {
      const amount = findSubRecipeAmount(recipe, sale.recipeId, sale.qty);
      return total + (amount || 0)
    }, 0);
    //console.log(`Calculated sales amount for recipe ${recipe.name}: ${salesAmountNew}`);

    const saleDisplay = denormalizeIngredientQuantity({ ...recipe, amount: salesAmountNew, recipeunit: recipe.yieldDescription }).toFixed(2);
    return {
      ...recipe,
      saleAmount: salesAmountNew || '0',
      saleDisplay,
      startRecordUOM
    }
  }).map(recipe => {
    const wastageAmountNew = data.periodWastage.reduce((total, waste) => {
      const amount = waste.recipe ? findSubRecipeAmount(recipe, waste.recipe.id, waste.normalQty) : 0;
      return total + (amount || 0)
    }, 0);
    const wasteDisplay = denormalizeIngredientQuantity({ ...recipe, amount: wastageAmountNew, recipeunit: recipe.yieldDescription }).toFixed(2);
    return {
      ...recipe,
      wastageAmount: wastageAmountNew || '0',
      wasteDisplay
    }
  }).map(recipe => {
    const transferAmountNew = data.periodTransfers.reduce((total, transfer) => {
      const amount = transfer.recipe ? findSubRecipeAmount(recipe, transfer.recipe.id, transfer.normalQty) : 0;
      if (transfer.transferType === 'IN') return total + (amount || 0);

      return total - (amount || 0)
    }, 0);
    const transferDisplay = denormalizeIngredientQuantity({ ...recipe, amount: transferAmountNew, recipeunit: recipe.yieldDescription }).toFixed(2);
    return {
      ...recipe,
      trasnferAmount: transferAmountNew || '0',
      transferDisplay
    }
  }).map(recipe => {
    const previousAmountNew = previousStocktake && previousStocktake.recipes ?
      previousStocktake.recipes.reduce((total, item) => {
        const amount = findSubRecipeAmount(recipe, item.id, item.normalQty);
        return total + (amount || 0)
      }, 0) : 0;
    const prevDisplay = denormalizeIngredientQuantity({ ...recipe, amount: previousAmountNew, recipeunit: recipe.yieldDescription }).toFixed(2);
    return {
      ...recipe,
      previousAmount: previousAmountNew || '0',
      prevDisplay
    }
  }).map(recipe => {
    const closingDisplay = denormalizeIngredientQuantity({ ...recipe, amount: parseFloat(recipe.normalQty), recipeunit: recipe.yieldDescription }).toFixed(2);
    return {
      ...recipe,
      closing: recipe.normalQty || '0',
      closingDisplay
    }
  });
  //console.log('buildRecipeLive completed.');
  return livestock;
}

const findMostRecentStocktake = (state, accountId, weekEnd) => (
  _.last(
    _.sortBy(
      _.filter(current(state, 'stockTakes', accountId), (stocktake) => (!stocktake.isDraft && moment.utc(getStocktakeDate(stocktake)).isSameOrBefore(weekEnd, 'date'))),
      (stocktake) => getStocktakeDate(stocktake)
    )
  )
);

export const findNextStocktake = (state, accountId, weekEnd) => {
  const nextStocktake = _.first(
    _.sortBy(
      _.filter(current(state, 'stockTakes', accountId), (stocktake) => (
        !stocktake.isDraft && moment.utc(getStocktakeDate(stocktake)).isAfter(weekEnd, 'date')
      )),
      (stocktake) => getStocktakeDate(stocktake)
    )
  );

  return nextStocktake || null;
};

export const calculcateWeekWastageCost = (state, accountId, weekStart, weekEnd) => (
  _.sumBy(
    _.filter(current(state, 'wastages', accountId), (wastage) => {
      const wastageDate = getWastageDate(wastage);
      if (!wastageDate) {
        // Ignore any wastage records without a date
        return false;
      }
      return (moment.utc(wastageDate).isBetween(weekStart, weekEnd, 'day', '[]'));
    }),
    (wastage) => ((isNaN(wastage.cost)) ? 0 : wastage.cost)
  )
)

// Function to aggregate incidental costs for a given period (from periodStart to weekEnd)
// This function gathers incidentals such as procurement transfers, petty cash, and stock allowances
// It uses the selectPeriodReportIncidentals function to retrieve all incidentals within the period
// Then, it reduces the array of incidentals to aggregate the total values for each type of incidental
// The returned object contains the total values for procurementTransferIn, procurementTransferOut,
// procurementPettyCash, and stockAllowances for the given period.
const buildPeriodReportIncidentals = (state, accountId, periodStart, weekEnd) => {
  const periodIncidentals = selectPeriodReportIncidentals({ state, accountId, periodStart, weekEnd });
  return periodIncidentals.reduce((obj, item) => {
    for (const key in obj) {
      if (item[key]) obj[key] += parseFloat(item[key]); 
    }
    return obj;
  }, {procurementTransferIn: 0, procurementTransferOut: 0, procurementPettyCash: 0, stockAllowances: 0})
}

// Function to calculate the expected stock value for a given period
// This function uses the most recent stocktake before or on the weekEnd as a starting point.
// It calculates the expected stock value by considering orders, transfers, incidental costs, wastage, and sales for that period.
// The final expected stock value is the opening stocktake value plus or minus all period-based adjustments.
export const buildExpectedStockValue = ({accountId, weekStart, weekEnd}) => {
  const state = store.getState();

  // Get the most recent stocktake date before or on the weekEnd (which can go back before weekStart if needed), else return 0 (new user)
  const mostRecentStocktake = findMostRecentStocktake(state, accountId, weekEnd)

  // Set the period start to the stocktake date or the start of the week if no stocktake exists (new user)
  const periodStart = (mostRecentStocktake) ? getStocktakeDate(mostRecentStocktake) : weekStart;

  // Calculate the cost of the opening stocktake, or set to 0 if no stocktake
  const mostRecentOpeningStocktakeValue = (mostRecentStocktake) ? calcStocktakeCost(mostRecentStocktake) : 0;

  // Calculate total costs for the period: orders, transfers, incidental costs, wastage, and sales
  const periodOrdersCost = calcOrdersTotal(getOrdersForPeriod(accountId, periodStart, weekEnd));
  const periodTransfersCost = periodTransferCosts(accountId, periodStart, weekEnd);
  const periodWastageCost = calculcateWeekWastageCost(state, accountId, periodStart, weekEnd);
  const perdiodIncidentalCost = buildPeriodReportIncidentals(state, accountId, periodStart, weekEnd);

  // Sum up sales costs for the period
  const periodSales = getSalesForPeriod(accountId, periodStart, weekEnd);
  const periodSalesCost = _.sumBy(periodSales, 'totalcost');
  
  // Calculate the expected stock value by adding/subtracting all relevant costs
  const expectedClosingStocktakeValue = mostRecentOpeningStocktakeValue + (
    periodOrdersCost
    + castFloat(periodTransfersCost.transfersIn)
    - castFloat(periodTransfersCost.transfersOut)
    + castFloat(perdiodIncidentalCost.procurementPettyCash)
  ) - periodWastageCost - periodSalesCost - castFloat(perdiodIncidentalCost.stockAllowances);

  return {
    expectedClosingStocktakeValue,
    mostRecentOpeningStocktakeValue,
    periodStart
  }
}

// Function to calculate the theoretical stock value for the week
// This function builds on the expected stock value by considering weekly sales, procurement, transfers, and wastage.
// It calculates the theoretical closing stock value, adjusting for weekly activities.
export const calculcateWeekTheoreticalStock = (weekStart, weekEnd, accountId) => {
  const state = store.getState();

  // First, calculate the expected stock value for the period
  const { periodStart, expectedClosingStocktakeValue, mostRecentOpeningStocktakeValue } = 
    buildExpectedStockValue({accountId, weekStart, weekEnd});

  // Ensure the weekStart aligns with the periodStart (IMPORTANT!! in case the stocktake happens after the weekStart)
  if (moment.utc(periodStart).isAfter(weekStart,'day')) weekStart = periodStart;

  // Gather sales, procurement, wastage, transfers, and incidentals for the current week
  const weekOrders = getOrdersForPeriod(accountId, weekStart, weekEnd);
  const weekOrdersCost = calcOrdersTotal(weekOrders);

  const weekTransfersCost = periodTransferCosts(accountId, weekStart, weekEnd);
  const weekWastageCost = calculcateWeekWastageCost(state, accountId, weekStart, weekEnd);
  const weekReportIncidentalsCost = buildPeriodReportIncidentals(state, accountId, weekStart, weekEnd);
  
  // Sum up the sales cost and net sales for the week
  const weekSales = getSalesForPeriod(accountId, weekStart, weekEnd);
  const weekSalesCost = _.sumBy(weekSales, 'totalcost');
  const weekSalesNet = _.sumBy(weekSales, 'totalnet');

  const procurementTotal = (
    weekOrdersCost
    + castFloat(weekTransfersCost.transfersIn)
    - castFloat(weekTransfersCost.transfersOut)
    + castFloat(weekReportIncidentalsCost.procurementPettyCash)
  );

  // Calculate the theoretical opening stock value by reverse-engineering
  // We adjust the expectedClosingStocktakeValue by subtracting procurement total (orders, transfers, etc.),
  // then adding back sales costs, wastage, and incidental stock allowances to estimate what the opening stock would have been.
  // This is only necessary if no stocktake exists at the start of the week, allowing us to estimate stock between stocktakes.
  const expectedOpeningStocktakeValue = 
    expectedClosingStocktakeValue -
    procurementTotal +
    weekSalesCost +
    weekWastageCost +
    castFloat(weekReportIncidentalsCost.stockAllowances);
  
  const stockType = (moment.utc(periodStart).isSame(moment.utc(weekStart), 'day')) ? 'Actual' : 'Theoretical';

  // Return all calculated values, including the theoretical stock value for the week
  return {
    expectedClosingStocktakeValue,
    expectedOpeningStocktakeValue,
    periodStart, //from mostRecentOpeningStocktake
    mostRecentOpeningStocktakeValue,
    weekSalesNet,
    stockType,
  };
}

//Functions
// New function to dispatch stocktakes
export const dispatchStocktakes = (accountId, recentStocktakes) => {
  const stocktakeDataArray = [];  // Array to store all stocktake data

  // Ensure there are enough stocktakes to create pairs
  if (recentStocktakes.length >= 2) {
    for (let i = 0; i < recentStocktakes.length - 1; i++) {
      // Get the current stocktake pair
      const stocktakeA = recentStocktakes[i];
      const stocktakeB = recentStocktakes[i + 1];

      // Call buildLivestock with the current pair
      const livestockData = buildLivestock(accountId, stocktakeA, true, stocktakeB);

      const stocktakeData = {
        stocktakeId: stocktakeA.id,  
        livestockData,
      };

      // Add the stocktake data to the array
      stocktakeDataArray.push(stocktakeData);
    }
  }
  
  // Return the stocktake data once it's processed
  return stocktakeDataArray;
};
