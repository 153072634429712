import { takeEvery, put, call, select, fork } from 'redux-saga/effects';
import { addStockTake, removeStockTake, updateStockTake } from '../firebase/stockTake';
import { actions, actionTypes } from '../actions';
import { activeAccount, currentUserName } from '../../utils/selectors';
import { dispatchStocktakes } from '../../utils/stock'

export default function* stockTakeSaga() {
  yield takeEvery(actionTypes.stockTake.ADD_STOCKTAKE, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      const userName = yield select(currentUserName);
      yield call(addStockTake, action.stockTake, accountId, userName);
      yield put(actions.appMessageSuccess('Stock Take Added'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      yield put(actions.appMessageError('Stock Take could not be added'));
    }
  });
  yield takeEvery(actionTypes.stockTake.REMOVE_STOCKTAKE, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(removeStockTake, action.stockTakeId, accountId);
      yield put(actions.appMessageSuccess('Stock Take Removed'));
    }
    catch (err) {
      yield put(actions.appMessageError('Stock Take could not be removed'));
    }
  });
  yield takeEvery(actionTypes.stockTake.UPDATE_STOCKTAKE, function* (action) {
    const { id, ...stockTake } = action.stockTake;
    try {
      const accountId = yield action.accountId || select(activeAccount);
      const userName = yield select(currentUserName);
      yield call(updateStockTake, id, stockTake, accountId, userName);
      yield put(actions.appMessageSuccess('Stock Take Updated'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      yield put(actions.appMessageError('Stock Take could not be updated'));
    }
  });
  /// Handling the action to load the most recent stocktakes and dispatch them in the background
  yield takeEvery(actionTypes.stockTake.LOAD_MOST_RECENT_STOCKTAKES_REQUESTED, function* (action) {
    const { accountId, payload } = action;
    // Fork the dispatchStocktakes process in the background
    const stocktakeDataArray = yield call(dispatchStocktakes, accountId, payload);
    // Once the background task is done, update the Redux store with the processed data
    if (stocktakeDataArray && stocktakeDataArray.length > 0) {
      //console.log('Dispatching stocktake data:', stocktakeDataArray);
      yield put(actions.stockTake.loadMostRecentStocktakesLoaded(accountId, stocktakeDataArray));
    } else {
      console.log('No stocktakes to dispatch.');
    }
  });
};
