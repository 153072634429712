import _ from 'lodash';
import moment from 'moment';
import { getWC } from './time';
import { dateStoreFormat } from './format';
import { accountTypes } from './constants';

// Retrieves the currently active account ID from the Redux state.
export const activeAccount = (state) => (state.currentAccount);

// Retrieves the current user name from the Redux state.
export const currentUserName = (state) => {
  return state.profile?.name;
};

// Selector to get menus for a given groupId
export const selectMenus = (state, groupId) => state.menus[groupId] || [];

// Selector to get recipes for a given groupId
export const selectRecipes = (state, groupId) => state.recipes[groupId] || [];

// Selector to get modifiers for a given groupId
export const selectModifiers = (state, groupId) => state.modifiers[groupId] || [];

// Selector to get non-deleted modifiers for a given groupId
export const selectNonDeletedModifiers = (state, groupId) => {
  const allModifiers = state.modifiers[groupId] || [];
  //console.log("All Modifiers: ", allModifiers); // Debugging line
  const nonDeletedModifiers = allModifiers.filter(modifier => !modifier.deleted);
  //console.log("Non-Deleted Modifiers: ", nonDeletedModifiers); // Debugging line
  return nonDeletedModifiers;
};

// Store the cached results
let cachedResults = {};

export const current = (state, collectionName, accountId = false) => {
  const ca = accountId || activeAccount(state);
  let results = [];

  if (!ca || !state.accounts[ca]) return results;

  // Check if the cached result is the same as the current state
  const cacheKey = `${ca}:${collectionName}`;

  // Only return cached results if the data hasn't changed
  const isDataChanged = () => {
    const currentData = state[collectionName][ca];
    const cachedData = cachedResults[cacheKey];

    // If there's no cached data, data has changed
    if (!cachedData) return true;

    // If the current data is different from the cached data, recompute
    return !_.isEqual(currentData, cachedData);
  };

  // If the cache is still valid, return the cached result
  if (cachedResults[cacheKey] && !isDataChanged()) {
    //console.log("Using cached results for", cacheKey);
    return cachedResults[cacheKey];
  }

  const currentAccount = state.accounts[ca];

  if (currentAccount?.departments) {
    for (let x = 0, ln = currentAccount.departments.length; x < ln; x++) {
      const deptId = currentAccount.departments[x];
      results = _.concat(results, _.map(state[collectionName][deptId], (item) => ({ ...item })));
    }
  }

  if (currentAccount?.parents) {
    for (let x = 0, ln = currentAccount.parents.length; x < ln; x++) {
      const parentId = currentAccount.parents[x];
      results = _.concat(results, _.map(state[collectionName][parentId], (item) => ({ ...item })));
    }
  }

  // Directly include data associated with the current account
  if (state[collectionName][ca]) {
    results = _.concat(results, state[collectionName][ca]);
  }

  // Cache the result for this accountId and collectionName if data is valid
  cachedResults[cacheKey] = _.uniqBy(results, 'id');

  return cachedResults[cacheKey];
};

export const getRelevantDepartments = (state) => {
  const currentAccountId = state.currentAccount;
  const currentAccount = state.accounts[currentAccountId];

  if (!currentAccount) {
    return [];
  }

  if (currentAccount.type === accountTypes.OWNER) {
    // If the account is a head office, get its children accounts as departments
    const childrenDepartments = currentAccount.children
      ? currentAccount.children
          .map((childId) => state.accounts[childId])
          .filter((dept) => dept && dept.type === accountTypes.DEPARTMENT)
      : [];
    return _.sortBy(childrenDepartments, 'name');
  } else {
    // If not a head office, return any account that lists the current account as its parent
    const parentDepartments = _.filter(
      state.accounts,
      (dept) =>
        dept.type === accountTypes.DEPARTMENT &&
        dept.parents &&
        dept.parents.includes(currentAccountId)
    );
    return _.sortBy(parentDepartments, 'name');
  }
};

// Selects report incidental data for a given week and account.
export const selectReportIncidentals = ({ state, accountId, weekStart }) => {
  const weekStartDate = getWC(moment.utc(weekStart).toDate());
  const weekly = _.find(current(state, 'reportsIncidentals', accountId), { 'weekOf': dateStoreFormat(weekStartDate) }) || {};
  const yearStart = dateStoreFormat(getWC(moment.utc(weekStart).month(0).date(1).toDate()));
  const salesPrevYear = _.get(
    _.find(
      current(state, 'reportsIncidentals', accountId),
      { 'weekOf': yearStart }
    ),
    'salesPrevYear',
    0
  );

  return {
    ...weekly,
    salesPrevYear
  };
};

// Selects and aggregates report incidental data for a given account over a specified date range.
export const selectPeriodReportIncidentals = ({ state, accountId, periodStart, weekEnd }) => {
  const weekOf = moment.utc(periodStart, "YYYY-MM-DD");
  const weekStartDate = getWC(weekOf.toDate());
  weekEnd = moment.utc(weekEnd, "YYYY-MM-DD");
  return current(state, 'reportsIncidentals', accountId).filter(report => {
    const reportDate = moment.utc(report.weekOf, "YYYY-MM-DD");
    return reportDate.isSameOrAfter(weekStartDate, 'day') && reportDate.isSameOrBefore(weekEnd, 'day');
  });
};

// Constructs a hierarchical tree of ingredient categories based on parent-child relationships.
export const selectIngredientCategoryHierarchy = (state) => {
  const arrangeHierarchy = (currentLevel, branchMap, list = [], level = 0, path = '') => {
    for (let x = 0, ln = currentLevel.length; x < ln; x++) {
      list.push({
        ...currentLevel[x],
        level,
        hierarchy: path
      });
      if (branchMap[currentLevel[x].id]) {
        arrangeHierarchy(branchMap[currentLevel[x].id], branchMap, list, level + 1, path + currentLevel[x].id + '$');
      }
    }
    return list;
  };
  const [trunks, branches] = _.partition(current(state, 'ingredientCategories'), (cat) => !cat.parent);
  const branchMap = _.groupBy(_.sortBy(branches, (cat) => _.lowerCase(cat.name)), 'parent');
  const hierarchy = arrangeHierarchy(trunks, branchMap);

  return hierarchy;
};
