import store from '../redux';
import fb from './'
import { actions } from '../actions';
import _ from 'lodash';

// Reference to the 'profiles' collection in Firestore
const profileRef = fb.firestore.collection('profiles')
let profileListener

// Function to create a new profile with default values based on user data
const createNewProfile = (user) => {
  const profile = {
    currentaccount: null,
    email: user.email,
    name: user.displayName,
    provider: 'google',
    uid: user.uid
  }
  addProfile(user.uid, profile)
  profile.id = user.uid
  return profile
}

// Function to load a user's profile from Firestore
export const loadProfile = (user) => {
  if (!user || !user.uid) {
    console.error('Incomplete user info provided!', user);
    return;
  }

  if (profileListener) {
    profileListener();
  }
  
  //console.log(`loading: profile/${user.uid}`)
  store.dispatch(actions.applicationLoadStart(`profile:${user.uid}`));

  // Sets up a real-time listener for the user's profile document in Firestore
  profileListener = profileRef.doc(user.uid).onSnapshot((doc) => {
    const profile = (doc.exists) ? { id: doc.id, ...doc.data() } : createNewProfile(user);
    const currentaccount = profile.currentaccount;

    // Always dispatch profile load success first
    store.dispatch(actions.profile.profileLoadSuccess(profile));
  
    if (currentaccount) {
      //console.log(`[loadProfile] Current Account: ${currentaccount}`);
      store.dispatch(actions.accounts.currentAccountSet(currentaccount));
    
      // Now trigger account loading with the current account as priority
      store.dispatch(actions.accounts.accountsLoadRequest(user.email, currentaccount));
    } else {
      //console.log('[loadProfile] No current account, loading accounts without priority');
      // If no current account, load accounts without priority
      store.dispatch(actions.accounts.accountsLoadRequest(user.email, currentaccount));
    }
  });
};

// Function to clear the profile data from the application state
// Function to clear the profile data from the application state
export const clearProfile = () => {
  // Stop the snapshot listener
  if (profileListener) {
    profileListener(); // Unsubscribe from Firestore listener
    profileListener = null;
  }
};


// Function to add a profile to Firestore
export const addProfile = (profileId, profile) => {
  // Adds or merges the profile document in Firestore
  return profileRef.doc(profileId).set(profile, {merge: true})
}

// Function to remove a profile from Firestore
export const removeProfile = (profileId) => {
  return profileRef.doc(profileId).delete()
}

// Function to update a profile in Firestore
export const updateProfile = (profileId, profile) => {
  return profileRef.doc(profileId).update(profile)
}
