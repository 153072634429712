//React
import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

//Libraries
import _ from 'lodash';
import { majorScale, Table, Text, Pane } from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';
import CustomDialog from '../../../components/Dialog/Dialog';
import LoadingIndicator from '../../../components/IsLoading/IsLoading';

//Files
import { currency, fullDateDisplayReduceDay } from '../../../utils/format';
import { calcStocktakeCost, getStocktakeDate } from '../../../utils/functions';
import { current } from '../../../utils/selectors';
import { actions } from '../../../store/actions';

const StockTakeArchive = () => {
  const { accountId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  //const mostRecentStocktakeLoading = useSelector((state) => state.stocktakesRecent.loading);
  const [isNavigating, setIsNavigating] = useState(false); 
  //const [hasDispatched, setHasDispatched] = useState(false);
  //console.log(isLoading, mostRecentStocktakeLoading)

  const archiveFromState = useSelector((state) => {
    let archive = _.reverse(_.sortBy(_.filter({ ...current(state, 'stockTakes', accountId) }, { 'isDraft': false }), (stocktake) => getStocktakeDate(stocktake)));
    return _.map(archive, (item) => ({
      ...item,
      cost: calcStocktakeCost(item)
    }));
  });
  // Then memoize the expensive calculations if needed
  const archive = useMemo(() => archiveFromState, [archiveFromState]);

  useEffect(() => {
    if (archive.length > 0) {
      setIsLoading(false);
    }
  }, [archive]);

  // Separate effect for dispatching stocktake
  /*useEffect(() => {
    if (!isLoading && !hasDispatched && archive.length > 0) {
      setHasDispatched(true);
      dispatch(actions.stockTake.loadMostRecentStocktakesRequested(accountId, archive.slice(0, 4)));
    }
  }, [isLoading, hasDispatched, archive, accountId, dispatch])*/

  //Handle reopening Stocktake
  const [selectedStocktake, setSelectedStocktake] = useState(null);
  const [isDialogShown, setIsDialogShown] = useState(false);

  const reopenStocktake = (accountId, stockTake) => {
    if (stockTake) {
      // Filter out ingredients with amount <= 0
      const filteredIngredients = stockTake.ingredients?.filter(ingredient => parseFloat(ingredient.amount) > 0) || [];

      // Dispatch updated stocktake
      dispatch(
        actions.stockTake.updateStockTake(accountId, {
          ...stockTake,
          isDraft: true,
          cost: null,
          ingredients: filteredIngredients,
        })
      );
      // Navigate to the stocktake detail view or another desired page
      navigate(`/${accountId}/inventory/stocktake/${stockTake.id}`);
    }
  };

  const handleReopenStock = (stocktake) => {
    setSelectedStocktake(stocktake);
    setIsDialogShown(true);
  }

  const handleNavigate = (url) => {
    setIsNavigating(true); // Set navigating to true
    setTimeout(() => {
      navigate(url); // Perform navigation after ensuring state is updated
    }, 0); // Allow React to complete rendering before navigating
  };  

  const confirmReopen = () => {
    if (selectedStocktake) {
      reopenStocktake(accountId, selectedStocktake);
    }
    setIsDialogShown(false);
  };

  return (
    <>
    { isNavigating ? (
      <LoadingIndicator message="Generating your report, please wait..." />
    ) : (
    <Block>
      <CustomHeading level="3" marginY={majorScale(2)} paddingX={majorScale(2)}>Stocktakes</CustomHeading>
      { isLoading ? (
        <LoadingIndicator message="Getting your stocktakes, please wait..." />
      ) : (
      <Table>
        <Table.Head height={48}>
          <Table.TextHeaderCell>Date</Table.TextHeaderCell>
          <Table.TextHeaderCell>Value</Table.TextHeaderCell>
          <Table.TextHeaderCell>Stocktake</Table.TextHeaderCell>
          <Table.TextHeaderCell>Report</Table.TextHeaderCell>
          <Table.TextHeaderCell>Re-open</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {_.isEmpty(archive) && (
            <Table.Row height={48}w>
              <Table.TextCell padding={majorScale(1)}>No Stocktake to display</Table.TextCell>
            </Table.Row>
          )}
          {_.map(archive, (stocktake) => (
            <Table.Row height={48} key={stocktake.id}>
              <Table.TextCell>{fullDateDisplayReduceDay(getStocktakeDate(stocktake))}</Table.TextCell>
              <Table.TextCell>£ {currency(stocktake.cost)}</Table.TextCell>
              <Table.Cell>
                <IconWrapper
                    //is={Link}
                    onClick={() => handleNavigate(`/${accountId}/review/stocktake/view/${stocktake.id}`)}
                    name="clipboard"
                    appearance="clickable"
                />
              </Table.Cell>
              <Table.Cell>
                <IconWrapper
                    onClick={() => handleNavigate(`/${accountId}/review/stocktake/report/${stocktake.id}`)}
                    name="clipboard"
                    appearance="clickable"
                />
              </Table.Cell>
              <Table.Cell>
                <IconWrapper
                    // component={Link}
                    onClick={() => handleReopenStock(stocktake)}
                    name="edit"
                    appearance='danger'
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      )}

      <CustomDialog
        isOpen={isDialogShown}
        title="Confirm reopen Stocktake"
        onClose={() => setIsDialogShown(false)}
        onConfirm={confirmReopen}
        confirmLabel="Reopen Stocktake"
      >
        <Pane marginBottom={majorScale(4)}>
          <Text>Are you sure you want to reopen this stocktake?
          If prices have changed, this action will impact its total value!
          </Text>
        </Pane>
      </CustomDialog>
    </Block>
    )}
    </>
  );
};

export default StockTakeArchive;
