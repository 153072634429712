//Libraries
import _ from 'lodash';
import moment from 'moment';

//Files
import store from '../store/redux';
import { orderStatuses } from './constants';
import { filterSalesItems, getSalesForPeriod, calcOrdersTotal, calcPercentage, calcStocktakeCost, getOrdersForPeriod, 
  getProcurementTotal, getStocktakeDate, periodTransferCosts, denormalizeIngredientQuantity, getOrderDate, ingOrderAmount } from './functions';
import { calculcateWeekTheoreticalStock, findNextStocktake, calculcateWeekWastageCost } from './stock';
import { buildFinancialPeriodsAccountsData } from './financials';
import { filterActiveIngredients } from './ingredients'
import { buildAccountData } from './accounts';
import { selectReportIncidentals, current } from './selectors';

// Calculates the start and end of the week, along with the previous week’s start and end.
export const buildWeekDates = (ws) => {
  //console.log(ws, "WS")
  const weekStart = moment.utc(ws).startOf('isoWeek').toDate(); // Monday 00:00 UTC
  const weekEnd = moment.utc(ws).endOf('isoWeek').toDate(); // Sunday 23:59 UTC
  const prevWeekStart = moment.utc(weekStart).subtract(1, 'week').toDate();
  const prevWeekEnd = moment.utc(prevWeekStart).endOf('isoWeek').toDate();
  return {
    weekStart,
    weekEnd,
    prevWeekEnd,
    prevWeekStart
  }
}

//DATA_SET in Redux
export const initFilters = {
  locations: [],
  areas: [],
  departments: [],
  categories: [],
  suppliers: [],
  ingredientCategories: []
}

// Function to aggregate the data
export const getAccountsData = (state, accountId) => {
  const filters = initFilters
  // Fetch supplierMap, ingredients, ingredientCategories using current()
  const supplierMapData = current(state, 'suppliers', accountId);
  const allIngredients = current(state, 'ingredients', accountId);
  const ingredientCategoriesData = current(state, 'ingredientCategories', accountId);

  // Compute supplierMap and ingredients
  const supplierMap = _.keyBy(supplierMapData, 'id');
  const ingredients = filterActiveIngredients(allIngredients);
  const ingredientCategories = _.sortBy(
    _.concat(
      [{ label: '<No Category>', value: 'uncategorized' }],
      _.map(ingredientCategoriesData, (cat) => ({ label: cat.name, value: cat.id }))
    ),
    'label'
  );

  // Aggregate accounts data
  const aggregatedAccountsData = buildAccountData(accountId, filters);
  const accountsData = {
    ...aggregatedAccountsData,
    supplierMap,
    ingredients,
    ingredientCategories
  };

  return accountsData;
};

export const getSalesData = (accountId, accountsData, weekdates, filterProps) => {
  const weekSalesDates = buildWeekDates(weekdates.start);
  const newAccountsData = { ...accountsData, filterProps };
  const salesAccountsData = buildAccountsData({
    accounts: newAccountsData,
    options: { sales: true },
    ...weekSalesDates,
  });

  const salesData = buildAggregatedSalesData(salesAccountsData);
  return { salesDeptData: salesAccountsData, ...salesData };
};

export const getCostsData = (accountId, accountsData, weekdates, filterProps) => {
  const weekSalesDates = buildWeekDates(weekdates.start);
  const newAccountsData = { ...accountsData, filterProps };
  const costsAccountsData = buildAccountsData({
    accounts: newAccountsData,
    options: { purchases: true },
    ...weekSalesDates,
  });

  const costsData = buildAggregatedOrdersData(costsAccountsData);
  return { costsDeptData: costsAccountsData, ...costsData };
};

export const getFinancialsData = (financial, accountsData, currentPeriod, currentYear, filterProps) => {
  const newAccountsData = { ...accountsData, filterProps };
  // Get financial data based on the new accountsData and weekSalesDates
  const periodsDataMemo = buildFinancialPeriodsAccountsData({
    financial,
    accounts: newAccountsData,
    currentPeriod, 
    currentYear,
  });

  return periodsDataMemo;
};

//SALES
// Aggregates weekly sales data, including total gross sales, net sales, costs, and quantity sold. It’s crucial for generating sales totals and KPIs on the dashboard.
const aggregateSales = (sales) => {
  return sales.reduce((data, sale) => {
    if (sale.totalcost) {
      data.weekSalesCost += sale.totalcost;
    }
    if (sale.totalgross) {
      data.weekSalesTotal += sale.totalgross;
    }
    if (sale.totalnet) {
      data.weekSalesNet += sale.totalnet;
    }
    if (sale.totalqty) {
      data.weekSalesQty += sale.totalqty;
    }
    return data;
  }, {
    weekSalesTotal: 0,
    weekSalesNet: 0,
    weekSalesCost: 0,
    weekSalesQty: 0
  });
}

// Combines account-level sales data for a specific week, applying filters if needed. 
// This allows you to get a filtered view of weekly sales.
export const aggregateFilterWeekSales = (salesDept, type = 'weekSales', filters = null) => {
  let sales = [];
  for (const id in salesDept) {
    const account = salesDept[id];
    if (account[type]?.length > 0) {
      const filtered = filterSalesItems(id, account[type], filters);
      sales = sales.concat(filtered);
    }
  }
  const aggregated = aggregateSales(sales);
  return aggregated;
}

export const findRecipePlu = (salesDept, recipeId, type = 'weekSales') => {
  for (const id in salesDept) {
    const account = salesDept[id];
    if (account[type]?.length > 0) {
      // Look for the matching recipeId in the weekSales array
      const matchedSale = account[type].find((sale) => sale.recipeId === recipeId);
      if (matchedSale && matchedSale.plu) {
        return matchedSale.plu; // Return the PLU if found
      }
    }
  }
};

// Specific for calculating sales for individual recipes or categories within a given week. 
// This function is useful when you need more granular reporting at the recipe level.
export const calculateWeekRecipeSales = ({ accountId, weekStart, weekEnd, categories, recipe }) => {
  const filters = {categories, ids: [recipe.id]}
  const weekSales = getSalesForPeriod(accountId, moment.utc(weekStart).toDate(), moment.utc(weekEnd).toDate(), false, filters);

  const weekSalesTotal = _.sumBy(weekSales, 'totalgross');
  const weekSalesNet = _.sumBy(weekSales, 'totalnet');
  const weekSalesCost = _.sumBy(weekSales, 'totalcost');
  const weekSalesQty = _.sumBy(weekSales, 'qty');
  const weekSalesAvgMargin = _.sumBy(weekSales, 'avgmargin');

  return {
    recipe,
    weekSalesTotal,
    weekSalesNet,
    weekSalesCost,
    weekSalesQty,
    weekSalesAvgMargin,
  }
}

export const calculateDailySales = (accountId, weekStart, weekEnd, filters) => {
  const daySales = {};

  let currentDay = moment.utc(weekStart);
  const endDay = moment.utc(weekEnd);

  while (currentDay.isSameOrBefore(endDay)) {
    const startOfDay = currentDay.clone().startOf('day').toDate();
    const endOfDay = currentDay.clone().endOf('day').toDate();
    const dateKey = currentDay.format('YYYY-MM-DD');

    //Maybe needs to use true here if users doesn't have daily sales
    const salesForDay = getSalesForPeriod(accountId, startOfDay, endOfDay, false, filters);

    const daySalesTotal = _.sumBy(salesForDay, 'totalgross');
    const daySalesNet = _.sumBy(salesForDay, 'totalnet');
    const daySalesCost = _.sumBy(salesForDay, 'totalcost');
    const daySalesQty = _.sumBy(salesForDay, 'totalqty');
    const expectedDayGP = calcPercentage(daySalesNet - daySalesCost, daySalesNet, 1);

    daySales[dateKey] = {
      daySalesTotal,
      daySalesNet,
      daySalesCost,
      daySalesQty,
      expectedDayGP,
    };

    currentDay.add(1, 'day');
  }

  return daySales;
};

export const calculateReportsWeekData = ({ accountId, weekStart, weekEnd, prevWeekStart, prevWeekEnd, filters }) => {
  const weekSales = getSalesForPeriod(accountId, moment.utc(weekStart).toDate(), moment.utc(weekEnd).toDate(), false, filters);
  //console.log(weekSales, 'weekSales')
  const prevWeekSales = prevWeekStart && prevWeekEnd ? getSalesForPeriod(accountId, moment.utc(prevWeekStart).toDate(), moment.utc(prevWeekEnd).toDate(), false, filters) : null;

  const weekSalesTotal = _.sumBy(weekSales, 'totalgross');
  const weekSalesNet = _.sumBy(weekSales, 'totalnet');
  const weekSalesCost = _.sumBy(weekSales, 'totalcost');
  const weekSalesQty = _.sumBy(weekSales, 'totalqty');
  const expectedGP = calcPercentage(weekSalesNet - weekSalesCost, weekSalesNet, 1);

  const prevWeekSalesNet = prevWeekSales ? _.sumBy(prevWeekSales, 'totalnet') : null;
  const prevWeekSalesCost = prevWeekSales ? _.sumBy(prevWeekSales, 'totalcost'): null;
  const prevWeekSalesTotal = prevWeekSales ? _.sumBy(prevWeekSales, 'totalgross'): null;
  const prevWeekGP = prevWeekSales ? calcPercentage(prevWeekSalesNet - prevWeekSalesCost, prevWeekSalesNet, 1): null;

  return {
    weekSales,
    weekSalesTotal,
    weekSalesNet,
    weekSalesCost,
    weekSalesQty,
    expectedGP,
    prevWeekGP,
    prevWeekSalesNet,
    prevWeekSalesTotal,
    prevWeekSales
  }
}

// PROCUREMENT
const filterOrders = (orders, accounts, filters = {}) => {
  return orders.filters((order) => {
    let allowed = true;
    if (filters.suppliers?.length > 0) {
      allowed = false;
      const orderSupplier = accounts.supplierMap[order.supplierId];
      if (filters.suppliers.indexOf(orderSupplier?.name) > -1) {
        allowed = true;
      }
    }
    if (filters.ingredientCategories?.length > 0) {
      allowed = false;
      const orderItemCatIndex = order.items.findIndex(i => {
        const ing = accounts.ingredients.find(ing => ing.id === i.ingredientId);
        return filters.ingredientCategories.indexOf(ing.category) > -1;
      });
      if (orderItemCatIndex > -1) {
        allowed = true;
      }
    }
    return allowed;
  });
}

export const buildReceiptsData = ({
  accountId,
  weekStart,
  weekEnd,
  area
}) => {
  const state = store.getState();
  if (!area.has_deliverect) return {};

  const channels = area.deliverectLocationChannels
  weekStart = moment.utc(weekStart);
  weekEnd = moment.utc(weekEnd);

  const receipts = state.receipts[accountId].filter(receipt => {
    const date = moment.utc(receipt.date, "YYYYMMDD");
    return weekStart.isSameOrBefore(date, 'date') && weekEnd.isSameOrAfter(date, 'date');
  });
  return {receipts, channels};
}

// Function to build order reports for a given week.
// This function calculates various metrics related to orders, stocktake, wastage, transfers, and procurement for the specified week.
export const buildOrderReportsWeekData = ({ accountId, weekStart, weekEnd, prevWeekStart, prevWeekEnd }) => {
  const state = store.getState();

  //console.log(weekStart, weekEnd)
  // Calculate the theoretical stock data points for the week (expected stock based on sales, procurement, etc.)
  const theoreticalStock = calculcateWeekTheoreticalStock(moment.utc(weekStart).toDate(), moment.utc(weekEnd).toDate(), accountId);

  // Find the next stocktake that occurs after the weekEnd, if available.
  // Extract the date of the next closing stocktake, if a stocktake is found.
  // Then, calculate the stocktake value only if the stocktake happens on the same day as the weekEnd or 
  // one day after the weekEnd. If it occurs later, no stocktake cost is calculated.
  // IMP: Verify if it's necessary to calculate the stocktake cost if the stocktake occurs more than a day after the weekEnd.
  const closingStocktake = findNextStocktake(state, accountId, moment.utc(weekEnd).toDate());
  const closingStocktakeDate = closingStocktake ? moment.utc(getStocktakeDate(closingStocktake)) : null;
  const closingStocktakeCosts = closingStocktake && moment.utc(weekEnd).add(1,'day').isSameOrAfter(closingStocktakeDate, 'date') ? calcStocktakeCost(closingStocktake) : 0;

  // Fetch the orders for the week that are in specific statuses (RECEIVED, SENT, FINALISED, MATCHED)
  // Calculate the average cost per order for the week
  // We pull weeoOrdersCost from theroreticalStock
  const weekOrders = getOrdersForPeriod(accountId, moment.utc(weekStart).toDate(), moment.utc(weekEnd).toDate()).filter(o => [orderStatuses.RECEIVED, orderStatuses.SENT, orderStatuses.FINALISED, orderStatuses.MATCHED].includes(o.status));
  const weekOrdersCost = calcOrdersTotal(weekOrders);
  const weekOrdersAverage = weekOrders.length > 0 ? weekOrdersCost / weekOrders.length : 0;

  // Count the number of open purchase orders for the current week
  const openPOCount = (_.filter(weekOrders, (order) => (order.status === orderStatuses.SENT))).length;
  const approvedPOCount = (_.filter(weekOrders, (order) => (order.status === orderStatuses.RECEIVED))).length;

  const weekWastageCost = calculcateWeekWastageCost(state, accountId, weekStart, weekEnd);
  const weekReportIncidentalsCost = selectReportIncidentals({state, accountId, weekStart: moment.utc(weekStart)});
  const weekTransfersCost = periodTransferCosts(accountId, moment.utc(weekStart).toDate(), moment.utc(weekEnd).toDate());
  const weekProcurementTotal = getProcurementTotal(weekOrders, weekTransfersCost, weekReportIncidentalsCost);

  // Same for prev week
  const prevWeekOrders = prevWeekStart && prevWeekEnd ?
    getOrdersForPeriod(accountId, moment.utc(prevWeekStart).toDate(), moment.utc(prevWeekEnd).toDate()).filter(o => [orderStatuses.RECEIVED, orderStatuses.SENT].includes(o.status)) :
    null;
  const prevWeekOrdersCost = prevWeekOrders ? calcOrdersTotal(prevWeekOrders) : null;
  const prevWeekOrdersAverage = prevWeekOrders?.length > 0 ? prevWeekOrdersCost / prevWeekOrders.length : 0;

  const prevWeekWastageCost = calculcateWeekWastageCost(state, accountId, prevWeekStart, prevWeekEnd);
  const prevWeekTransfersCost = prevWeekStart && prevWeekEnd ? periodTransferCosts(accountId, moment.utc(prevWeekStart).toDate(), moment.utc(prevWeekEnd).toDate()) : null;
  const prevReportIncidentalsCost = selectReportIncidentals({state, accountId, prevWeekStart: moment.utc(prevWeekStart)});
  const prevProcurementTotal = prevWeekOrders ? getProcurementTotal(prevWeekOrders, prevWeekTransfersCost, prevReportIncidentalsCost) : null;

  return {
    theoreticalStock,

    closingStocktake,
    closingStocktakeDate,
    closingStocktakeCosts,

    weekOrders,
    weekOrdersCost,
    weekOrdersAverage,
    openPOCount,
    approvedPOCount,
    
    weekWastageCost,
    weekReportIncidentalsCost,
    weekTransfersCost,
    weekProcurementTotal,

    prevWeekOrders,
    prevWeekOrdersCost,
    prevWeekOrdersAverage,

    prevWeekTransfersCost,
    prevReportIncidentalsCost,
    prevWeekWastageCost,
    prevProcurementTotal,
  }
}

const initReportOptions = {
  sales: true,
  purchases: true,
  receipts: false
}

export const buildAccountsData = ({accounts, options = initReportOptions, ...rest}) => {
  // Check for filterProps directly in the accounts object and extract filters
  const { filterProps = { filters: [] } } = accounts;
  const { filters } = filterProps;

  // Continue with your logic
  const { areas } = accounts;
  if (areas) {
    const result = areas.reduce((acc, area) => {
      const props = {accountId: area.id, filters, area, ...rest}
      const data = options.sales ? calculateReportsWeekData(props) : {};
      const daySales = options.sales ? calculateDailySales(props.accountId, props.weekStart, props.weekEnd, props.filters) : {};
      const purchases = options.purchases ? buildOrderReportsWeekData(props) : {};
      const receipts = options.receipts ? buildReceiptsData(props) : {}
      acc[area.id] = {...data, ...daySales, ...purchases, ...receipts, area};
      return acc;
    }, {})
    return result;
  }
  return {};
}

export const buildAggregatedSalesData = (data) => {
  let gross = 0, net = 0, grossLw = 0, netLw = 0, qty = 0;
  let dailySales = {};

  for (const areaId in data) {
    if (Object.prototype.hasOwnProperty.call(data, areaId)) {
      const areaData = data[areaId];
      gross += areaData.weekSalesTotal || 0;
      net += areaData.weekSalesNet || 0;
      grossLw += areaData.prevWeekSalesTotal || 0;
      netLw += areaData.prevWeekSalesNet || 0;
      qty += areaData.weekSalesQty || 0;

      // Aggregate daily sales from each area
      for (const date in areaData) {
        if (date.match(/^\d{4}-\d{2}-\d{2}$/)) {  // Check if the key is a date
          if (!dailySales[date]) {
            dailySales[date] = {
              daySalesCost: 0,
              daySalesNet: 0,
              daySalesQty: 0,
              daySalesTotal: 0,
              expectedDayGP: 0
            };
          }
          const dayData = areaData[date];
          dailySales[date].daySalesCost += dayData.daySalesCost || 0;
          dailySales[date].daySalesNet += dayData.daySalesNet || 0;
          dailySales[date].daySalesQty += dayData.daySalesQty || 0;
          dailySales[date].daySalesTotal += dayData.daySalesTotal || 0;
        }
      }
    }
  }

  // Calculate expectedDayGP for aggregated daily sales
  for (const date in dailySales) {
    const day = dailySales[date];
    day.expectedDayGP = day.daySalesNet > 0 
      ? ((day.daySalesNet - day.daySalesCost) / day.daySalesNet * 100).toFixed(1)
      : "0.0";
  }

  return { gross, net, grossLw, netLw, qty, dailySales };
};

export const buildAggregatedOrdersData = (data) => {
  const returnData = {
    openPOCount: 0,
    approvedPOCount: 0,
    weekOrdersCost: 0,
    weekOrdersAverage: 0,
    prevWeekOrdersCost: 0,
    prevWeekOrdersAverage: 0,
    totalPOCount: 0,
    prevWeekTotalPOCount: 0,
    prevProcurementTotal: 0,
    weekProcurementTotal: 0
  }
  for (const id in data) {
    const val = data[id];
    for (const key in returnData) {
      if (val[key]) {
        returnData[key] += val[key];
      }
    }
    if (val.weekOrders) {
      returnData.totalPOCount += val.weekOrders.length;
    }
    if (val.prevWeekOrders) {
      returnData.prevWeekTotalPOCount += val.prevWeekOrders.length;
    }
  }
  returnData.weekOrdersAverage = returnData.totalPOCount > 0 ? returnData.weekOrdersCost / returnData.totalPOCount : 0;
  returnData.prevWeekOrdersAverage = returnData.prevWeekTotalPOCount > 0 ? returnData.prevWeekOrdersCost / returnData.prevWeekTotalPOCount : 0;
  return returnData;
}

const buildReceipts = (receipts, data, accountId) => {
  return receipts.map(receipt => ({
    ...receipt,
    location: data.area.name,
    channel: data.channels.find(c => c.id === receipt.channelLink)?.name,
    accountId
  }))
}

export const buildAggregatedReceipts = (data) => {
  let receipts = [];
  for (const id in data) {
    const val = data[id];
    if (val.receipts) {
      receipts = receipts.concat(buildReceipts(val.receipts, val, id));
    }
  }
  return receipts;
}

// ANALYTICS/COSTS/INGREDIENTS

//VOLUME
// Calculates the order amount for a specific item in an order, matching by option ID.
const orderOptItem = (opt, order, ing) => {
  const item = order.items.find(i => i.id === opt.id);
  return item ? ingOrderAmount(item) : 0;
}

// Aggregates order data for a specified period based on start and end dates.
const buildPeriodOrders = ( orders, start, end, opt, ing, week = false ) => {
  const periodOrders = orders.filter(o => {
    const orderDate = moment.utc(o.date, 'YYYY-MM-DD');
    const isWithinRange = orderDate.isBetween(moment.utc(start), moment.utc(end), 'day', '[]');
    return isWithinRange;
  });
  const sum = periodOrders.reduce((total, order) => orderOptItem(opt, order, ing) + total,  0);
  const displaySum = denormalizeIngredientQuantity({ recipeunit: ing.recipeunit, amount: sum });
  return displaySum;
}

export const buildWeekOrders = ({ orders, weekStart, weekEnd, opt, ing }) => buildPeriodOrders(orders, weekStart, weekEnd, opt, ing, true);

export const buildPrevWeekorders = ({ orders, prevWeekStart, prevWeekEnd, opt, ing }) => buildPeriodOrders(orders, prevWeekStart, prevWeekEnd, opt, ing, true);

const buildDatePeriodOrders = (orders, weekStart, subtract, opt, ing, log = false) => {
  const startDate = moment.utc(weekStart).subtract(subtract.num, subtract.unit);
  return buildPeriodOrders(orders, startDate, weekStart, opt, ing, log);
}

export const buildMonthOrders = ({ orders, weekStart, opt, ing }) => buildDatePeriodOrders(orders, weekStart, { num: 5, unit: 'weeks'}, opt, ing);
export const buildThreeMonthOrders = ({ orders, weekStart, opt, ing }) => buildDatePeriodOrders(orders, weekStart, { num: 13, unit: 'weeks'}, opt, ing);
export const buildYearOrders = ({ orders, weekStart, opt, ing }) => buildDatePeriodOrders(orders, weekStart, { num: 53, unit: 'weeks'}, opt, ing);

//Aggregates totals from different time periods for a particular procurement item.
const calcOptTotal = (item, total) => {
  for (const prop in total) {
    if (item[prop]) {
      total[prop] += item[prop];
    }
  }
  return total;
}

//Formats all numerical values in a total object to two decimal places or sets them to '0' if they are zero.
const setFinalZero = (total) => {
  for (const prop in totalInit) {
    if (total[prop] === 0) {
      total[prop] = '0'
    }
    else if (total[prop]) {
      total[prop] = total[prop].toFixed(2)
    }
  }
  return total;
}

//Initial values for calculating totals, ensuring every category starts from zero.
const totalInit = {
  weekOrders: 0,
  prevWeekOrders: 0,
  monthOrders: 0,
  threeMonthOrders: 0,
  yearOrders: 0
}

//Aggregates procurement data across various options for a single ingredient and applies formatting to the aggregated values.
export const buildIngOptSum = (ing, uom, optProcurments) => {
  const sum = optProcurments.reduce((total, item) => calcOptTotal(item, total), { ...totalInit });
  return { ...ing, uom, ...setFinalZero(sum), opts: optProcurments.map(o => setFinalZero(o)) };
}

//PRICING
// Filters the list of all options to match those that belong to a specific ingredient.
export const buildIngOptions = (ing, optionList) => optionList.filter((opt) => ing.id === opt.ingredientId);

// Converts the unit of measure (UOM) from 'unit' to a numerical scale for calculations.
export const uomUnits = (uom) => {
  return uom === 'unit' ? 1 : 1000;
}

// Calculates the final unit price of an ingredient option based on its UOM.
export const finalUnitPrice = (opt, price = null) => {
  //console.log(opt, 'OPTTT')
  price = price || opt.unitprice;
  price = price / parseFloat(opt.gramperportion) / parseFloat(opt.numperportion);
  return price * uomUnits(opt.base_uom);
}

// Retrieves the price from an order for a specific ingredient option, if available.
export const orderIngPrice = (opt, order) => {
  const item = order?.items.find(i => i.ingredientId === opt.ingredientId);
  return item ? parseFloat(item.finalprice) : 0;
}

// Constructs an object representing the price, unit price, and date for an ingredient option.
export const createOptPrice = (opt, order) => ({
  price: orderIngPrice(opt, order) || opt.unitprice,
  unitprice: finalUnitPrice(opt,  orderIngPrice(opt, order)),
  date: moment.utc(getOrderDate(order))
});

// Determines the current price based on the most recent order or the static unit price if no orders are found.
export const buildOptionCurrentPrice = ({ optOrders, weekEnd, opt }) => {
  if (!optOrders || !optOrders[0]) {
    return {
      price: opt.unitprice,
      unitprice: finalUnitPrice(opt),
      date: moment.utc(weekEnd)
    };
  }
  // First option ordered, most recent order
  return createOptPrice(opt, optOrders[0]);
};

// Calculates the product of unit price and ordered quantity for an ingredient option.
// Utility to calculate price * volume for an ingredient option
export const calculatePriceVolume = (opt, ing) => {
  const unitPrice = parseFloat(opt.unitPrice);
  const quantity = parseFloat(ing.quantityOrdered);  // Make sure quantityOrdered is being calculated somewhere
  return unitPrice * quantity;
}

// Calculates the total spent on an ingredient option across multiple orders within a specified date range.
export const calculateTotalSpentOnIngredient = (opt, optOrders) => {
  // Calculate total spent on filtered orders.
  return optOrders.reduce((total, order) => {
    const item = order.items.find(i => i.id === opt.id);
    if (item) {
      const unitPrice = orderIngPrice(opt, order); // Reuse existing utility to get price.
      const quantity = item.quantity ? parseFloat(item.quantity) : 0;
      //console.log(`Processing item: ${item.ingredientId}, Unit Price: ${unitPrice}, Quantity: ${quantity}`); // Log each item processed
      total += unitPrice * quantity;
      //console.log("No matching item found in order: ", order.id);
    }
    return total;
  }, 0);
};

// Calculates the total quantity and adjusted quantity of an ingredient option across multiple orders.
export const calculateTotalQuantity = (opt, optOrders) => {
  const result = optOrders.reduce((acc, order) => {
    // Find the item in the order that matches the ingredient option
    const item = order.items.find(i => i.id === opt.id);
    if (item) {
      //console.log(item, item.name)
      const quantity = parseFloat(item.quantity);
      const adjustedQuantity = quantity * parseFloat(item.gramperportion) * parseFloat(item.numperportion) / uomUnits(item.base_uom); // Adjust based on unit of measure (UOM)
      
      // Add to total quantity and adjusted total
      acc.quantity += quantity;
      acc.adjustedQuantity += adjustedQuantity;

      // Add individual quantities for later use if needed
      acc.quantities.push({
        orderId: order.id,
        quantity: quantity,
        adjustedQuantity: adjustedQuantity
      });
    }
    return acc;
  }, { quantity: 0, adjustedQuantity: 0, quantities: [] }); // Initial accumulator with total and adjusted totals

  return result; // Return both total quantity and adjusted quantity
};

// Calculate the total order value across all ingredients within the specified date range.
export const calculateTotalOrderValue = (orders, weekStart, weekEnd) => {
  return orders.reduce((totalValue, order) => {
    if (moment.utc(getOrderDate(order)).isBetween(weekStart, weekEnd, undefined, '[]')) {
      order.items.forEach(item => {
        const unitPrice = parseFloat(item.finalprice || item.unitprice); // Use final price if available, else unit price
        const quantity = parseFloat(item.quantity);
        totalValue += unitPrice * quantity;
      });
    }
    return totalValue;
  }, 0);
};

// A placeholder for when no valid pricing data exists.
const createNullPrice = { unitprice: null, date: null }

// Finds a change in price from previous orders and returns the newer price.
export const buildOptionPrevPrice = ({ optPrevWeekOrders, opt, uom }) => {
  const initPrice = optPrevWeekOrders[0] ? orderIngPrice(opt, optPrevWeekOrders[0]) : parseFloat(opt.unitprice);
  const nextIndex = optPrevWeekOrders.findIndex(o => orderIngPrice(opt, o) !== initPrice);
  if (nextIndex > -1) return createOptPrice(opt, optPrevWeekOrders[nextIndex], uom)

  return createNullPrice;
}

// Finds the last week's price based on the previous week's orders
export const buildOptionPrevWeekPrice = ({ optPrevWeekOrders, opt, uom }) => {
  // Check if there are previous week's orders
  if (optPrevWeekOrders && optPrevWeekOrders.length > 0) {
    // Use the first order from last week (most recent one)
    return createOptPrice(opt, optPrevWeekOrders[0], uom);
  }
  
  // If no previous orders, return the default price
  return createNullPrice;
};