import { actionTypes as commonActionTypes, actions as commonActions } from './common';
import { actionTypes as accountsActionTypes, actions as accountsActions } from './accounts';
import { actionTypes as profileActionTypes, actions as profileActions } from './profile';
import { actionTypes as accountUsersActionTypes, actions as accountUsersActions } from './accountUsers';
import { actionTypes as ingredientActionTypes, actions as ingredientActions } from './ingredients';
import { actionTypes as ingredientCategoriesActionTypes, actions as ingredientCategoriesActions } from './ingredientCategories';
import { actionTypes as supplierCategoriesActionTypes, actions as supplierCategoriesActions } from './supplierCategories';
import { actionTypes as recipeActionTypes, actions as recipeActions } from './recipes';
import { actionTypes as modifierCategoriesActionTypes, actions as modifierCategoriesActions } from './modifierCategories';
import { actionTypes as departmentsActionTypes, actions as departmentsActions } from './departments';
import { actionTypes as areasActionTypes, actions as areasActions } from './areas';
import { actionTypes as modifierActionTypes, actions as modifierActions } from './modifiers';
import { actionTypes as recipeCategoriesActionTypes, actions as recipeCategoriesActions } from './recipeCategories';
import { actionTypes as menuActionTypes, actions as menuActions } from './menus';
import { actionTypes as menuCategoriesActionTypes, actions as menuCategoriesActions } from './menuCategories';
import { actionTypes as deliveryCompaniesActionTypes, actions as deliveryCompaniesActions } from './deliveryCompanies';
import { actionTypes as miseEnPlacesActionTypes, actions as miseEnPlacesActions } from './miseEnPlaces';
import { actionTypes as suppliersActionTypes, actions as suppliersActions } from './suppliers';
import { actionTypes as sectionsActionTypes, actions as sectionsActions } from './sections';
import { actionTypes as uploadsActionTypes, actions as uploadsActions } from './uploads';
import { actionTypes as messagesActionTypes, actions as messagesActions } from './messages';
import { actionTypes as orderAttachmentActionTypes, actions as orderAttachmentActions } from './orderAttachments';
import { actionTypes as ordersActionTypes, actions as ordersActions } from './orders';
import { actionTypes as stockTakesActionTypes, actions as stockTakesActions } from './stockTake';
import { actionTypes as wastagesActionTypes, actions as wastagesActions } from './wastages';
import { actionTypes as transfersActionTypes, actions as transfersActions } from './transfers';
import { actionTypes as allergenActionTypes, actions as allergenActions } from './allergens';
import { actionTypes as taskActionTypes, actions as taskActions } from './tasks';
import { actionTypes as taskCommentActionTypes, actions as taskCommentActions } from './tasksComments';
import { actionTypes as taskHistoryActionTypes, actions as taskHistoryActions } from './tasksHistory';
import { actionTypes as prepTaskActionTypes, actions as prepTaskActions } from './prepTasks';
import { actionTypes as salesActionTypes, actions as salesActions } from './sales';
import { actionTypes as receiptsActionTypes, actions as receiptsActions } from './receipts';
import { actionTypes as foodRecordActionTypes, actions as foodRecordActions } from './foodRecords';
import { actionTypes as orderRecordActionTypes, actions as orderRecordActions } from './orderRecords';
import { actionTypes as reportsIncidentalsActionTypes, actions as reportsIncidentalsActions } from './reportsIncidentals';
import { actionTypes as financialsActionTypes, actions as financialsActions } from './financials';
import { actionTypes as budgetsActionTypes, actions as budgetsActions } from './budgets';
import { actionTypes as invoiceUploadActionTypes, actions as invoiceUploadActions } from './invoiceUploads';
import { actionTypes as messagesSeenTimesActionTypes, actions as messagesSeenTimesActions } from './messagesSeenTimes';
import { actionTypes as reportsActionTypes, actions as reportsActions } from './reports';
import * as reportsComments from './reportsComments';
import * as supplierOptions from './supplierOptions';
import * as formActions from './forms';

export const actionTypes = {
  ...commonActionTypes,
  accounts: accountsActionTypes,
  accountUsers: accountUsersActionTypes,
  profile: profileActionTypes,
  ingredients: ingredientActionTypes,
  ingredientCategories: ingredientCategoriesActionTypes,
  supplierCategories: supplierCategoriesActionTypes,
  recipes: recipeActionTypes,
  recipeCategories: recipeCategoriesActionTypes,
  modifiers: modifierActionTypes,
  modifierCategories: modifierCategoriesActionTypes,
  departments: departmentsActionTypes,
  areas: areasActionTypes,
  menus: menuActionTypes,
  menuCategories: menuCategoriesActionTypes,
  deliveryCompanies: deliveryCompaniesActionTypes,
  miseEnPlaces: miseEnPlacesActionTypes,
  suppliers: suppliersActionTypes,
  supplierOptions: supplierOptions.actionTypes,
  sections: sectionsActionTypes,
  uploads: uploadsActionTypes,
  messages: messagesActionTypes,
  messagesSeenTimes: messagesSeenTimesActionTypes,
  orderAttachments: orderAttachmentActionTypes,
  orders: ordersActionTypes,
  stockTake: stockTakesActionTypes,
  wastages: wastagesActionTypes,
  transfers: transfersActionTypes,
  allergens: allergenActionTypes,
  tasks: taskActionTypes,
  tasksComments: taskCommentActionTypes,
  tasksHistory: taskHistoryActionTypes,
  prepTasks: prepTaskActionTypes,
  sales: salesActionTypes,
  receipts: receiptsActionTypes,
  foodRecords: foodRecordActionTypes,
  orderRecords: orderRecordActionTypes,
  invoiceUploads: invoiceUploadActionTypes,
  reportsComments: reportsComments.actionTypes,
  reportsIncidentals: reportsIncidentalsActionTypes,
  forms: formActions.actionTypes,
  financials: financialsActionTypes,
  budgets: budgetsActionTypes,
  reports: reportsActionTypes,
};

export const actions = {
  ...commonActions,
  accounts: accountsActions,
  accountUsers: accountUsersActions,
  profile: profileActions,
  ingredients: ingredientActions,
  ingredientCategories: ingredientCategoriesActions,
  supplierCategories: supplierCategoriesActions,
  recipes: recipeActions,
  recipeCategories: recipeCategoriesActions,
  modifiers: modifierActions,
  modifierCategories: modifierCategoriesActions,
  departments: departmentsActions,
  areas: areasActions,
  menus: menuActions,
  menuCategories: menuCategoriesActions,
  deliveryCompanies: deliveryCompaniesActions,
  miseEnPlaces: miseEnPlacesActions,
  suppliers: suppliersActions,
  supplierOptions: supplierOptions.actions,
  sections: sectionsActions,
  uploads: uploadsActions,
  messages: messagesActions,
  messagesSeenTimes: messagesSeenTimesActions,
  orderAttachments: orderAttachmentActions,
  orders: ordersActions,
  stockTake: stockTakesActions,
  wastages: wastagesActions,
  transfers: transfersActions,
  allergens: allergenActions,
  tasks: taskActions,
  tasksComments: taskCommentActions,
  tasksHistory: taskHistoryActions,
  prepTasks: prepTaskActions,
  sales: salesActions,
  receipts: receiptsActions,
  foodRecords: foodRecordActions,
  orderRecords: orderRecordActions,
  invoiceUploads: invoiceUploadActions,
  reportsComments: reportsComments.actions,
  reportsIncidentals: reportsIncidentalsActions,
  forms: formActions.actions,
  financials: financialsActions,
  budgets: budgetsActions,
  reports: reportsActions,
};
