//React
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';

//Components
import Page from '../../components/Page/Page';
import Dashboard from './Dashboard';
import Financials from './Financials';
import SalesTabs from './SalesTabs';
import CostsTabs from './CostsTabs';
import Receipts from './components/Receipts';
import LoadingIndicator from '../../components/IsLoading/IsLoading';

//Files
import { useDate } from '../../context/DateContext';
import useReportWeeks from '../../hooks/useReportWeeks';
import { getSalesData, getCostsData, getFinancialsData } from '../../utils/reports';
import { findFinancePeriod } from '../../utils/financials'
import { actions } from '../../store/actions';

const unsetHierarchicalFilters = (filterName, prevFilters) => {
  // unset filters hierarchically
  if (filterName === 'locations') {
    prevFilters['departments'] = [];
    prevFilters['areas'] = [];
    prevFilters['categories'] = [];
  }
  if (filterName === 'areas') {
    prevFilters['departments'] = [];
    prevFilters['categories'] = [];
  }
  if (filterName === 'departments') {
    prevFilters['categories'] = [];
  }
  return {...prevFilters};
}

const initFilters = {
  locations: [],
  areas: [],
  departments: [],
  categories: [],
  suppliers: [],
  ingredientCategories: []
}

const Reports = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentAccount = useSelector((state) => state.currentAccount);
  const accountId = currentAccount;
  const account = useSelector(state => state.accounts[accountId]);
  const accessibleAccounts = useSelector((state) => state.accessibleAccounts);

  const reportLoading = useSelector((state) => state.reportLoading);
  const hasRequestedReports = useSelector((state) => state.reports.hasRequestedReports);
  const reportsDataLoaded = useSelector((state) => state.reports.reportsDataLoaded);
  //console.log(reportLoading, hasRequestedReports, reportsDataLoaded)

  const [filters, setFilters] = useState(initFilters);
  const weekProps = useReportWeeks({});

  const accountsData = useSelector(state => state.reports.accountsData);
  const salesData = useSelector(state => state.reports.salesData);
  const costsData = useSelector(state => state.reports.costsData);
  const financialsData = useSelector(state => state.reports.financialsData);

  // Modify your first useEffect to handle redirection properly
  useEffect(() => {
    if (
      !hasRequestedReports
    ) {
      //console.log('Redirecting to /products');
      navigate('/products');
    }
  }, [hasRequestedReports, navigate]);

  // Step 1: Only dispatch once per session
  useEffect(() => {
    if (accountId && accessibleAccounts && hasRequestedReports && !reportsDataLoaded) {
      //console.log("Dispatching accountLoadReport");
      dispatch(actions.accounts.accountLoadRequest(
        accountId, 
        accessibleAccounts, 
        false, // Working account is not used for reports
        true,
      ));
    }
  }, [accountId, accessibleAccounts, hasRequestedReports, reportsDataLoaded, dispatch, salesData, costsData, financialsData]);

  // Step 2: Dispatch once the data has fully loaded
  useEffect(() => {
    if (!reportLoading && hasRequestedReports && !reportsDataLoaded) {
      //console.log("Report data fully loaded");
      dispatch(actions.reports.reportsDataLoaded());
    }
  }, [reportLoading, hasRequestedReports, reportsDataLoaded, dispatch]);


  const updateFilters = useCallback((filterName, value) => {
    setFilters(prevFilters => {
      // Shallow copy of previous filters
      let updatedFilters = { ...prevFilters };
      const currentValues = updatedFilters[filterName] || [];

      const index = currentValues.indexOf(value);
      if (index === -1) {
        updatedFilters[filterName] = [...currentValues, value];
      } else {
        updatedFilters[filterName] = currentValues.filter(item => item !== value);
      }

      // Ensure to call unsetHierarchicalFilters properly
      return unsetHierarchicalFilters(filterName, updatedFilters);
    });
  }, []);

  const filterProps = useMemo(() => ({
    filters,
    updateFilters
  }), [filters, updateFilters]);

  const weekdates = useMemo(() => {
    return weekProps.weekInfo;
  }, [weekProps.weekInfo]);

  //Financials
  const { currentYear: currentYearDateContext } = useDate();
  const [currentYear, setCurrentYear] = useState(currentYearDateContext);
  const currentYearProps = { currentYear, setCurrentYear }
  
  const financials = useSelector((state) => _.sortBy(state.financials[accountsData.accountId], 'year'));
  const financial = financials.find(f => parseInt(f.year) === parseInt(currentYear));
  const financialsProps = { financials, financial };

  const [currentPeriod, setCurrentPeriod] = useState(findFinancePeriod(financial)?.periodKey);
  const currentPeriodProps = { currentPeriod, setCurrentPeriod };

  // Process data once account data is loaded
  useEffect(() => {
    if (hasRequestedReports && reportsDataLoaded) {
      //console.log("Processing data with loaded account data");
      
      // Calculate sales data only if it’s not already set
      const aggregatedSalesData = getSalesData(accountId, accountsData, weekdates, filterProps);
      //console.log(aggregatedSalesData);
      dispatch(actions.reports.salesDataSet(aggregatedSalesData));
    
  
      // Calculate costs data only if it’s not already set
      const aggregatedCostsData = getCostsData(accountId, accountsData, weekdates, filterProps);
      //console.log(aggregatedCostsData);
      dispatch(actions.reports.costsDataSet(aggregatedCostsData));
  
      // Calculate financials data only if it’s not already set
      const aggregatedFinancialsData = getFinancialsData(financial, accountsData, currentPeriod, currentYear, filterProps);
      //console.log(aggregatedFinancialsData);
      dispatch(actions.reports.financialsDataSet(aggregatedFinancialsData));
    }
  }, [accountId, accountsData, reportsDataLoaded, weekdates, financial, currentPeriod, currentYear, filters, filterProps, dispatch]);

  // Check if the current account and component are valid before rendering
  if (!currentAccount) {
    return null;
  }

  return (
    <Page title="Reports" flex="1 0 auto">
      {reportLoading && !reportsDataLoaded ? (
        <LoadingIndicator message={"We are loading your reports..."} />
      ) : (
        <Routes>
          <Route path="dashboard/*" element={<Dashboard account={account} accountId={accountId} accounts={accountsData} weekProps={weekProps} filterProps={filterProps} />} />
          <Route path="financials/*" element={<Financials account={account} accounts={accountsData} filterProps={{ filters, updateFilters }} financialsProps={financialsProps} currentYearProps={currentYearProps} currentPeriodProps={currentPeriodProps} />} />
          <Route path="sales/*" element={<SalesTabs account={account} accountId={accountId} accounts={accountsData} weekProps={weekProps} filterProps={filterProps} />} />
          <Route path="costs/*" element={<CostsTabs account={account} accountId={accountId} accounts={accountsData} weekProps={weekProps} filterProps={filterProps} />} />
          {account?.has_deliverect && (
            <Route path="orders/*" element={<Receipts account={account} accounts={accountsData} weekProps={weekProps} filterProps={{ filters, updateFilters }} />} />
          )}
        </Routes>
      )}
    </Page>
  );
};

Reports.propTypes = {
  accountId: PropTypes.string
};

export default Reports;
