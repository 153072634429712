//React
import React, { useEffect, useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';

//Libraries
import PropTypes from 'prop-types';
import { majorScale, Pane, useTheme } from 'evergreen-ui';

//Files
import { actions } from '../../store/actions';
import { APP_TITLE } from '../../utils/constants';


const Page = ({ title, children, containerProps, ...otherProps }) => {
  const dispatch = useCallback(useDispatch(), []);
  const theme = useTheme();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const accountName = useSelector(state => {
    const activeAccount = state.currentAccount;
    return activeAccount && activeAccount !== 'pending' && state.accounts[activeAccount]
      ? state.accounts[activeAccount].name
      : '';
  });

  //Accessing working account details
  const activeAccountDetails = useSelector(state => {
    const workingAccountId = state.workingAccount;
    if (!workingAccountId) {
      return null
    }
    return workingAccountId && state.accounts[workingAccountId]
      ? state.accounts[workingAccountId]
      : {};
  });
  
  //This is used to decide the top name - could be changed maybe
  useEffect(() => {
    const newTitle = !activeAccountDetails ? `${accountName}` : `${activeAccountDetails.name || ''}`;
    dispatch(actions.pageTitleSet(newTitle));
  }, [accountName, activeAccountDetails, isMobile, dispatch]);

  // Conditional padding based on whether it's a mobile view
  const paddingValue = isMobile ? majorScale(1) : majorScale(2);

  return (
    <Pane
        paddingX={paddingValue}
        //paddingY={majorScale(2)}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        {...otherProps}
        {...containerProps}
        backgroundColor={theme.colors.white}
    >
      {children}
    </Pane>
  );
};

Page.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  containerProps: PropTypes.object
};

export default Page;
