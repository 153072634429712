import { all, takeEvery, select, put, call, fork } from 'redux-saga/effects';
import { toaster } from 'evergreen-ui';
import _ from 'lodash';
import store from '../redux';

import { messageTypes } from '../../utils/constants';
import { getAccessibleAccountIds2 } from '../../utils/accounts';
import { actionTypes, actions } from '../actions';
import { loadCollection, loadReportCollection, clearCollection, clearAccountCollections } from '../firebase/common';
import { loadAccounts, updateAccount, addAccount, clearAccount, clearAccounts } from '../firebase/accounts';
import { loadProfile, clearProfile, updateProfile, addProfile } from '../firebase/profile';
import { loadAccountUsers, clearAccountUsers, addToAccount, removeFromAccount, removeByAccountId } from '../firebase/accountUsers';

import foodRecordsSaga from './foodRecords';
import orderRecordsSaga from './orderRecords';
import invoiceUploadsSaga from './invoiceUploads';
import ingredientsSaga from './ingredients';
import ingredientCategoriesSaga from './ingredientCategories';
import supplierCategoriesSaga from './supplierCategories';
import recipesSaga from './recipes';
import recipeCategoriesSaga from './recipeCategories';
import modifiersSaga from './modifiers';
import modifierCategoriesSaga from './modifierCategories';
import departmentsSaga from './departments';
import menuCategoriesSaga from './menuCategories';
import suppliersSaga from './suppliers';
import supplierOptionsSaga from './supplierOptions';
import sectionsSaga from './sections';
import messagesSaga from './messages';
import messagesSeenTimesSaga from './messagesSeenTimes';
import miseEnPlacesSaga from './miseEnPlaces';
import stockTakeSaga from './stockTake';
import wastageSaga from './wastage';
import transferSaga from './transfer';
import ordersSaga from './orders';
import taskSaga from './tasks';
import tasksCommentsSaga from './tasksComments';
import taskHistorySaga from './tasksHistory';
import prepTaskSaga from './prepTasks';
import menusSaga from './menus';
import salesSaga from './sales';
import receiptsSaga from './receipts';
import reportsCommentsSaga from './reportsComments';
import reportsIncidentalsSaga from './reportsIncidentals';
import areasSaga from './areas';
import financialsSaga from './financials';
import budgetsSaga from './budgets';

const clearCollections = () => {
  clearAccountUsers();
  clearCollection('ingredients');
  clearCollection('ingredientCategories');
  clearCollection('supplierCategories');
  clearCollection('recipes');
  clearCollection('recipeCategories');
  clearCollection('modifiers');
  clearCollection('modifierCategories');
  clearCollection('departments');
  clearCollection('areas');
  clearCollection('financials');
  clearCollection('budgets');
  clearCollection('menus');
  clearCollection('menuCategories');
  clearCollection('deliveryCompanies');
  clearCollection('foodRecords');
  clearCollection('orderRecords');
  clearCollection('invoiceUploads');
  clearCollection('miseEnPlaces');
  clearCollection('suppliers');
  clearCollection('supplierOptions');
  clearCollection('sections');
  clearCollection('uploads');
  clearCollection('messages');
  clearCollection('messagesSeenTimes');
  clearCollection('wastages');
  clearCollection('transfers');
  clearCollection('stockTake');
  clearCollection('orderAttachments');
  clearCollection('orders');
  clearCollection('tasks');
  clearCollection('tasksComments');
  clearCollection('tasksHistory');
  clearCollection('prepTasks');
  clearCollection('sales');
  clearCollection('receipts');
  clearCollection('reportsComments');
  clearCollection('reportsIncidentals');
};

const loadCollectionsForParentsAndGroups = (accountId) => {
  // Parent Collections
  loadCollection('areas', accountId);
  loadCollection('departments', accountId);

  loadCollection('ingredients', accountId);
  loadCollection('ingredientCategories', accountId);
  loadCollection('suppliers', accountId);
  loadCollection('supplierOptions', accountId);
  loadCollection('supplierCategories', accountId);
  
  loadCollection('financials', accountId);
  loadCollection('uploads', accountId);
  //loadCollection('messages', accountId);
  //loadCollection('messagesSeenTimes', accountId);

  // Group Collections
  loadCollection('menus', accountId);
  loadCollection('menuCategories', accountId);
  loadCollection('recipes', accountId);
  loadCollection('recipeCategories', accountId);
  loadCollection('modifiers', accountId);
  loadCollection('modifierCategories', accountId);
};

const loadCollectionsForSuppliers = (accountId) => {
  // Supplier Collections
  loadCollection('suppliers', accountId);
  loadCollection('supplierOptions', accountId);
  loadCollection('supplierCategories', accountId);
  loadCollection('uploads', accountId);
};

const loadCollectionsForCurrentAccount = (accountId) => {
  // Location
  loadCollection('invoiceUploads', accountId);
  //loadCollection('messagesSeenTimes', accountId);
};

export const loadCollectionsForWorkingAccount = (accountId) => {
  // Area Operates Review
  loadCollection('budgets', accountId);
  loadCollection('sales', accountId);
  loadCollection('receipts', accountId);
  loadCollection('reportsIncidentals', accountId);
  loadCollection('reportsComments', accountId);
  loadCollection('orders', accountId);
  loadCollection('orderAttachments', accountId);
  loadCollection('stockTake', accountId);
  loadCollection('wastages', accountId);
  loadCollection('transfers', accountId);

  // Tasks and Others
  loadCollection('sections', accountId);
  loadCollection('prepTasks', accountId);
  loadCollection('deliveryCompanies', accountId);
  loadCollection('foodRecords', accountId);
  loadCollection('orderRecords', accountId);
  loadCollection('tasks', accountId);
  loadCollection('tasksComments', accountId);
  loadCollection('tasksHistory', accountId);

  //loadCollection('miseEnPlaces', accountId);
  //loadCollection('messages', accountId);
};

export const loadCollectionsForReports = (accountId) => {
  // Area Operates Review
  loadReportCollection('budgets', accountId);
  loadReportCollection('sales', accountId);
  loadReportCollection('receipts', accountId);
  loadReportCollection('reportsIncidentals', accountId);
  loadReportCollection('reportsComments', accountId);
  loadReportCollection('orders', accountId);
  loadReportCollection('orderAttachments', accountId);
  loadReportCollection('stockTake', accountId);
  loadReportCollection('wastages', accountId);
  loadReportCollection('transfers', accountId);
};

const loadingTasks = [];
const backgroundTasks = [];
const reportTasks = [];
const accountsLoading = [];

function* profileSaga() {
  // Listens for PROFILE_LOAD_REQUESTED actions and loads the user profile.
  yield takeEvery(actionTypes.profile.PROFILE_LOAD_REQUESTED, function* (action) {
    const user = yield select((state) => (state.user));
    yield call(loadProfile, user);
    //yield put(actions.appMessageSuccess('Profile loaded'));
  });

  // DEPRECATED - only use now in loadProfile to update the redux state
  /*yield takeEvery(actionTypes.profile.PROFILE_LOAD_SUCCESS, function* (action) {
    const { currentaccount, uid } = action.payload;
    console.log(`[PROFILE_LOAD_SUCCESS] Profile Loaded: ${uid}`);
    console.log(`[PROFILE_LOAD_SUCCESS] Current Account: ${currentaccount}`);
    //console.log(`[PROFILE_LOAD_SUCCESS] Working Account: ${workingAccount}`);

  });*/

  yield takeEvery(actionTypes.profile.PROFILE_CLEAR, function* () {
    // Unsubscribe from the profile listener first
    yield call(clearProfile);

    // When we clear the profile, clear the collections
    yield call(clearCollections);
    yield put(actions.applicationLoaded());
  });

  // Sets the current account in the profile.
  yield takeEvery(actionTypes.profile.PROFILE_SET_ACCOUNT, function* (action) {
    // Set the current account, and update the profile
    yield put(actions.accounts.currentAccountSet(action.accountId));
    const profile = yield select((state) => (state.profile));
    profile.currentaccount = action.accountId;
    yield call(updateProfile, profile.id, profile);
    yield put(actions.appMessageSuccess('Profile updated'));
  });

  // Updates the user profile.
  yield takeEvery(actionTypes.profile.PROFILE_UPDATE, function* (action) {
    const { id, ...profile } = action.profile;
    try {
      yield call(updateProfile, id, profile);
      yield put(actions.appMessageSuccess('Profile updated'));

    } catch (err) {
      console.error('Profile update error', err);
      yield put(actions.appMessageError('Unable to update profile'));
    }
  });

  // Adds a new profile.
  yield takeEvery(actionTypes.profile.PROFILE_ADD, function* (action) {
    try {
      yield call(addProfile, action.profileId, action.profile);
      yield put(actions.appMessageSuccess('Account Created'));
    }
    catch (err) {
      console.error('Profile add error', err);
      yield put(actions.appMessageError('Unable to add profile'));
    }
  });
}

// Responds to account load requests, fetching user's accounts from Firestore.
function* accountsSaga() {
  // Listens for a request to load all accounts associated with a user's email and calls the loadAccounts function.
  yield takeEvery(actionTypes.accounts.ACCOUNTS_LOAD_REQUESTED, function* (action) {
    //console.log('accounts load')
    //store.dispatch(actions.applicationLoading());
    try {
      // Call loadAccounts and wait for it to complete
      yield call(loadAccounts, action.userEmail, action.currentAccountId);
      //console.log('accounts load end');
      
    } catch (error) {
      console.error('Error loading accounts:', error);
      store.dispatch(actions.applicationLoaded()); // Ensure app doesn't stay in loading state
    }
  });

  yield takeEvery(actionTypes.accounts.ACCOUNTS_LOAD_SUCCESS, function* (action) {
    const { accounts, currentAccountId } = action;
    const firstLoad = yield select((state) => state.firstLoad.firstLoad);
  
    // Get accessible accounts
    const accessibleAccounts = getAccessibleAccountIds2(currentAccountId, accounts);

    // Set accessible accounts
    yield put({type: actionTypes.accounts.ACCESSIBLE_ACCOUNTS_SET, accounts: accessibleAccounts});
  
    if (!accessibleAccounts || accessibleAccounts.length === 0) {
      //console.warn('[ACCOUNTS_LOAD_SUCCESS] No accessible accounts found.');
      return;
    }
  
    // Load data for current account
    if (action.currentAccountId) {
      const workingAccount = yield select((state) => state.workingAccount);
      
      yield put(actions.accounts.accountLoadRequest(
        currentAccountId,
        accessibleAccounts,
        workingAccount
      ));
    }

    // === Fork loadAccountUsers for OWNER accounts ===
    for (const account of accessibleAccounts) {
      if (account.type === 'OWNER' && firstLoad) {
        //console.log(`[ACCOUNTS_LOAD_SUCCESS] Forking loadAccountUsers for OWNER account: ${account.id}`);
        yield fork(loadAccountUsers, account.id);
      }
    }
  });
  
  // Handles requests to clear all account information from the Redux store.
  yield takeEvery(actionTypes.accounts.ACCOUNTS_CLEAR, function* (action) {
    yield call(clearAccounts);
  });

  // This saga listens for the ACCOUNT_LOAD_REQUEST action, tracks loading state, 
  // and loads appropriate collections based on account type
  yield takeEvery(actionTypes.accounts.ACCOUNT_LOAD_REQUEST, function* (action) {
    
    yield put(actions.applicationBackgroundLoading());
    const firstLoad = yield select((state) => state.firstLoad.firstLoad);

    try {
      const { accountId, accessibleAccounts = [], workingAccount, isReport = false } = action;
      //console.log(`[ACCOUNT_LOAD_REQUEST] Loading account data`);
      //console.log(accountId, accessibleAccounts, workingAccount)
      
      // Track this account as loading
      yield accountsLoading.push(accountId);
      
      // Get account type from accessibleAccounts or state
      let currentAccountType;
      if (Array.isArray(accessibleAccounts) && accessibleAccounts.length > 0) {
        const currentAccount = accessibleAccounts.find(acc => acc.id === accountId);
        currentAccountType = currentAccount?.type;
      }
      
      if (!currentAccountType) {
        const accounts = yield select((state) => state.accounts);
        currentAccountType = accounts[accountId]?.type;
      }
      
      //console.log(`[ACCOUNT_LOAD_REQUEST] Current Account Type: ${currentAccountType}`);
      
      // Check for changes by comparing with state
      const previousCurrentAccount = yield select((state) => state.currentAccount);
      const previousWorkingAccount = yield select((state) => state.workingAccount);
      const previousAccessibleAccounts = store.getState().accessibleAccounts;
      
      // Determine what has changed
      const accessibleAccountsChanged = !_.isEqual(
        _.map(previousAccessibleAccounts || [], acc => _.pick(acc, ['id', 'type'])),
        _.map(accessibleAccounts || [], acc => _.pick(acc, ['id', 'type']))
      );
      
      const currentAccountChanged = previousCurrentAccount !== accountId;
      const workingAccountChanged = previousWorkingAccount !== workingAccount;
      
      /*console.log(`[ACCOUNT_LOAD_REQUEST] Changes detected:
        Accessible accounts changed: ${accessibleAccountsChanged}
        Current account changed: ${currentAccountChanged}
        Working account changed: ${workingAccountChanged}`);

      if (firstLoad) {
        console.log(`[ACCOUNT_LOAD_REQUEST] First load detected — forcing data load`);
      }*/

      // Step 0: If accessible accounts have changed, clear and reload
      if (accessibleAccountsChanged) {
        //console.log(`[ACCOUNT_LOAD_REQUEST] Accessible accounts changed — resetting state`);
        yield call(clearAccount); //To check
      }

      // Step 1: If Supplier account → Load supplier-specific data and return early
      if (currentAccountType === 'SUPPLIER') {
        //console.log(`[ACCOUNT_LOAD_REQUEST] Loading collections for SUPPLIER account`);
        yield call(loadCollectionsForSuppliers, accountId);
        _.pull(accountsLoading, accountId);
        return;
      }

      // Step 2: Load OWNER and GROUP accounts (Only if accessible accounts have changed)
      if ((firstLoad || accessibleAccountsChanged) && !isReport) {
        //console.log(`[ACCOUNT_LOAD_REQUEST] Reloading OWNER and GROUP accounts`);
        const ownerAndGroupAccounts = accessibleAccounts.filter(
          (account) => account.type === 'OWNER' || account.type === 'GROUP'
        );

        for (const account of ownerAndGroupAccounts) {
          //console.log(`[ACCOUNT_LOAD_REQUEST] Loading Parent/Group Account: ${account.id}`);
          yield call(loadCollectionsForParentsAndGroups, account.id);
        }
      }
      
      // Step 3: Handle current account based on type and change state
      if ((firstLoad || currentAccountChanged) && !isReport) {
        //console.log(`[ACCOUNT_LOAD_REQUEST] Loading current account data`);

        // Clear previous LOCATION data if the current account type is OWNER
        const previousAccountType = yield select((state) => state.accounts[previousCurrentAccount]?.type);
        if (previousAccountType === 'LOCATION') {
          //console.log(`[ACCOUNT_LOAD_REQUEST] Clearing previous location data`);
          yield call(clearAccount, previousCurrentAccount);
        }

        if (currentAccountType === 'LOCATION') {
          // Load current location data
          //console.log(`[ACCOUNT_LOAD_REQUEST] Loading Current Account Collections: ${accountId}`);
          yield call(loadCollectionsForCurrentAccount, accountId);
        } else if (currentAccountType === 'OWNER') {
          // Do nothing for OWNER — data is already loaded
          //console.log(`[ACCOUNT_LOAD_REQUEST] Skipping loading for OWNER`);
        }
      } else {
        yield put(actions.applicationBackgroundLoaded());
        //console.log(`[ACCOUNT_LOAD_REQUEST] Skipping current account data load (no changes detected)`);
      }
      
      // Step 4: Handle working account separately (if it exists and has changed)
      if ((firstLoad || (workingAccount && workingAccount !== false)) && !isReport) {
        if (workingAccountChanged) {
          //console.log(`[ACCOUNT_LOAD_REQUEST] Working account changed. Clearing previous working account data`);

          // Clear previous working account data
          const previousWorkingAccountType = yield select(
            (state) => state.accounts[previousWorkingAccount]?.type
          );
          if (previousWorkingAccountType) {
            //console.log(`[ACCOUNT_LOAD_REQUEST] Clearing data for previous working account: ${previousWorkingAccount}`);
            yield call(clearAccount, previousWorkingAccount);
          }

          // Load new working account data
          //console.log(`[ACCOUNT_LOAD_REQUEST] Loading working account data for: ${workingAccount}`);
          yield call(loadCollectionsForWorkingAccount, workingAccount);

          // If working account is different from current account, ensure it has basic collections
          if (workingAccount !== accountId) {
            //console.log(`[ACCOUNT_LOAD_REQUEST] Ensuring basic collections for working account: ${workingAccount}`);
            yield call(loadCollectionsForCurrentAccount, workingAccount);
          }
        } else {
          yield put(actions.applicationBackgroundLoaded());
          //console.log(`[ACCOUNT_LOAD_REQUEST] Skipping working account data load (no changes detected)`);
        }
      }

      // Step 5: Load department data only in report mode
      if (isReport) {
        //console.log(`[ACCOUNT_LOAD_REQUEST] Loading department data for reports`);
        const departmentAccounts = accessibleAccounts.filter(
          (account) => account.type === 'DEPARTMENT'
        );
        for (const department of departmentAccounts) {
          //console.log(`[ACCOUNT_LOAD_REQUEST] Loading department data: ${department.id}`);
          yield call(loadCollectionsForReports, department.id);
        }
      }
      
      // Remove this account from loading tracking
      _.pull(accountsLoading, accountId);
      
      // Check if all accounts are done loading
      if (accountsLoading.length === 0) {
        //console.log('[ACCOUNT_LOAD_REQUEST] All accounts finished loading');
        store.dispatch(actions.applicationLoaded());
      }
      
    } catch (error) {
      console.error(`[ACCOUNT_LOAD_REQUEST] Error loading account data:`, error);
      
      // Make sure to remove from loading array even on error
      _.pull(accountsLoading, action.accountId);
      
      // Check if all accounts are done loading
      if (accountsLoading.length === 0) {
        store.dispatch(actions.applicationLoaded());
      }
      
      yield put(actions.appMessageError('Failed to load account data'));
    }
  });

  // This saga listens for the ACCOUNT_LOADED action, which is dispatched once an account is fully loaded.
  // It triggers loading of associated account users and updates the loading state.
  // TODO: Update this logic to:
  // 1. Only listen to AccountUsers when the `currentAccount` is not null.
  // 2. Restrict listeners to accessibleAccounts derived from `getAccessibleAccountIds2`.
  // 3. Optimize Firestore usage by limiting listeners dynamically based on the user's active context.
  // 4. Add appropriate unit tests and verify changes with performance benchmarks.]

  //THIS NEEDS TO BE REVIEWED
  yield takeEvery(actionTypes.accounts.ACCOUNT_LOADED, function* (action) {
    const accountId = action.payload.id; // Extract the accountId from the payload
    try {
      // Start loading users associated with this account in parallel using the loadAccountUsers function
      //yield fork(loadAccountUsers, accountId);
      
      // Remove the accountId from the accountsLoading array, indicating it has finished loading
      _.pull(accountsLoading, accountId);
  
      // If there are no accounts left in the accountsLoading array, trigger a profile load request
      if (accountsLoading.length === 0) {
        // Use fork to initiate a separate saga to load the user's profile
        yield fork(function* () {
          //yield put(actions.profile.profileLoadRequest()); // Dispatch profileLoadRequest action
        });
      }
    } catch (err) {
      // Log any errors that occur and notify the user of the failure
      console.error(err, action);
      yield put(actions.appMessageError('Failed to load account data')); // Dispatch an error message
    }
  });

  // Clears specific account data.
  yield takeEvery(actionTypes.accounts.ACCOUNT_CLEAR, function* (action) {
    const accountId = action.payload;
    try {
      yield call(clearAccount, accountId);
      yield call(clearAccountCollections, accountId);
      yield call(clearCollections)
    }
    catch (err) {
      console.error(err, action);
      yield put(actions.appMessageError('Failed to clear account data'));
    }
  });

  // Updates account information in Firestore.
  yield takeEvery(actionTypes.accounts.ACCOUNT_UPDATE, function* (action) {
    try {
      const { callback, accountInfo } = action;
      const { id, ...moreInfo } = accountInfo;
      yield call(updateAccount, id, moreInfo);
      yield put(actions.appMessageSuccess('Account updated'));
      if (callback) {
        yield call(callback, accountInfo);
      }
    }
    catch (err) {
      yield put(actions.appMessageError('Account could not be updated: ' + err.message));
    }
  });

  // Adds a new account to Firestore.
  yield takeEvery(actionTypes.accounts.ACCOUNT_CREATE, function* (action) {
    try {
      const { accountInfo, callback } = action;
      const accountDoc = yield call(addAccount, accountInfo);
      yield put(actions.accountUsers.addUser({ email: accountInfo.ownerEmail, account: accountDoc.id }));
      yield put(actions.appMessageSuccess('Account Added'));
      if (callback) {
        yield call(callback, { id: accountDoc.id, ...accountInfo });
      }
    }
    catch (err) {
      yield put(actions.appMessageError('Account could not be added: ' + err.message));
    }
  });

  // Handles adding a user to an account.
  yield takeEvery(actionTypes.accountUsers.ADD_USER, function* (action) {
    try {
      yield call(addToAccount, action.userInfo);
      yield put(actions.appMessageSuccess('Account User added'));
    }
    catch (err) {
      yield put(actions.appMessageError('User could not be added to account: ' + err.message));
    }
  });

  // Handles removing a user from an account.
  yield takeEvery(actionTypes.accountUsers.REMOVE_USER, function* (action) {
    try {
      yield call(removeFromAccount, action.userId);
      yield put(actions.appMessageSuccess('Account User removed'));
    }
    catch (err) {
      yield put(actions.appMessageError('User could not be removed from account: ' + err.message));
    }
  });
  //
  // Removes all users associated with an account.
  yield takeEvery(actionTypes.accountUsers.REMOVE_ACCOUNT, function* (action) {
    try {
      yield call(removeByAccountId, action.accountId);
      yield put(actions.appMessageSuccess('Account Users removed successfully.'));
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Users could not be removed from account: ' + err.message));
    }
  });
}

// Listens for the APP_LOAD_STARTED action to track loading tasks.
function* appSaga() {
  yield takeEvery(actionTypes.APP_LOAD_STARTED, function* (action) {
    // Logs the task that has started loading and adds it to the loadingTasks array.
    //For debugging purpose
    //console.log(action.task,'action task load')
    yield loadingTasks.push(action.task);
  });

  // Listens for the APP_LOAD_COMPLETE action to remove tasks from the loading array.
  yield takeEvery(actionTypes.APP_LOAD_COMPLETE, function* (action) {
    // Removes the completed task from the loadingTasks array.
    _.pull(loadingTasks, action.task);

    // If there are no more loading tasks, the application is considered fully loaded.
    if (loadingTasks.length === 0) {
      //console.log('=== APPLICATION LOADED ===');
      // Dispatches an action to indicate that the application has fully loaded.
      yield put(actions.applicationLoaded());
    }
  });

  yield takeEvery(actionTypes.APP_BACKGROUND_STARTED, function* (action) {
    //console.log(action.task, 'Background task started');
    yield backgroundTasks.push(action.task);
  });

  yield takeEvery(actionTypes.APP_BACKGROUND_COMPLETE, function* (action) {
    //console.log(action.task, 'Background task completed');
    _.pull(backgroundTasks, action.task);

    if (backgroundTasks.length === 0) {
      //console.log('=== BACKGROUND TASKS LOADED ===');
      yield put(actions.applicationBackgroundLoaded());
      yield put(actions.firstLoadSuccess());
    }
  });

  yield takeEvery(actionTypes.APP_REPORT_STARTED, function* (action) {
    //console.log(action.task, 'Report task started');
    yield reportTasks.push(action.task);
  });

  yield takeEvery(actionTypes.APP_REPORT_COMPLETE, function* (action) {
    //console.log(action.task, 'Report task completed');
    _.pull(reportTasks, action.task);

    if (reportTasks.length === 0) {
      //console.log('=== REPORT TASKS LOADED ===');
      yield put(actions.applicationReportLoaded());
    }
  });

   // Listens for APP_MESSAGE_SET actions to display messages using the toaster.
  yield takeEvery(actionTypes.APP_MESSAGE_SET, function* (action) {
    switch (action.messageType) {
      case messageTypes.SUCCESS:
        yield call(toaster.success, action.messageText);
        break;
      case messageTypes.WARNING:
        yield call(toaster.warning, action.messageText);
        break;
      case messageTypes.ERROR:
        yield call(toaster.danger, action.messageText);
        break;
      case messageTypes.INFO:
      default:
        yield call(toaster.notify, action.messageText);
        break;
    }
  });

  // Listens for APP_MESSAGE_CLEAR actions to close all open toast notifications.
  yield takeEvery(actionTypes.APP_MESSAGE_CLEAR, function* () {
    yield call(toaster.closeAll);
  });
}


export default function* rootSaga() {
  yield all([
    appSaga(),
    accountsSaga(),
    profileSaga(),
    foodRecordsSaga(),
    orderRecordsSaga(),
    invoiceUploadsSaga(),
    ingredientsSaga(),
    ingredientCategoriesSaga(),
    supplierCategoriesSaga(),
    recipesSaga(),
    recipeCategoriesSaga(),
    modifiersSaga(),
    modifierCategoriesSaga(),
    departmentsSaga(),
    areasSaga(),
    financialsSaga(),
    budgetsSaga(),
    menuCategoriesSaga(),
    suppliersSaga(),
    supplierOptionsSaga(),
    sectionsSaga(),
    menusSaga(),
    messagesSaga(),
    messagesSeenTimesSaga(),
    miseEnPlacesSaga(),
    stockTakeSaga(),
    wastageSaga(),
    transferSaga(),
    ordersSaga(),
    taskSaga(),
    tasksCommentsSaga(),
    taskHistorySaga(),
    prepTaskSaga(),
    salesSaga(),
    receiptsSaga(),
    reportsCommentsSaga(),
    reportsIncidentalsSaga(),
  ]);
};
