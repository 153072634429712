//React
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

//Libraries
import { Paragraph, Heading, Pane } from 'evergreen-ui';

//Components
import Page from '../../components/Page/Page';
import LoadingIndicator from '../../components/IsLoading/IsLoading';

const NotFound = (props) => {
  const backgroundLoading = useSelector((state) => state.backgroundLoading);
  const [show404, setShow404] = useState(false);
  
  useEffect(() => {
    let timer;

    if (backgroundLoading) {
      // Start timeout for 5 seconds to switch to 404 state
      timer = setTimeout(() => {
        if (backgroundLoading) {
          setShow404(true); // If still loading after 5 seconds, show 404
        }
      }, 5000);
    }

    // If loading completes within 5 seconds, cancel the timeout
    if (!backgroundLoading) {
      clearTimeout(timer);
      setShow404(false); // Reset back to normal state
    }

    return () => clearTimeout(timer); // Cleanup on unmount
  }, [backgroundLoading]);
  
  return (
    <Page title="404 Not Found" containerProps={{ maxWidth: 600 }}>
      {show404 ? (
        // Show 404 state after timeout
        <Pane display="flex" alignItems="center" justifyContent="center" height="100vh" width="100%">
          <Heading is="h2">Sorry</Heading>
          <Paragraph>The page requested could not be found.</Paragraph>
        </Pane>
      ) : (
        // Show loading state
        <Pane display="flex" alignItems="center" justifyContent="center" height="100vh" width="100%">
          <LoadingIndicator message={"We are loading your data..."} />
        </Pane>
      )}
    </Page>
  );
};

export default NotFound;
