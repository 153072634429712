import _ from 'lodash';
import store from '../redux';
import fb from './';
import { actions } from '../actions';

const allergensRef = fb.firestore.collection('allergens');
let allergenListener;

// exported methods
export const loadAllergens = () => {
  if (allergenListener) { allergenListener(); }
  //console.log(`loading: /allergens`);
  allergenListener = allergensRef
    .onSnapshot((querySnapshot) => {
      const results = [];
      querySnapshot.forEach((doc) => {
        results.push({ id: doc.id, ...doc.data() });
      });
      store.dispatch(actions.allergens.allergensLoadSuccess(_.sortBy(results, 'name')));
    });
};
