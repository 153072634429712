//React
import React from 'react';

//Libraries
import PropTypes from 'prop-types';
import { majorScale, Pane, Text, Table, useTheme } from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';

//Files
import { TABLE_MAX_WIDTH } from '../../../utils/constants';
import { dateFormat } from '../../../utils/time';
import { calcPercentage, castFloat } from '../../../utils/functions';
import { currency } from '../../../utils/format';

const TheorecticalBreakdown = ({
  theoreticalStock: {
    expectedClosingStocktakeValue,
    mostRecentOpeningStocktakeValue,
    periodStart,
    stockType,
    expectedOpeningStocktakeValue,
  },
  weekSalesCost,
  weekSalesNet,
  weekWastageCost,
  procurementTotal,
  reportIncidentals,
  setEditingValue
}) => {
  const theme = useTheme();

  return (
  <>
    <Block marginBottom={majorScale(2)}>
      <Pane display="flex" alignItems="center">
        <Pane padding={majorScale(2)} display="flex" alignItems="center" maxWidth={TABLE_MAX_WIDTH} width='100%'>
          <CustomHeading level="4" flex="3 0 0">Theoretical Stock Value</CustomHeading>
          <Text size={600} color={theme.colors.tertiary100} flex={1}>£ {currency(expectedClosingStocktakeValue)}</Text>
        </Pane>
        <Pane padding={majorScale(2)} display="flex" alignItems="center" maxWidth={TABLE_MAX_WIDTH} width='100%'>
          <Text flex="3 0 0">Period start: {dateFormat(periodStart)}</Text>
          <Text flex={1}>£ {currency(procurementTotal)}</Text>
        </Pane>
      </Pane>
      <Table maxWidth={TABLE_MAX_WIDTH} marginBottom={majorScale(2)}>
        <Table.Row>
          <Table.TextCell>{stockType}</Table.TextCell>
          <Table.TextCell>£ {currency(expectedOpeningStocktakeValue)}</Table.TextCell>
        </Table.Row>
        <Table.Row>
          <Table.TextCell>Procurement Balance</Table.TextCell>
          <Table.TextCell>£ {currency(procurementTotal)}</Table.TextCell>
        </Table.Row>
        <Table.Row>
          <Table.TextCell>Theoretical Cost of Sales</Table.TextCell>
          <Table.TextCell>£ {currency(weekSalesCost)}</Table.TextCell>
        </Table.Row>
        <Table.Row>
          <Table.TextCell>Wastage</Table.TextCell>
          <Table.TextCell>£ {currency(weekWastageCost)}</Table.TextCell>
        </Table.Row>
        <Table.Row>
          <Table.TextCell>Allowances</Table.TextCell>
          <Table.Cell>
            <Text>£ {currency(reportIncidentals.stockAllowances || 0)}</Text>
            <IconWrapper
                name="edit"
                appearance="clickable"
                marginLeft={majorScale(2)}
                onClick={() => setEditingValue({
                  label: 'Stock Allowances',
                  incidental: 'stockAllowances',
                  value: reportIncidentals.stockAllowances,
                })}
            />
          </Table.Cell>
        </Table.Row>
      </Table>
    </Block>

    <Block marginBottom={majorScale(2)}>
      <Pane padding={majorScale(2)} display="flex" alignItems="center" maxWidth={TABLE_MAX_WIDTH}>
        <CustomHeading level="4" flex="3 0 0">Theoretical GP</CustomHeading>
        <Text
            color={theme.colors.tertiary100}
            marginLeft={majorScale(2)}
            flex={1}
            size={600}
        >{calcPercentage(weekSalesNet - weekSalesCost, weekSalesNet, 1)} %</Text>
        <Text
            color={theme.colors.tertiary100}
            marginLeft={majorScale(2)}
            flex={1}
            size={600}
        >£ {currency(weekSalesNet - weekSalesCost)}</Text>
      </Pane>
      <Table maxWidth={TABLE_MAX_WIDTH} marginBottom={majorScale(2)}>
        <Table.Row>
          <Table.TextCell>Total Net Sales</Table.TextCell>
          <Table.TextCell>£ {currency(weekSalesNet)}</Table.TextCell>
        </Table.Row>
        <Table.Row>
          <Table.TextCell>Theoretical Cost of Sales</Table.TextCell>
          <Table.TextCell>£ {currency(weekSalesCost)}</Table.TextCell>
        </Table.Row>
      </Table>

      <Pane padding={majorScale(2)} display="flex" alignItems="center" maxWidth={TABLE_MAX_WIDTH}>
        <CustomHeading level="3" flex="3 0 0">Theoretical GP with allowances</CustomHeading>
        <Text
            color={theme.colors.tertiary100}
            marginLeft={majorScale(2)}
            flex={1}
            size={600}
        >{calcPercentage(weekSalesNet - weekSalesCost - castFloat(reportIncidentals.stockAllowances), weekSalesNet, 1)} %</Text>
        <Text
            color={theme.colors.tertiary100}
            marginLeft={majorScale(2)}
            flex={1}
            size={600}
        >£ {currency(weekSalesNet - weekSalesCost - castFloat(reportIncidentals.stockAllowances))}</Text>
      </Pane>
      <Table maxWidth={TABLE_MAX_WIDTH} marginBottom={majorScale(2)}>
        <Table.Row>
          <Table.TextCell>Allowances</Table.TextCell>
          <Table.TextCell>£ {currency(reportIncidentals.stockAllowances || 0)}</Table.TextCell>
        </Table.Row>
      </Table>
    </Block>
  </>
  )
};

TheorecticalBreakdown.propTypes = {
  theoreticalStock: PropTypes.object.isRequired,
  reportIncidentals: PropTypes.object.isRequired,
  setEditingValue: PropTypes.func.isRequired
}

export default TheorecticalBreakdown;
