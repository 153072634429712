import { combineReducers } from 'redux';
import { actionTypes } from '../actions';

// Reducers

// Linked to actions/common.js
// Global State Management

/**
 * User State:
 * - Stores the authenticated user's information.
 * - Initially set to 'loading' during the app's bootstrapping process.
 */
const user = (state = 'loading', action) => {
  switch (action.type) {
    case actionTypes.USER_SET:
      return action.userInfo;
    case actionTypes.USER_CLEAR:
      return null;
    default:
      return state;
  }
};

/**
 * Loading State:
 * - Indicates whether the app is currently loading or not.
 * - Manages global app loading status.
 */
const loading = (state = true, action) => {
  switch (action.type) {
    case actionTypes.APP_LOADED:
      return false;
    case actionTypes.APP_LOADING:
      return true;
    default:
      return state;
  }
};

const backgroundLoading = (state = false, action) => {
  switch (action.type) {
    case actionTypes.APP_BACKGROUND_LOADING:
      return true;
    case actionTypes.APP_BACKGROUND_LOADED:
      return false;
    default:
      return state;
  }
};

const reportLoading = (state = true, action) => {
  switch (action.type) {
    case actionTypes.APP_REPORT_LOADING:
      return true;
    case actionTypes.APP_REPORT_LOADED:
      return false;
    default:
      return state;
  }
};

const firstLoad = (state = { firstLoad: true }, action) => {
  switch (action.type) {
    case actionTypes.FIRST_LOAD_REQUEST:
      return {
        ...state,
        firstLoad: true, // Start loading process
      };
    case actionTypes.FIRST_LOAD_SUCCESS:
      return {
        ...state,
        firstLoad: false, // Set to false after the first successful load
      };
    case actionTypes.RESET_FIRST_LOAD:
      return {
        ...state,
        firstLoad: true, // Reset for debugging or session reset
      };
    default:
      return state;
  }
};

/**
 * Messages State:
 * - Manages messages displayed in the app, such as success or error notifications.
 * - Indexed by account for context-specific messages.
 */
const messages = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.messages.MESSAGES_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.messages.MESSAGES_CLEAR:
      return {};
    default:
      return state;
  }
};

/**
 * Page Title State:
 * - Tracks the title of the current page for display purposes.
 * - Defaults to 'Misenplace' when cleared.
 */
const pageTitle = (state = 'Misenplace', action) => {
  switch (action.type) {
    case actionTypes.PAGE_TITLE_SET:
      return action.title;
    case actionTypes.PAGE_TITLE_CLEAR:
      return 'Misenplace';
    default:
      return state;
  }
};

/**
 * Navigation Menu State:
 * - Tracks whether the navigation menu is expanded or collapsed.
 * - Used for UI state management.
 */
const navMenuExpanded = (state = false, action) => {
  switch (action.type) {
    case actionTypes.NAV_MENU_EXPANDED_SET:
      return action.expanded;
    default:
      return state;
  }
};

/**
 * Working Account State:
 * - Tracks the current working account for DEPARTMENTS/AREAS when `currentAccount` is set to LOCATION.
 * - Resets to false when cleared.
 */
const workingAccount = (state = false, action) => {
  switch (action.type) {
    case actionTypes.WORKING_ACCOUNT_SET:
      return action.accountId;
    case actionTypes.WORKING_ACCOUNT_CLEAR:
      return false;
    default:
      return state;
  }
};

const accounts = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.accounts.ACCOUNT_LOADED:
      return {
        ...state,
        [action.payload.id]: action.payload
      };
    case actionTypes.accounts.ACCOUNTS_LOAD_SUCCESS:
      // Just add the accounts data
      return {
        ...state,
        ...action.accounts
      }; 
    case actionTypes.accounts.ALL_ACCOUNTS_LOADED:
      return {
        ...state,
        accountsLoaded: true
      };
    case actionTypes.accounts.ACCOUNT_CLEAR:
      const { [action.payload]:clearedAccount, ...remainingState } = state;
      return remainingState;
    case actionTypes.accounts.ACCOUNTS_CLEAR:
      return {};
    default:
      return state;
  }
};

const accessibleAccounts = (state = [], action) => {
  switch (action.type) {
    case actionTypes.accounts.ACCESSIBLE_ACCOUNTS_SET:
      return action.accounts;
    case actionTypes.accounts.ACCESSIBLE_ACCOUNTS_CLEAR:
      return [];
    default:
      return state;
  }
};

const accountUsers = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.accountUsers.ACCOUNT_USERS_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.accountUsers.ACCOUNT_USERS_CLEAR:
      return {};
    default:
      return state;
  }
}

const allergens = (state = [], action) => {
  switch (action.type) {
    case actionTypes.allergens.ALLERGENS_LOAD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const currentAccount = (state = 'pending', action) => {
  switch (action.type) {
    case actionTypes.accounts.CURRENT_ACCOUNT_SET:
      return action.payload;
    case actionTypes.accounts.CURRENT_ACCOUNT_CLEAR:
      return 'pending';
    default:
      return state;
  }
};

//GENERAL STATE
const deliveryCompanies = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.deliveryCompanies.DELIVERY_COMPANIES_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.deliveryCompanies.DELIVERY_COMPANIES_CLEAR:
      return {};
    default:
      return state;
  }
};

const foodRecords = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.foodRecords.FOOD_RECORDS_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.foodRecords.FOOD_RECORDS_CLEAR:
      return {};
    default:
      return state;
  }
};

const orderRecords = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.orderRecords.ORDER_RECORDS_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.orderRecords.ORDER_RECORDS_CLEAR:
      return {};
    default:
      return state;
  }
};

const invoiceUploads = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.invoiceUploads.INVOICE_UPLOADS_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.invoiceUploads.INVOICE_UPLOADS_CLEAR:
      return {};
    default:
      return state;
  }
};

const ingredients = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ingredients.INGREDIENTS_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.ingredients.INGREDIENTS_CLEAR:
      return {};
    default:
      return state;
  }
};

const ingredientCategories = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ingredientCategories.INGREDIENT_CATEGORIES_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.ingredientCategories.INGREDIENT_CATEGORIES_CLEAR:
      return {};
    default:
      return state;
  };
};

const supplierCategories = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.supplierCategories.SUPPLIER_CATEGORIES_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.supplierCategories.SUPPLIER_CATEGORIES_CLEAR:
      return {};
    default:
      return state;
  };
};

const menuCategories = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.menuCategories.MENU_CATEGORIES_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.menuCategories.MENU_CATEGORIES_CLEAR:
      return {};
    default:
      return state;
  }
};

const menus = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.menus.MENUS_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.menus.MENUS_CLEAR:
      return {};
    default:
      return state;
  }
};



const messagesSeenTimes = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.messagesSeenTimes.MESSAGES_SEEN_TIMES_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.messagesSeenTimes.MESSAGES_SEEN_TIMES_CLEAR:
      return {};
    default:
      return state;
  }
};

const miseEnPlaces = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.miseEnPlaces.MISE_EN_PLACES_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.miseEnPlaces.MISE_EN_PLACES_CLEAR:
      return {};
    default:
      return state;
  }
};

const orderAttachments = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.orderAttachments.ORDER_ATTACHMENTS_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.orderAttachments.ORDER_ATTACHMENTS_CLEAR:
      return {};
    default:
      return state;
  }
};

const orders = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.orders.ORDERS_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.orders.ORDERS_CLEAR:
      return {};
    default:
      return state;
  }
};

const prepTasks = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.prepTasks.PREP_TASKS_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.prepTasks.PREP_TASKS_CLEAR:
      return {};
    default:
      return state;
  }
};

const profile = (state = null, action) => {
  switch (action.type) {
    case actionTypes.profile.PROFILE_LOAD_SUCCESS:
    case actionTypes.profile.PROFILE_UPDATE_SUCCESS:
      return action.payload;
    case actionTypes.profile.PROFILE_CLEAR:
      return null;
    default:
      return state;
  }
};

const quickOrder = (state = null, action) => {
  switch (action.type) {
    case actionTypes.orders.QUICK_ORDER_SET:
      return {
        optionId: action.optionId,
        quantity: action.quantity,
        ingredientId: action.ingredientId
      };
    case actionTypes.orders.QUICK_ORDER_CLEAR:
      return null;
    default:
      return state;
  }
};

const recipes = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.recipes.RECIPES_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload,
      };
    case actionTypes.recipes.RECIPES_CLEAR:
      return {};
    default:
      return state;
  }
};

const recipeCategories = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.recipeCategories.RECIPE_CATEGORIES_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.recipeCategories.RECIPE_CATEGORIES_CLEAR:
      return {};
    default:
      return state;
  }
};

const modifiers = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.modifiers.MODIFIERS_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.modifiers.MODIFIERS_CLEAR:
      return {};
    default:
      return state;
  }
};

const modifierCategories = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.modifierCategories.MODIFIER_CATEGORIES_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.modifierCategories.MODIFIER_CATEGORIES_CLEAR:
      return {};
    default:
      return state;
  }
};

const departments = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.departments.DEPARTMENTS_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.departments.DEPARTMENTS_CLEAR:
      return {};
    default:
      return state;
  }
};

const areas = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.areas.AREAS_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.areas.AREAS_CLEAR:
      return {};
    default:
      return state;
  }
};

const financials = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.financials.FINANCIALS_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.financials.FINANCIALS_CLEAR:
      return {};
    default:
      return state;
  }
};

const budgets = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.budgets.BUDGETS_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.budgets.BUDGETS_CLEAR:
      return {};
    default:
      return state;
  }
};

export const reports = (state = { accountsData: {}, salesData: {}, costsData: {}, financialsData: {}, hasRequestedReports: false, reportsDataLoaded: false, }, action) => {
  switch (action.type) {
    case actionTypes.reports.HAS_REQUESTED_REPORTS:
      return {
        ...state,
        hasRequestedReports: true,
      };
    case actionTypes.reports.REPORTS_DATA_LOADED:
      return {
        ...state,
        reportsDataLoaded: true,
      };
    case actionTypes.reports.ACCOUNTS_DATA_SET:
      return {
        ...state,
        accountsData: action.payload,
      };
    case actionTypes.reports.SALES_DATA_SET:
      return {
        ...state,
        salesData: action.payload,
      };
    case actionTypes.reports.COSTS_DATA_SET:
      return {
        ...state,
        costsData: action.payload,
      };
    case actionTypes.reports.FINANCIALS_DATA_SET:
      return {
        ...state,
        financialsData: action.payload,
      };
    case actionTypes.reports.CLEAR_REPORT_DATA:
      return {  // Reset all data
        aggregatedData: {},
        salesData: {},
        costsData: {},
        financialsData: {},
        hasRequestedReports: false,
        reportsDataLoaded: false
      };
    default:
      return state;
  }
};

const reportsComments = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.reportsComments.REPORTS_COMMENTS_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload,
      };
    case actionTypes.reportsComments.REPORTS_COMMENTS_CLEAR:
      return {};
    default:
      return state;
  }
};

const reportsIncidentals = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.reportsIncidentals.REPORTS_INCIDENTALS_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.reportsIncidentals.REPORTS_INCIDENTALS_CLEAR:
      return {};
    default:
      return state;
  }
};

const sales = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.sales.SALES_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.sales.SALE_LOAD_SUCCESS:
      const sales = state[action.account] || [];
      const index = state[action.account].findIndex(s => s.id === action.payload.id)
      if (index > -1) sales[index] = action.payload;
      else sales.push(action.payload);
      return {
        ...state,
        [action.account]: [...sales]
      };
    case actionTypes.sales.SALES_CLEAR:
      return {};
    default:
      return state;
  }
};

const receipts = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.receipts.RECEIPTS_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.receipts.RECEIPT_LOAD_SUCCESS:
      if (!action.account || !action.payload) return state;

      const receipts = state[action.account] || [];
      const index = state[action.account].findIndex(s => s.id === action.payload.id)
      if (index > -1) receipts[index] = action.payload;
      else receipts.push(action.payload);
      return {
        ...state,
        [action.account]: [...receipts]
      };
    case actionTypes.receipts.SALES_CLEAR:
      return {};
    default:
      return state;
  }
};

const sections = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.sections.SECTIONS_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.sections.SECTIONS_CLEAR:
      return {};
    default:
      return state;
  }
};

const stockTakes = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.stockTake.STOCKTAKES_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.stockTake.STOCKTAKES_CLEAR:
      return {};
    default:
      return state;
  }
};

export const stocktakesRecent = (state = { stocktakesRecent: [], loading: true }, action) => {
  //console.log('Action dispatched:', action);
  switch (action.type) {
    case actionTypes.stockTake.LOAD_MOST_RECENT_STOCKTAKES_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.stockTake.LOAD_MOST_RECENT_STOCKTAKES_LOADED:
      return {
        ...state,
        stocktakesRecent: action.payload, 
        loading: false,
      };
    default:
      return state;
  }
};

const replaceObjects = (state, payload) => {
  return payload.reduce((array, item) => {
    const index = array.findIndex(i => i.id === item.id);
    if (index > -1) array[index] = {...item};
    else array.push(item);
    return array;
  }, [...state])
}

const suppliers = (state = {}, action) => {
  //console.log('Reducer action received:', action.type, action);
  switch (action.type) {
    case actionTypes.suppliers.SUPPLIERS_LOAD_SUCCESS:
      //console.log('Loading suppliers:', action.payload);
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.suppliers.SUPPLIERS_LOAD_REPLACE:
      //console.log('Replacing suppliers:', action.payload);
      const newState = {
        ...state,
        [action.account]: replaceObjects(state[action.account] || [], action.payload)
      };
      //console.log('New state post replace:', newState);
      return newState;
    case actionTypes.suppliers.SUPPLIERS_CLEAR:
      return {};
    default:
      return state;
  }
};

const supplierOptions = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.supplierOptions.SUPPLIER_OPTIONS_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload,
      };
    case actionTypes.supplierOptions.SUPPLIER_OPTIONS_LOAD_REPLACE:
      return {
        ...state,
        [action.account]: replaceObjects(state[action.account] || [], action.payload)
      };
    case actionTypes.supplierOptions.SUPPLIER_OPTIONS_CLEAR:
      return {};
    default:
      return state;
  }
};

const tasks = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.tasks.TASKS_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.tasks.TASKS_CLEAR:
      return {};
    default:
      return state;
  }
};

const tasksComments = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.tasksComments.TASKS_COMMENTS_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.tasksComments.TASKS_COMMENTS_CLEAR:
      return {};
    default:
      return state;
  }
};

const tasksHistory = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.tasksHistory.TASKS_HISTORY_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.tasksHistory.TASKS_HISTORY_CLEAR:
      return {};
    default:
      return state;
  }
};

const uploads = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.uploads.UPLOADS_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.uploads.UPLOADS_CLEAR:
      return {};
    default:
      return state;
  }
};



const wastages = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.wastages.WASTAGES_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.wastages.WASTAGES_CLEAR:
      return {};
    default:
      return state;
  }
};

const transfers = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.transfers.TRANSFERS_LOAD_SUCCESS:
      return {
        ...state,
        [action.account]: action.payload
      };
    case actionTypes.transfers.TRANSFERS_CLEAR:
      return {};
    default:
      return state;
  }
};



const forms = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.forms.FORM_CHANGE:
      if (action.form) {
        const form = {...state[action.form]} || {};
        form.data = action.data;
        return {
          ...state,
          [action.form]: form
        };
      }
      return state;
    case actionTypes.forms.FORM_IMAGE_CHANGE:
      if (action.form) {
        const form = {...state[action.form]} || {};
        form.image = action.image;
        return {
          ...state,
          [action.form]: form
        };
      }
      return state;
    case actionTypes.forms.FORM_CLEAR:
      return {
        ...state,
        [action.form]: null
      };
    default:
      return state;
  }
};

export default combineReducers({
  // Account Collections
  accountUsers,
  accessibleAccounts,
  deliveryCompanies,
  foodRecords,
  orderRecords,
  invoiceUploads,
  ingredients,
  ingredientCategories,
  supplierCategories,
  menuCategories,
  menus,
  messages,
  messagesSeenTimes,
  miseEnPlaces,
  orderAttachments,
  orders,
  recipes,
  recipeCategories,
  modifiers,
  modifierCategories,
  departments,
  areas,
  financials,
  budgets,
  reportsComments,
  reportsIncidentals,
  sales,
  receipts,
  sections,
  stockTakes,
  stocktakesRecent,
  suppliers,
  supplierOptions,
  prepTasks,
  tasks,
  tasksComments,
  tasksHistory,
  uploads,
  wastages,
  transfers,
  quickOrder,
  reports,

  // General state
  loading,
  backgroundLoading,
  reportLoading,
  firstLoad,
  user,
  currentAccount,
  navMenuExpanded,
  pageTitle,
  
  profile,
  workingAccount,

  // General Collections
  allergens,
  accounts,

  // forms
  forms
});
