//React
import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { majorScale, Pane, Tablist, Text } from 'evergreen-ui';

//Components
import DataTable from '../../../components/DataTable/DataTable';
import FilterBar from '../../../components/FilterBar/FilterBar';
import DatePickerInput from '../../../components/DatePickerInput/DatePickerInput';
import Block from '../../../components/ui/Block/Block';
import CustomHeading from '../../../components/Headings/Headings';
import Tab from '../../../components/ui/Tab/Tab';

//Files
import { actions } from '../../../store/actions';
import { TODAY } from '../../../utils/time';
import { generateSearchFieldsFn, getIngredientUOMOptions, getRecipeUOMOptions, calcStocktakeIngredientCost, 
  calcStocktakeRecipeCost, normalizeIngredientQuantity, normalizeRecipeQuantity } from '../../../utils/functions';
import { filterActiveIngredients } from '../../../utils/ingredients'; 
import { filterActiveRecipes } from '../../../utils/recipes'; 
import { toFixed } from '../../../utils/format';
import { stockRecordingUnits } from '../../../utils/constants';
import { current } from '../../../utils/selectors';


const TAB_INGREDIENTS = 'ingredients';
const TAB_RECIPES = 'recipes';


const WastageTab = () => {
  const { accountId } = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 460 });

  const [currentTab, setCurrentTab] = useState(TAB_INGREDIENTS);
  const [ingFilters, setIngFilters] = useState({});
  const [recFilters, setRecFilters] = useState({});
  const [wastageDate, setWastageDate] = useState(TODAY);
  const [wastageIngredients, setWastageIngredients] = useState({});
  const [wastageRecipes, setWastageRecipes] = useState({});

  const ingredients = useSelector((state) => {
    // Retrieve current ingredients and filter out archived items
    let currentIngredients = filterActiveIngredients(current(state, 'ingredients', accountId));
    // Apply custom filters here based on ingFilters state
    const searchFilter = ingFilters.search;
    let filteredIngredients = searchFilter ? currentIngredients.filter(searchFilter) : currentIngredients;
    // Merge modifications from wastageIngredients
    const mergedIngredients = filteredIngredients.map(ingredient => {
        const wastage = wastageIngredients[ingredient.id];
        return wastage ? { ...ingredient, ...wastage } : ingredient;
    });
    return mergedIngredients;
  });
  
  const recipes = useSelector((state) => {
    let currentRecipes = filterActiveRecipes(current(state, 'recipes', accountId));
    // Apply custom filters based on recFilters state
    const searchFilter = recFilters.search;
    let filteredRecipes = searchFilter ? currentRecipes.filter(searchFilter) : currentRecipes;
    // Merge modifications from wastageRecipes
    const mergedRecipes = filteredRecipes.map(recipe => {
        const wastage = wastageRecipes[recipe.id];
        return wastage ? { ...recipe, ...wastage } : recipe;
    });
    return mergedRecipes.slice(0, 100);  // Ensure only up to 100 recipes are returned
  });

  const updateIngFilters = (filterName, value) => {
    setIngFilters((prevFilters) => {
      if (!value) {
        let newFilters = Object.assign({}, prevFilters);
        delete newFilters[filterName];
        return newFilters;
      }
      return { ...prevFilters, [filterName]: value };
    });
  };

  const updateRecFilters = (filterName, value) => {
    setRecFilters((prevFilters) => {
      if (!value) {
        let newFilters = Object.assign({}, prevFilters);
        delete newFilters[filterName];
        return newFilters;
      }
      return { ...prevFilters, [filterName]: value };
    });
  };

  const ingSearchOnChange = (newSearchValue) => {
    console.log('Search value:', newSearchValue);
    if (!newSearchValue) {
      updateIngFilters('search', null);
    }
    else {
      updateIngFilters('search', generateSearchFieldsFn(['name'], newSearchValue));
    }
  };

  const recSearchOnChange = (newSearchValue) => {
    if (!newSearchValue) {
      updateRecFilters('search', '');
    }
    else {
      updateRecFilters('search', generateSearchFieldsFn(['name'], newSearchValue));
    }
  };

  const ingOnFieldChange = (ing, field, newValue) => {
    setWastageIngredients((prevIngredients) => {
      if (prevIngredients[ing.id]) {
        return { ...prevIngredients, [ing.id]: { ...prevIngredients[ing.id], [field]: newValue } };
      }
      if (field !== 'recordUOM') {
        // Add the default UOM
        return { ...prevIngredients, [ing.id]: { 'recordUOM': _.first(getIngredientUOMOptions(ing)).value, [field]: newValue } };
      }
      return { ...prevIngredients, [ing.id]: { [field]: newValue } };
    });
  };

  const ingOnRecordUOMChange = (ing, newValue) => {
    let uomOptions = getIngredientUOMOptions(ing);
    ingOnFieldChange(ing, 'recordUOM', newValue);
    if (!_.includes(_.keys(stockRecordingUnits), newValue)) {
      // Record UOM is not a default UOM, so stash the display value as well
      let selected = _.find(uomOptions, { 'value': newValue });
      ingOnFieldChange(ing, 'displayRecordUOM', selected.label);
    }
    else {
      // Clear the displayRecordUOM field in case the user has previously selected
      //  a "unit" or "lot" option
      ingOnFieldChange(ing, 'displayRecordUOM', '');
    }
  };

  const recOnFieldChange = (rec, field, newValue) => {
    setWastageRecipes((prevRecipes) => {
      if (prevRecipes[rec.id]) {
        return { ...prevRecipes, [rec.id]: { ...prevRecipes[rec.id], [field]: newValue } };
      }
      if (field !== 'recordUOM') {
        // Add the default UOM
        return { ...prevRecipes, [rec.id]: { 'recordUOM': _.first(getRecipeUOMOptions(rec)).value, [field]: newValue } };
      }
      return { ...prevRecipes, [rec.id]: { [field]: newValue } };
    });
  };

  const saveIngredientWastage = (ingredient) => {
    const { inputAmount: amount, inputReason: reason, recordUOM, displayRecordUOM, ...ing } = ingredient;
    if (parseFloat(amount) > 0 && reason !== '') {
      const wastageObj = {
        name: ing.name,
        ingredient: ing,
        recordUOM,
        amount,
        normalQty: normalizeIngredientQuantity({ ...ing, amount, recordUOM }),
        reason,
        date: wastageDate,
        cost: parseFloat(toFixed(calcStocktakeIngredientCost({ ...ing, amount, recordUOM }, true), 4))
      };
      // displayRecordUOM may be empty, so add it here only if it has a value
      if (displayRecordUOM) {
        wastageObj['displayRecordUOM'] = displayRecordUOM;
      }
      dispatch(actions.wastages.addWastage(accountId, wastageObj));
      // Clear the ingredient from our active list
      setWastageIngredients((prevIngredients) => {
        let { [ing.id]: i, ...ingredients } = prevIngredients;
        return ingredients;
      });
    }
    else {
      dispatch(actions.appMessageError('You must provide an amount and a reason for Wastage'));
    }
  }
  
  const saveRecipeWastage = (recipe) => {
    const { inputAmount: amount, inputReason: reason, recordUOM, ...rec } = recipe;
    if (parseFloat(amount) > 0 && reason !== '') {
      dispatch(actions.wastages.addWastage(accountId, {
        name: rec.name,
        recipe: rec,
        recordUOM,
        amount,
        normalQty: normalizeRecipeQuantity({ ...rec, amount, recordUOM }),
        reason,
        date: wastageDate,
        cost: parseFloat(toFixed(calcStocktakeRecipeCost({ ...rec, amount, recordUOM }, true), 4))
      }));

      // Clear the recipe from our active list
      setWastageRecipes((prevRecipes) => {
        let { [rec.id]: r, ...recipes } = prevRecipes;
        return recipes;
      });
    }
    else {
      dispatch(actions.appMessageError('You must provide an amount and a reason for Wastage'));
    }
  }

  const ingTableHeaders = useMemo(() => [
    { label: 'Name', field: 'name', width: 4},
    { label: 'UOM', field: 'recordUOM', type: 'dropdown', optionsFn: getIngredientUOMOptions, onChange: (item, e) => ingOnRecordUOMChange(item, e.target.value), width: 2},
    { label: 'Qty', field: 'inputAmount', type: 'input', onChange: (item, e) => ingOnFieldChange(item, 'inputAmount', e.target.value), width: 1 },
    { label: 'Reason', field: 'inputReason', type: 'input', onChange: (item, e) => ingOnFieldChange(item, 'inputReason', e.target.value), width: 3},
    { label: 'Value', type: 'calc', calc: (item) => calcStocktakeIngredientCost({ ...item, amount: item.inputAmount }, true), format: 'currency', width: 2 },
    { label: 'Record', field: 'save', type: 'action', icon: 'projects', width: 1},
  ], []);
  const mobileIngTableHeaders = useMemo(() => [
    { label: 'Name', field: 'name', width: 10 },
    { label: 'Record', field: 'save', type: 'action', icon: 'projects', width: 2 },
    { label: 'UOM', field: 'recordUOM', type: 'dropdown', optionsFn: getIngredientUOMOptions, onChange: (item, e) => ingOnRecordUOMChange(item, e.target.value), width: 3, row: 2  },
    { label: 'Qty', field: 'inputAmount', type: 'input', onChange: (item, e) => ingOnFieldChange(item, 'inputAmount', e.target.value), columnProps: { flex: '0 0 7rem' }, width: 2, row: 2 },
    { label: 'Reason', field: 'inputReason', type: 'input', onChange: (item, e) => ingOnFieldChange(item, 'inputReason', e.target.value), width: 5, row: 2  },
    { label: 'Value', type: 'calc', calc: (item) => calcStocktakeIngredientCost({ ...item, amount: item.inputAmount }, true), format: 'currency', width: 2, row: 2  },
  ], []);
  const recTableHeaders = useMemo(() => [
    { label: 'Name', field: 'name', width: 4 },
    { label: 'Yield', field: 'yield', width: 1 },
    { label: 'Y Desc', field: 'yieldDescription', width: 2 },
    { label: 'UOM', field: 'recordUOM', type: 'dropdown', optionsFn: getRecipeUOMOptions, onChange: (item, e) => recOnFieldChange(item, 'recordUOM', e.target.value), width: 2 },
    { label: 'Qty', field: 'inputAmount', type: 'input', onChange: (item, e) => recOnFieldChange(item, 'inputAmount', e.target.value), width: 1 },
    { label: 'Reason', field: 'inputReason', type: 'input', onChange: (item, e) => recOnFieldChange(item, 'inputReason', e.target.value), width: 3 },
    { label: 'Value', type: 'calc', calc: (item) => calcStocktakeRecipeCost({ ...item, amount: item.inputAmount }, true), format: 'currency', width: 2 },
    { label: 'Record', field: 'save', type: 'action', icon: 'projects', width: 1 }
  ], []);
  const mobileRecTableHeaders = useMemo(() => [
    { label: 'Name', field: 'name', width: 10 },
    { label: 'Record', field: 'save', type: 'action', icon: 'projects', width: 2 },
    { label: 'Yield', type: 'calc', calc: (recipe) => `${recipe.yield || 1} ${recipe.yieldDescription || ''}`, width: 2, row: 2 },
    { label: 'UOM', field: 'recordUOM', type: 'dropdown', optionsFn: getRecipeUOMOptions, onChange: (item, e) => recOnFieldChange(item, 'recordUOM', e.target.value), width: 3, row: 2 },
    { label: 'Qty', field: 'inputAmount', type: 'input', onChange: (item, e) => recOnFieldChange(item, 'inputAmount', e.target.value), width: 2, row: 2 },
    { label: 'Reason', field: 'inputReason', type: 'input', onChange: (item, e) => recOnFieldChange(item, 'inputReason', e.target.value), width: 3, row: 2 },
    { label: 'Value', type: 'calc', calc: (item) => calcStocktakeRecipeCost({ ...item, amount: item.inputAmount }, true), format: 'currency', width: 2, row: 2 },
  ], []);

  return (
    <React.Fragment>
      <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
        <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
          <CustomHeading level="3" marginRight={majorScale(2)}>Wastage</CustomHeading>
          <DatePickerInput
              value={wastageDate}
              onChange={(newDate) => setWastageDate(newDate)}
          />
        </Pane>
        {currentTab === TAB_INGREDIENTS && (
          <FilterBar
              filters={ingFilters}
              searchPlaceholder="Search Ingredients"
              searchOnChange={ingSearchOnChange}
          />
        )}
        {currentTab === TAB_RECIPES && (
          <FilterBar
              searchPlaceholder="Search Recipes"
              searchOnChange={recSearchOnChange}
          />
        )}
      </Block>

      <Block flex={1} display="flex" flexDirection="column" marginBottom={majorScale(2)}>
        <Tablist
              display="flex"
              flexShrink={0}
              overflowX="auto"
              padding={majorScale(1)}
              //style={{ width: '30%' }} 
        >
          <Tab 
            maxWidth='200px'
            isSelected={currentTab === TAB_INGREDIENTS} 
            onSelect={() => setCurrentTab(TAB_INGREDIENTS)}
          >Ingredients</Tab>
          <Tab 
            maxWidth='200px'
            isSelected={currentTab === TAB_RECIPES} 
            onSelect={() => setCurrentTab(TAB_RECIPES)}
          >Recipes</Tab>
        </Tablist>
      </Block>
      
      <Block flex={`1 0 auto`} display="flex" flexDirection="column" height={"420px"} marginBottom={majorScale(2)}>
        {currentTab === TAB_INGREDIENTS && (
          <Pane flex="1" overflowY="auto" display="flex" flexDirection="column">
            {Object.keys(ingredients).length < 100 ? (
              <DataTable
                items={ingredients}
                filters={ingFilters}
                headers={(isMobile) ? mobileIngTableHeaders : ingTableHeaders}
                onSave={saveIngredientWastage}
                borderRadius={5}
                flex="1 0 auto"
                listHeight={400}
              />
            ) : (
              <Pane display="flex" alignItems="center" justifyContent="start" marginLeft={majorScale(2)} height="60px">
                <Text>Search for an ingredient to record</Text>
              </Pane>
            )}
          </Pane>
        )}
        {currentTab === TAB_RECIPES && (
          <Pane flex="1" overflowY="auto" display="flex" flexDirection="column">
            {Object.keys(recipes).length < 100 ? (
              <DataTable
                  items={recipes}
                  filters={recFilters}
                  headers={(isMobile) ? mobileRecTableHeaders : recTableHeaders}
                  onSave={saveRecipeWastage}
                  borderRadius={5}
                  flex={`1 0 auto`}
                  listHeigh={400}
              />
            ) : ( 
              <Pane display="flex" alignItems="center" justifyContent="start" marginLeft={majorScale(2)} height="60px">
                <Text>Search for a recipe to record</Text>
              </Pane>
            )}
          </Pane>
        )}
      </Block>
    </React.Fragment>
  );
};

export default WastageTab;
